<template>
  <v-container :fill-height="loading" fluid class="system-settings-view">

    <hb-app-bar>
    </hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tabs"
                  background-color="transparent">

            <v-tab>Indicators</v-tab>
            <v-tab>Units</v-tab>

            <v-tabs-items v-model="tabs" class="pt-5">
              <v-tab-item>
                <v-row>
                  <v-col cols="12">
                    <v-btn-toggle v-model="searchType">
                      <v-btn>
                        Search from all
                      </v-btn>
                      <v-btn>
                        Search from keywords
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="searchPhrase" outlined label="Search"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table :items="filteredIndicators" :headers="headers">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editItem(item)"
                        >
                          lnr lnr-pencil
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-btn @click="createNewUnit" color="primary">
                  <span>Create new unit</span>
                </v-btn>
                <v-row>
                  <v-col cols="12">
                    <v-data-table :items="units" :headers="unitHeaders">
                      <template v-slot:item.actions="{ item }">
                        <v-btn icon @click="editUnit(item)">
                        <v-icon
                          small
                          @click="editUnit(item)"
                        >
                          lnr lnr-pencil
                        </v-icon>
                        </v-btn>
                        <v-btn icon @click="deleteUnit(item)">
                          <v-icon small>
                            lnr lnr-trash
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

            </v-tabs-items>
          </v-tabs>
        </v-col>
      </v-row>
    </v-fade-transition>
    <EditIndicatorDialog :impacts="impacts" ref="editDialog" @indicator-updated="indicatorUpdated"></EditIndicatorDialog>
    <EditUnitDialog ref="editUnitDialog" @unit-updated="unitUpdated"></EditUnitDialog>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-container>
</template>

<script>

import systemApi from "@/api/system";
import IndicatorSelector from '@/service/indicatorselector.service';
import EditIndicatorDialog from "@/components/SystemSettings/EditIndicatorDialog";
import EditUnitDialog from "@/components/SystemSettings/EditUnitDialog";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "SystemSettingsView",
  components: {
    ConfirmDialog,
    EditUnitDialog,
    EditIndicatorDialog,
  },
  data() {
    return {
      loading: true,
      tabs: null,
      searchPhrase: '',
      indicators: [],
      units: [],
      unitHeaders: [{
        text: 'Unit',
        value: 'unit',
      }, {
        text: 'Description',
        value: 'description',
      },
        {
          text: 'Actions', value: 'actions', sortable: false,
        },
      ],
      searchType: 0,
      impacts: [],
      headers: [
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Short desc',
          value: 'shortDescription',
        },
        {
          text: 'Long desc',
          value: 'description',
        },
        {
          text: 'Keywords',
          value: 'keywords',
        },
        {
          text: 'ESG',
          value: 'impact.esg',
        },
        {
          text: 'Impact',
          value: 'impact.description',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  methods: {
    indicatorUpdated(indicator) {
      const index = this.indicators.findIndex(i => i.code === indicator.code);
      if (index === -1) {
        this.indicators.push(indicator);
      } else {
        this.indicators.splice(index, 1, indicator);
      }
    },
    unitUpdated(unit) {
      const index = this.units.findIndex(u => u.id === unit.id);
      if (index === -1) {
        this.units.push(unit);
      } else {
        this.units.splice(index, 1, unit);
      }
    },
    async getSystemData() {
      this.loading = true;

      try {
        const systemData = await systemApi.getFullSystemData();
        this.indicators = systemData.indicators;
        this.units = systemData.units;
        this.impacts = systemData.impacts;
        this.impacts.forEach(i => i.fullText = `${i.esg} -- ${i.description}`);
      } catch (error) {
        this.$handleApiError(error);
      }

      this.loading = false;
    },
    createNewUnit() {
      this.$refs.editUnitDialog.open();
    },
    async getUnits() {
      this.loading = true;

      try {
        this.indicators = await systemApi.getUnits();
      } catch (error) {
        this.$handleApiError(error);
      }

      this.loading = false;
    },
    editUnit(unit) {
      this.$refs.editUnitDialog.open(unit);
    },
    async deleteUnit(unit) {
      const confirm = await this.$refs.confirmDialog.open('Delete unit?', 'Are you sure you want to delete unit?', { color: 'error', agree: this.$t('delete'), cancel: this.$t('cancel')});
      if (!confirm) {
        return;
      }

      this.loading = true;

      try {
        await systemApi.deleteUnit(unit.id);
        await this.getSystemData();
      } catch (error) {
        this.$handleApiError(error);
      }

      this.loading = false;
    },
    editItem(item) {
      this.$refs.editDialog.open(item);
    },
  },
  computed: {
    filteredIndicators() {
      if (this.searchType === 0) {
        if (this.searchPhrase == null || this.searchPhrase.length === 0) {
          return this.indicators;
        } else {
          return this.indicators
            .filter(i => {
              const text = i.code + i.description + i.shortDescription + i.keywords;
              return text.toLowerCase().indexOf(this.searchPhrase.toLowerCase()) > -1
            });
        }
      } else {
        return IndicatorSelector.filterByKeywords(this.indicators, this.searchPhrase);
      }
    },
  },
  async mounted() {
    await this.getSystemData();
    this.loading = false;
  },
}
</script>

<style scoped>

</style>
