import axios from 'axios';

const dataExportApi = {
  async exportActionData(ids) {
    const data = { ids: ids };

    return await axios({
      url: '/api/tenant/export/actions',
      data,
      method: 'post',
      responseType: 'blob'
    }).then(r => r.data);
  }
}

export default dataExportApi;
