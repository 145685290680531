<template>
  <v-col cols="12">
    <v-fade-transition hide-on-leave>
      <v-row no-gutters v-if="!loading && impacts.length > 0">
        <v-col cols="12">
          <action-custom-impact-selector v-for="root in roots"
                                         :key="root.id"
                                         :root="root"
                                         :value="selectedCustomImpactIds[root.id]"
                                         @input="customImpactSelected"
                                         :custom-impacts="customImpacts"></action-custom-impact-selector>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <v-row no-gutters v-if="!loading && impacts.length === 0">
        <v-col cols="12">
          <v-alert type="info" :value="true" prominent text>
            {{ $t('plan.no_custom_impacts_help') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <v-col cols="12" v-if="loading" class="py-10">
        <hb-loading-indicator align-middle></hb-loading-indicator>
      </v-col>
    </v-fade-transition>
  </v-col>
</template>

<script>
import tenant from "@/api/tenant";
import ActionCustomImpactSelector from "@/components/Plan/ActionCustomImpactSelector";

export default {
  components: { ActionCustomImpactSelector },
  props: ['value'],
  name: 'ActionCustomImpacts',
  data() {
    return {
      selectedCustomImpactIds: {},
      impacts: [],
      loading: true,
    }
  },
  watch: {
    value() {
      this.setSelectedCustomIds();
    }
  },
  computed: {
    roots() {
      return this.customImpacts.filter(ci => ci.level === 0);
    },
    customImpacts() {
      const impacts = this.impacts.map(i => ({ ...i, children: [] }));

      const getRootId = (impact) => {
        if (impact.parentId) {
          const parent = impacts.find(i => i.id === impact.parentId);
          return getRootId(parent);
        }
        return impact.id;
      }

      const getLevel = (impact) => {
        if (impact.parentId) {
          const parent = impacts.find(i => i.id === impact.parentId);
          return getLevel(parent) + 1;
        } else {
          return 0;
        }
      }

      impacts.forEach(i => {
        i.level = getLevel(i);
        i.root = getRootId(i);
        if (i.parentId) {
          const parent = impacts.find(ii => ii.id === i.parentId);
          parent.children.push(i);
        }
      })

      return impacts;
    }
  },
  methods: {
    customImpactSelected(rootId, ciId) {
      this.selectedCustomImpactIds[rootId] = ciId;

      const vals = Object.values(this.selectedCustomImpactIds).filter(t => t).map(id => this.impacts.find(i => i.id === id));
      this.$emit('input', vals);
    },
    setSelectedCustomIds() {
      const selectedCustomImpactIds = {};
      this.roots.forEach(root => {
        const selectedForRoot = this.customImpacts.find(ci => ci.root === root.id && this.value.find(v => v.id === ci.id));
        selectedCustomImpactIds[root.id] = selectedForRoot && selectedForRoot.id;
      })

      this.selectedCustomImpactIds = selectedCustomImpactIds;
    },
    async reloadImpacts() {
      try {
        this.impacts = await tenant.getCustomImpacts();
      } catch (error) {
        this.$handleApiError(error);
      }
    }
  },
  async mounted() {
    await this.reloadImpacts();
    this.setSelectedCustomIds();
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
