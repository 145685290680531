import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VMenu,{attrs:{"bottom":"","nudge-bottom":"-14","max-height":"400","close-on-content-click":false,"close-on-click":!_vm.longDescriptionDialog,"max-width":_vm.$vuetify.breakpoint.mdAndUp ? _vm.maxWidth : ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({ref:"sdgTextField",attrs:{"value":_vm.sdgDisplayText,"placeholder":_vm.$t('plan.select_sdg_indicator'),"readonly":"","rules":[_vm.$rules.required],"autocomplete":"off","solo-inverted":"","flat":""},on:{"focus":_vm.setMaxWidth},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,{class:_vm.isActive ? 'mdi-rotate-180' : '',attrs:{"color":_vm.isActive ? 'primary' : ''}},[_vm._v("mdi mdi-menu-down")])]},proxy:true}],null,true)},on))]}}],null,false,1837001128),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('SDGBusinessIndicatorList',{attrs:{"is-active":_vm.isActive,"best-matches":_vm.bestMatches,"all-indicators":_vm.allIndicators,"selected-indicator":_vm.selectedIndicator},on:{"close-without-changes":function($event){_vm.isActive = false},"select-indicator":_vm.onSelect,"show-long-description":_vm.showLongDescription}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.xsOnly ? '' : '600',"hide-overlay":_vm.$vuetify.breakpoint.xsOnly,"transition":_vm.$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"value":_vm.sdgDisplayText,"placeholder":_vm.$t('plan.select_sdg_indicator'),"readonly":"","rules":[_vm.$rules.required],"autocomplete":"off","solo-inverted":"","flat":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,{class:_vm.isActive ? 'mdi-rotate-180' : '',attrs:{"color":_vm.isActive ? 'primary' : ''}},[_vm._v("mdi mdi-menu-down")])]},proxy:true}],null,true)},on))]}}],null,false,3320183319),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('SDGBusinessIndicatorList',{attrs:{"is-active":_vm.isActive,"best-matches":_vm.bestMatches,"all-indicators":_vm.allIndicators,"selected-indicator":_vm.selectedIndicator},on:{"close-without-changes":function($event){_vm.isActive = false},"select-indicator":_vm.onSelect,"show-long-description":_vm.showLongDescription}})],1):_vm._e(),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.longDescriptionDialog),callback:function ($$v) {_vm.longDescriptionDialog=$$v},expression:"longDescriptionDialog"}},[(_vm.shortDescription && _vm.longDescription)?_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.shortDescription))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.longDescriptionDialog = false}}},[_c(VIcon,[_vm._v("lnr lnr-cross")])],1)],1),_c(VCardText,{staticClass:"pt-5"},[_c('p',[_vm._v(_vm._s(_vm.longDescription))])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }