<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('tenants.edit_organization') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">

          <v-text-field
            class="mt-5 organization_name"
            :label="$t('tenants.organization_name')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="organization"
            v-model="currentTenant.name"
          ></v-text-field>
          <v-text-field
            class="mt-5 organization_street_address"
            :label="$t('tenants.details.street_address')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="name"
            v-model="currentTenant.streetAddress"
          ></v-text-field>
          <v-text-field
            class="mt-5 organization_zip_code"
            :label="$t('tenants.details.zip_code')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="zipCode"
            v-model="currentTenant.zipCode"
          ></v-text-field>
          <v-text-field
            class="mt-5 organization_city"
            :label="$t('tenants.details.city')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="city"
            v-model="currentTenant.city"
          ></v-text-field>
          <CountrySelect v-model="currentTenant.country" :rules="[$rules.required]"></CountrySelect>
          <v-select :items="businesses" item-text="text" item-value="id" :label="$t('tenants.details.business')"
                    v-model="currentTenant.business"></v-select>
          <v-select :items="headcounts" item-text="text" item-value="id" :label="$t('tenants.details.headcount')"
                    v-model="currentTenant.headcount"></v-select>
          <v-select :items="turnovers" item-text="text" item-value="id" :label="$t('tenants.details.turnover')"
                    v-model="currentTenant.turnover"></v-select>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields')}}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveOrganization"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import params from "../../../config/params";
  import CountrySelect from "../../CountrySelect";

  export default {
    name: "TenantDetailsDialog",
    components: { CountrySelect },
    data() {
      return {
        dialog: false,
        organization: '',
        showAlert: false,
        currentTenant: {},
      }
    },
    methods: {
      async saveOrganization() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }
        try {
          await this.$tenantApi.updateCurrentTenant({
            name: this.currentTenant.name,
            turnover: this.currentTenant.turnover,
            headcount: this.currentTenant.headcount,
            streetAddress: this.currentTenant.streetAddress,
            business: this.currentTenant.business,
            city: this.currentTenant.city,
            country: this.currentTenant.country,
            zipCode: this.currentTenant.zipCode,
          });
          this.dialog = false;
          this.$showSuccessNotification(this.$t('tenants.organization_update_success'));
        } catch (err) {
          this.$handleApiError(err, this.$t('tenants.organization_update_failed'));
        }
      },
      open(tenant) {
        this.dialog = true;
        this.currentTenant = Object.assign({}, tenant);
      },
    },
    computed: {
      businesses() {
        return params.businesses;
      },
      headcounts() {
        return params.headcount;
      },
      turnovers() {
        return params.turnover;
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.currentTenant = {};
          this.showAlert = false;
          this.$refs.form.reset();
          this.$emit('on-dismiss');
        }
      },
    },
  }
</script>

<style scoped>

</style>
