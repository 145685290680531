import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[(_vm.miniMenu)?[_c(VListItemAction,{staticClass:"justify-center"},[_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VAvatar,_vm._g({staticClass:"organization-avatar",attrs:{"size":"34","color":_vm.$randomizeColorFromString(_vm.selectedTenant, 75, 70)}},on),[_c('span',{staticClass:"text-uppercase font-weight-black white--text"},[_vm._v(_vm._s(_vm.organizationLetters))])])]}}],null,false,2111827436)},[_c(VList,{staticClass:"pt-0 pb-0",attrs:{"dense":""}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('tenants.choose_organization'))+":")]),_vm._l((_vm.possibleTenants),function(tenant){return _c(VListItem,{key:tenant.id,on:{"click":function($event){return _vm.selectTenant(tenant)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(tenant.name))])],1)],1)})],2)],1)],1)]:[_c(VSelect,{staticClass:"mt-3 mb-3",attrs:{"label":_vm.$t('tenants.organization'),"outlined":"","hide-details":"","items":_vm.possibleTenants,"item-text":"name","item-value":"id"},on:{"change":_vm.tenantSelected},model:{value:(_vm.selectedTenant),callback:function ($$v) {_vm.selectedTenant=$$v},expression:"selectedTenant"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }