<template>
  <v-list-item class="px-2">
    <template v-if="editMode">
      <v-list-item-content>
        <v-row>
          <v-col cols="4">
            <v-text-field
              :placeholder="$t('contributor.email')"
              :rules="[$rules.email, $rules.required]"
              validate-on-blur
              dense
              v-model="model.email"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :placeholder="$t('contributor.name')"
              validate-on-blur
              dense
              v-model="model.name"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :placeholder="$t('contributor.company')"
              validate-on-blur
              dense
              v-model="model.company"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-list-item-content>
      <v-list-item-action class="flex-row">
        <v-btn icon @click="saveContributor"><v-icon>save</v-icon></v-btn>
        <v-btn icon @click="cancelEdit"><v-icon>close</v-icon></v-btn>
      </v-list-item-action>
    </template>
    <template v-else>
      <v-list-item-action>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn v-on="on" v-bind="attrs" icon @click="toggleInGroup"><v-icon v-if="inGroup">remove_circle_outline</v-icon><v-icon v-else>add</v-icon></v-btn>
          </template>
          {{ inGroup ? $t('contributor.remove_contributor_from_group') : $t('contributor.add_contributor_to_group') }}
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ model.email }}</v-list-item-title>
        <v-list-item-subtitle v-if="model.name">{{ model.name }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="model.company">{{ model.company }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="inGroup">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="copyLink"><v-icon small>mdi mdi-content-copy</v-icon></v-btn>
          </template>
          <span>{{ $t('plan.copy_contributor_link') }}</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn icon @click="setEditMode"><v-icon>edit</v-icon></v-btn>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import tenantApi from "@/api/tenant";

export default {
  name: 'Contributor',
  props: {
    contributor: {
      type: Object
    },
    inGroup: {
      type: Boolean
    }
  },
  data() {
    return {
      model: {
        email: null,
        name: null,
        company: null,
        uuid: null,
      },
      editMode: false,
    };
  },
  watch: {
    contributor(value) {
      this.model = { ...value };
      this.editMode = this.model.id === null;
    }
  },
  methods: {
    toggleInGroup() {
      if (this.inGroup) {
        this.$emit('remove-contributor-from-group', this.contributor);
      } else {
        this.$emit('add-contributor-to-group', this.contributor);
      }
    },
    setEditMode() {
      this.editMode = true;
    },
    cancelEdit() {
      this.model = { ...this.contributor };
      this.editMode = false;
      this.$emit('cancel-edit-contributor', this.contributor);
    },
    async saveContributor() {
      if (this.model.id) {
        await this.updateContributor();
      } else {
        await this.createContributor();
      }
    },
    async createContributor() {
      try {
        const c = await tenantApi.createContributor(this.model);
        this.$emit('create-contributor', c);
        this.model = c;
        this.$showSuccessNotification(this.$t('contributor.contributor_created'));
        this.editMode = false;
        this.$emit('add-contributor-to-group', c);
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('contributor.contributor_create_fail'));
      }
    },
    async updateContributor() {
      try {
        const c = await tenantApi.updateContributor(this.model.id, this.model);
        this.$emit('update-contributor', c);
        this.model = c;
        this.$showSuccessNotification(this.$t('contributor.contributor_updated'));
        this.editMode = false;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('contributor.contributor_update_fail'));
      }
    },
    copyLink() {
      const el = document.createElement('textarea');
      el.value = this.$getSharedContributorUrl(this.$tenant, this.model.uuid);
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      this.$el.appendChild(el);
      el.focus();
      el.select();
      document.execCommand('copy');
      this.$el.removeChild(el);
    }
  },
  mounted() {
    this.model = { ...this.contributor };
    this.editMode = this.model.id === null;
  }
}
</script>

<style scoped>

</style>
