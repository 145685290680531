export default {
  filterByKeywords(indicators, phrase) {
    if (phrase == null || phrase.length < 3) {
      return indicators;
    }

    const descParts = phrase.split(/[,. ]/).filter(w => w.length > 2).map(w => w.toLowerCase());
    return indicators
      .filter(i => i.keywords.split(',')
        .map(w => w.trim().toLowerCase())
        .some(w => descParts.indexOf(w) > -1));
  },
  filterByTextAndKeywords(indicators, phrase) {
    if (phrase == null || phrase.length < 3) {
      return indicators;
    }

    const descParts = phrase.split(/[,. ]/).filter(w => w.length > 2).map(w => w.toLowerCase());
    return indicators
      .filter(i => i.keywords.split(',')
        .map(w => w.trim().toLowerCase())
        .some(w => descParts.indexOf(w) > -1));
  }
}
