<template>
  <v-dialog v-model="dialog"
            :persistent="true"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-card-title style="word-break: break-word;" class="text-wrap">
        {{ $t('tos.tos_has_changed') }}
      </v-card-title>
      <v-card-text class="pt-5">
        <p>{{ $t('tos.tos_body_1') }}</p>
        <p>
          {{ $t('tos.link_to_updated_tos') }}<br />
          <a href="https://www.sdgmonitor.co/terms-of-service" target="_blank">Link to Terms of Service</a>
        </p>
        <p>
          {{ $t('tos.link_to_updated_pp') }}<br />
          <a href="https://www.sdgmonitor.co/privacy-policy" target="_blank">Link to Privacy Policy</a>
        </p>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               @click="acceptTos"
               :fixed="$vuetify.breakpoint.xsOnly"
               :loading="saving"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <span>{{ $t('tos.accept') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import userApi from "@/api/user";

export default {
  name: 'TosAcceptDialog',
  components: {  },
  props: [],
  data() {
    return {
      dialog: false,
      saving: false,
    }
  },
  mounted() {
    this.dialog = this.$userInfo && this.$userInfo.tosAcceptDate == null;
  },
  methods: {
    async acceptTos() {
      this.saving = true;
      try {
        await userApi.acceptTos();
        window.location.reload();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.saving = false;
    },
  },
}
</script>

<style lang="scss">
.log-entry-textarea {
  textarea {
    line-height: 1.15rem !important;
  }
}
</style>
