<template>
  <div class="dashboard">
    <v-container :fill-height="loading" class="overview-dashboard">

      <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

      <hb-app-bar></hb-app-bar>

      <hb-loading-indicator v-if="loading" align-middle />

      <v-fade-transition>
        <v-row v-if="!loading" style="position: relative;">
          <v-col cols="12">

            <v-row justify="space-between" class="ignore-export">
              <v-col class="pb-0 text-center text-sm-left">
                <v-btn text @click="backToDashboards" class="hidden-print-only"><v-icon left>lnr lnr-arrow-left</v-icon>{{ $t('dashboards.back_to_dashboards') }}</v-btn>
              </v-col>
              <v-col class="text-center text-sm-right pb-0">
                <DashboardSettings v-model="settings" v-if="settings != null" :plan-id="planId"></DashboardSettings>
              </v-col>
            </v-row>

            <OverviewDashboard v-if="plan != null" :actions="actions" :plan="plan" :settings="settings" :editable="true"
                               @title-changed="titleChanged"
                               @long-term-saved="longTermSaved"></OverviewDashboard>

            <DashboardFooter />

          </v-col>
        </v-row>
      </v-fade-transition>

    </v-container>
  </div>
</template>

<script>
  import tenantApi from "../../api/tenant";
  import OverviewDashboard from "../../components/OverviewDashboard/OverviewDashboard";
  import DashboardSettings from "../../components/DashboardSettings";
  import DashboardFooter from "@/components/DashboardFooter";

  export default {
    name: 'OverviewDashboardView',
    components: {DashboardFooter, DashboardSettings, OverviewDashboard },
    props: {
      planId: {
        type: [Number, String],
        default: 1,
      },
    },
    data() {
      return {
        loading: false,
        subtleLoading: false,
        actions: [],
        plan: null,
        settings: null,
      }
    },

    methods: {
      async titleChanged(newTitle) {
        this.settings.title = newTitle;
        await tenantApi.updateDashboardSettings(`overview-${this.planId}`, {
          title: newTitle,
          shared: this.settings.shared,
          meta: this.settings.meta,
        });
      },
      async longTermSaved(value) {
        const meta = Object.assign({}, this.settings.meta);
        meta['long.year'] = value;
        const settings = await tenantApi.updateDashboardSettings(this.settings.code, {
          shared: this.settings.shared,
          title: this.settings.title,
          meta,
        });

        this.settings = settings;
      },
      backToDashboards() {
        this.$router.push({name: 'dashboards'});
      },
      async reloadDashboardInfo() {
        this.loading = true;

        try {
          const data = await tenantApi.getDashboardData('overview', this.planId);
          this.plan = data.plan;
          this.actions = data.actions;
          this.settings = data.dashboard;
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;

      },
    },
    mounted() {
      this.reloadDashboardInfo();
    }
  }
</script>

<style scoped>

</style>
