<template>
  <v-container fill-height class="name-your-plan">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12" lg="10" offset-lg="1" xl="8" offset-xl="2" class="text-center">
          <hb-heading :value="$t('plan.name_your_plan')" />
          <v-text-field :height="$vuetify.breakpoint.xsOnly ? 60 : 94"
                        v-model="planName"
                        full-width
                        :placeholder="$t('plan.plan_name_placeholder')"
                        solo
                        background-color="#f4f4f4"
                        flat
                        :style="{fontSize: $vuetify.breakpoint.xsOnly ? '22px' : '33px'}" />
          <v-btn x-large :disabled="planName.length === 0" color="primary" @click="savePlanName">{{ $t('continue') }}</v-btn>
        </v-col>

        <v-col cols="12">
        </v-col>

        <v-col cols="12">
        </v-col>

      </v-row>
    </v-fade-transition>

  </v-container>

</template>

<script>
  import tenantApi from "../../api/tenant";

  export default {
    name: 'NameYourPlanView',
    data() {
      return {
        loading: false,
        subtleLoading: false,
        planName: '',
      }
    },
    methods: {
      async savePlanName() {
        try {
          const plan = await tenantApi.createPlan({ name: this.planName });
          this.$router.push({name: 'plan', params: { planId: plan.id }});
        } catch (error) {
          this.$handleApiError(error);
        }
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
