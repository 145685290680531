<template>
  <v-container fill-height fluid class="login">

    <hb-app-bar hide-menu-button></hb-app-bar>

    <v-row>
      <v-col cols="12" md="5" xl="4" class="pa-10">
        <v-img src="../../public/img/chuttersnap-5Rlrs3sKVJU-unsplash.jpg" />
      </v-col>
      <v-col cols="12" md="5" offset-md="1" xl="4" offset-xl="2" class="text-center pa-10">
        <v-row>
          <custom-sign-in v-if="!isSignUp && !isForgotPassword && !isRequireNewPassword " :is-confirm-signup="isConfirmSignUp" v-bind:signInConfig="signInConfig"></custom-sign-in>
          <custom-require-new-password v-if="isRequireNewPassword" v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"></custom-require-new-password>
          <custom-forgot-password class="forgot-password" v-if="isForgotPassword" v-bind:forgotPasswordConfig="forgotPasswordConfig"></custom-forgot-password>
        </v-row>
        <v-row>
          <v-col cols="12 mt-5">
            <a href="https://www.sdgmonitor.co/" target="_blank" title="SDG Monitor Homepage">www.sdgmonitor.co</a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import UiEventBus from '../UiEventBus'
  import { components as amplifyComponents, AmplifyEventBus } from 'aws-amplify-vue'
  import CustomSignIn from '../components/Login/SignIn.vue';
  import CustomForgotPassword from '../components/Login/ForgotPassword.vue';
  import CustomRequireNewPassword from '../components/Login/RequireNewPassword.vue';
  import UserService from "@/service/user.service";

  export default {
    name: "LoginView",
    data() {
      return {
        signInConfig: {
          header: this.$t('login.login'),
          isSignUpDisplayed: false,
        },
        signUpConfig: {
          defaultCountryCode: '358',
          signUpFields: [
            {
              label: this.$Amplify.I18n.get('Username'),
              key: 'username',
              required: true,
              type: 'string',
              displayOrder: 1
            },
            {
              label: this.$Amplify.I18n.get('Email'),
              key: 'email',
              required: true,
              type: 'string',
              displayOrder: 2
            },
          ],
          hiddenDefaults: ['phone_number', 'username']
        },
        authConfig: {},
        forgotPasswordConfig: {},
        isSignUp: false,
        isConfirmSignUp: false,
        isForgotPassword: false,
        isRequireNewPassword: false,
      }
    },
    computed: {
      options() {
        const defaults = {
          signInConfig: {},
          signUpConfig: {},
          confirmSignUpConfig: {},
          confirmSignInConfig: {},
          forgotPasswordConfig: {},
          mfaConfig: {},
          requireNewPasswordConfig: {}
        };
        return Object.assign(defaults, this.authConfig)
      }
    },
    methods: {
      async getCurrentUser() {
        try {
          const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
          UiEventBus.$emit('userLoggedIn', cognitoUser)
          this.$store.commit('setLoadingOverlay', true);
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      logout() {
        // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
        this.$Amplify.Auth.signOut().then(() => {
          // this is client side only logout
          UiEventBus.$emit('userLoggedOut');
        })
      }
    },
    created() {
      AmplifyEventBus.$on('localUser', user => {
        this.user = user;
        this.options.signInConfig.username = this.user.username;
        this.options.confirmSignInConfig.user = this.user;
        this.options.confirmSignUpConfig.username = this.user.username;
        this.options.requireNewPasswordConfig.user = this.user;
      });
      AmplifyEventBus.$on('authState', state => {
        this.isRequireNewPassword = state === 'requireNewPassword';
        this.isSignUp = state === 'signUp';
        this.isConfirmSignUp = state === 'confirmSignUp';
        this.isForgotPassword = state === 'forgotPassword';
        if (state === 'signedIn') {
          this.getCurrentUser();
        }
      });
    },
    mounted() {
      if (this.$isLoggedIn) {
        this.$store.commit('setLoadingOverlay', false);
        this.$router.push({ name: 'start' });
      } else {
        UserService.clearUserInfo();
      }
    },
    components: {
      CustomSignIn,
      CustomForgotPassword,
      CustomRequireNewPassword,
      ...amplifyComponents
    },
  }
</script>
<style lang="scss" scoped>
  .sign-in,
  .sign-up,
  .forgot-password,
  .confirm-sign-up {
    box-shadow: none;
    background: none;
    min-width: auto;
    padding: 0;
  }


</style>
