<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        dark
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
        :large="$vuetify.breakpoint.smAndUp"
        :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">lnr-plus-circle</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('user_management.create_new_user') }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('user_management.create_new_user') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">

<!--        <v-subheader class="pl-0">{{ $t('user_management.user_type') }}</v-subheader>-->
<!--        <v-switch-->
<!--          color="primary"-->
<!--          v-model="localUser"-->
<!--          :label="$t('user_management.local_user')"-->
<!--          persistent-hint-->
<!--          :hint="$t('user_management.local_user_hint')"-->
<!--          class="mt-2 mb-4"-->
<!--        ></v-switch>-->


        <v-subheader class="pl-0">{{ $t('user_management.user_info') }}</v-subheader>
        <v-text-field
          v-if="localUser"
          class="mt-0 username"
          :label="$t('user_management.username')"
          :rules="[$rules.required, $rules.spacesNotAllowed]"
          validate-on-blur
          type="text"
          autocomplete="username"
          v-model="username"
        ></v-text-field>
        <v-text-field
          v-if="!localUser"
          :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"
          validate-on-blur
          class="mt-0 email"
          :label="$t('user_management.email')"
          autocomplete="username"
          type="text"
          v-model="username"
        ></v-text-field>
        <v-text-field
          v-if="localUser"
          class="mt-0 password"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('user_management.password')"
          v-model="password"
          :rules="[$rules.required]"
          validate-on-blur
          autocomplete="new-password"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-subheader class="pl-0">{{ $t('user_management.user_roles') }}</v-subheader>
        <v-btn-toggle v-if="$vuetify.breakpoint.smAndUp" v-model="roleSet" mandatory multiple class="elevation-0">
          <template v-for="role in roles">
            <v-btn :key="role" text outlined :color="$randomizeColorFromString(role, 75, 70)" :value="role">{{ role }}</v-btn>
          </template>
        </v-btn-toggle>

        <v-select
          v-if="$vuetify.breakpoint.xsOnly"
          v-model="roleSet"
          :items="roles"
          attach
          chips
          :label="$t('user_management.roles')"
          multiple
          style="width: 100%;"
        >
          <template v-slot:selection="data">
            <v-chip
              :selected="data.selected"
              close
              small
              outline
              @input="removeRole(data.item)"
              class="ml-0"
              :color="$randomizeColorFromString(data.item, 75, 70)"
            >
              <strong>{{ data.item }}</strong>
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-action>
              <v-icon v-if="roleSet.findIndex(r => r === data.item) > -1" :color="$randomizeColorFromString(data.item, 75, 70)">check_box</v-icon>
              <v-icon v-else :color="$randomizeColorFromString(data.item, 75, 70)">check_box_outline_blank</v-icon>
            </v-list-item-action>
            <v-list-item-title :style="{color: $randomizeColorFromString(data.item, 75, 70)}">{{ data.item }}</v-list-item-title>
          </template>
        </v-select>

        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">Tarkista, että olet täyttänyt kaikki pakolliset kentät.</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveUser"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import adminApi from "../../api/admin";

  export default {
    name: "AddUserDialog",
    props: {
      roles: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        dialog: false,
        localUser: false,
        username: '',
        password: '',
        showPassword: false,
        roleSet: this.roles.length > 0 ? [this.roles[0]] : [],
        showAlert: false,
      }
    },
    methods: {
      removeRole (item) {
        this.roleSet.splice(this.roleSet.indexOf(item), 1);
        this.roleSet = [...this.roleSet];
      },
      async saveUser() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        let user = {
            roleSet: this.roleSet,
            username: this.username,
        };

        if (this.localUser) user.password = this.password;

        try {
          const response = await adminApi.createUser(user);
          if (response.created) {
            this.dialog = false;
            this.$showSuccessNotification(this.$t('user_management.user_created_success'));
          }
        } catch (err) {
          let errorKey = false;
          if (err.response.data && err.response.data.error) errorKey = err.response.data.error;
          if (errorKey === 'constraint_exception') errorKey = errorKey + '.' + err.response.data.errorFields[0];

          const message = errorKey ? this.$t('user_management.' + errorKey) : this.$t('user_management.user_creation_failed');
          this.$showErrorNotification(message);
        }
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.localUser = false;
          this.username = '';
          this.password = '';
          this.showPassword = false;
          this.roleSet = [];
          this.showAlert = false;
          this.$refs.form.reset();
          this.$emit('on-dismiss');
        }
      }
    },
  }
</script>

<style scoped>

</style>
