import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":true,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.xsOnly ? '' : '600',"hide-overlay":_vm.$vuetify.breakpoint.xsOnly,"transition":_vm.$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-wrap",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.$t('tos.tos_has_changed'))+" ")]),_c(VCardText,{staticClass:"pt-5"},[_c('p',[_vm._v(_vm._s(_vm.$t('tos.tos_body_1')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('tos.link_to_updated_tos'))),_c('br'),_c('a',{attrs:{"href":"https://www.sdgmonitor.co/terms-of-service","target":"_blank"}},[_vm._v("Link to Terms of Service")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('tos.link_to_updated_pp'))),_c('br'),_c('a',{attrs:{"href":"https://www.sdgmonitor.co/privacy-policy","target":"_blank"}},[_vm._v("Link to Privacy Policy")])])]),(_vm.$vuetify.breakpoint.smAndUp)?_c(VDivider):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","fixed":_vm.$vuetify.breakpoint.xsOnly,"loading":_vm.saving,"bottom":_vm.$vuetify.breakpoint.xsOnly,"right":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.acceptTos}},[_c('span',[_vm._v(_vm._s(_vm.$t('tos.accept')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }