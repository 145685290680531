<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ dataPoint && dataPoint.id ? $t('plan.edit_data_point') : $t('plan.add_new_date_and_value') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="dataPoint" class="pt-4">
        <v-form ref="form">
            <v-menu
              transition="scale-transition"
              offset-y
              v-model="dateModal"
              :close-on-content-click="false"
              :min-width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 24px)' : 'auto'"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="$formatDateNoTime(dataPoint.date)"
                  :label="$t('plan.date')"
                  :rules="[$rules.required]"
                  readonly>
                  <template v-slot:append>
                    <v-icon size="16" class="mt-2">lnr lnr-calendar-full</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                :full-width="$vuetify.breakpoint.xsOnly"
                v-model="dataPoint.date"
                no-title
                scrollable
                @input="dateModal = false"
                first-day-of-week="1"
                color="secondary"
                locale="en"></v-date-picker>
            </v-menu>
            <v-text-field :label="$t('plan.value')"
                          :rules="[$rules.required, $rules.number]"
                          inputmode="decimal"
                          :suffix="unit" v-model="dataPoint.value"></v-text-field>

            <div class="subtitle-1 mt-5 primary--text">{{ $t('plan.source') }}</div>

            <template v-if="dataPoint.userEmail">
              <v-text-field :label="$t('plan.source_user')"
                            readonly
                            :value="dataPoint.userFirstName ? `${dataPoint.userFirstName} ${dataPoint.userLastName} (${dataPoint.userEmail})` : dataPoint.userEmail"></v-text-field>
            </template>

            <data-point-source-combobox v-model="dataPoint.source"
                        :rules="[$rules.required]"
                        :label="$t('plan.data_source')"></data-point-source-combobox>

        <template v-if="dataPoint.id">
          <v-text-field :label="$t('plan.source_created_date')" readonly :value="dataPointCreateDate"></v-text-field>
        </template>


        <div class="subtitle-1 mt-5 mb-3 primary--text">{{ $t('plan.attachments') }}</div>

        <file-upload :use-description="true" :object-id="action.id"
                     :new-object="!dataPointId"
                     :field="dataPointId"
                     :object="'action'" ref="fileUpload"></file-upload>
        </v-form>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :disabled="saving"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :loading="saving"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import dataPointApi from "@/api/dataPointApi";
import actionApi from "@/api/actionApi";
import FileUpload from "@/components/FileUpload.vue";
import DataPointSourceCombobox from "@/components/DataPointSourceCombobox.vue";

export default {
  name: 'DatapointDialog',
  components: { DataPointSourceCombobox, FileUpload },
  props: ['action', 'unit'],
  data() {
    return {
      dialog: false,
      dateModal: false,
      dataPoint: null,
      executionKey: (Math.random() + 1).toString(36).substring(7),
      saving: false,
    }
  },
  watch: {
    dialog(to) {
      if (!to) {
        this.dataPoint = null;
      }
    },
    value(to) {
      if (!this.dataPoint || this.dataPoint.value == null)
        return;

      if (typeof(to) === 'string' && to.indexOf(',') !== -1) {
        this.dataPoint.value = to.replace(',', '.');
      }
    }
  },
  computed: {
    dataPointId() {
      if (this.dataPoint && this.dataPoint.id) {
        return this.dataPoint.id + '';
      }

      return null;
    },
    value() {
      if (!this.dataPoint || this.dataPoint.value == null)
        return null;

      return this.dataPoint.value;
    },
    dataPointCreateDate() {
      return this.$formatDateTime(this.dataPoint.created);
    }
  },
  methods: {
    generateNewKey() {
      this.executionKey = (Math.random() + 1).toString(36).substring(7);
    },
    addFile() {
      this.$refs.fileInput.click();
    },
    openAdd() {
      this.dataPoint = {
        created: new Date(),
      };
      this.dialog = true;
    },
    openEdit(dataPoint) {
      this.dataPoint = { ...dataPoint };
      this.dialog = true;
    },
    async save() {
      setTimeout(async () => {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.saving = true;
        try {
          const data = {...this.dataPoint, date: new Date(this.dataPoint.date).toISOString()};
          if (this.dataPoint.id) {
            const dataPoint = await dataPointApi.updateDataPoint(this.dataPoint.id, data);
            this.$emit('data-point-updated', dataPoint);
          } else {
            const dataPoint = await actionApi.createDataPoint(this.action.id, data);
            await this.$refs.fileUpload.uploadFiles({ field: dataPoint.id});
            this.$emit('data-point-added', dataPoint);
          }
          this.dialog = false;
        } catch (error) {
          this.$handleApiError(error);
        }
        this.saving = false;
      }, 0)

    },
    close() {
      this.dialog = false;
    }
  },
}
</script>

<style scoped>

</style>
