<template>
  <v-scale-transition>
    <v-btn v-if="!menuOpenComputed" icon @click="toggleMainMenu">
      <v-icon size="38" color="primary">lnr lnr-menu</v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
  export default {
    name: "MainMenuButton",
    methods: {
      toggleMainMenu() {
        this.$store.commit('toggleMainMenuState');
      },
    },
    computed: {
      menuOpenComputed() {
        return this.$store.state.mainMenuOpen;
      },
    }
  }
</script>

<style scoped>

</style>
