<template>
    <div v-if="settings" style="display: inline-block;" class="ignore-export">
    <v-switch v-model="settings.shared" @change="sharedChanged" class="mt-0">
      <template v-slot:label>
        <span class="switch-label">{{ $t('dashboards.share_dashboard') }}</span>
      </template>
      <template v-slot:prepend>
        <v-tooltip bottom class="ignore-export" content-class="ignore-export">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :loading="exporting" @click="exportPng">
              <v-icon>mdi mdi-download</v-icon>
            </v-btn>
          </template>
          <span class="ignore-export">Export as PNG</span>
        </v-tooltip>
        <v-tooltip bottom v-if="settings.shared" >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="copyLink"><v-icon>mdi mdi-content-copy</v-icon></v-btn>
          </template>
          <span>{{ $t('dashboards.copy_link') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="settings.shared" >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon :href="$getSharedUrl($tenant, settings.uuid)" target="_blank"><v-icon>mdi mdi-open-in-new</v-icon></v-btn>
          </template>
          <span>{{ $t('dashboards.open_in_new_tab') }}</span>
        </v-tooltip>
      </template>
    </v-switch>
    </div>
</template>

<script>
  import tenantApi from "../api/tenant";

  export default {
    props: ['value', 'planId', 'actionId'],
    name: 'DashboardSettings',
    data() {
      return {
        settings: null,
        exporting: false,
      }
    },
    watch: {
      value(newValue) {
        this.settings = newValue;
      },
    },
    methods: {
      async exportPng() {
        this.exporting = true;
        try {
          const data = await tenantApi.takeScreenshot({ dashboardCode: this.settings.code });
          this.$downloadAsImage(data);
        } catch (error) {
          console.error(error);
          this.$showErrorNotification(this.$t('errors.error_taking_screenshot'));
        }
        this.exporting = false;
      },
      async sharedChanged(e) {
        const settings = await tenantApi.updateDashboardSettings(this.settings.code, {
          shared: e,
          meta: this.settings.meta,
        });

        this.settings = settings;
        this.$emit('input', settings);
      },
      copyLink() {
        const el = document.createElement('textarea');
        el.value = this.$getSharedUrl(this.$tenant, this.settings.uuid);
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },
    },
    mounted() {
      this.settings = this.value;
    }
  }
</script>

<style lang="scss" scoped>
  .switch-label {
    font-size: 20px;
    font-weight: 300;
    line-height: 50px;
    height: 49px;
    margin-left: 6px;
    color: rgba(0,0,0,0.87)
  }
</style>
