import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticClass:"px-2"},[(_vm.editMode)?[_c(VListItemContent,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":_vm.$t('contributor.email'),"rules":[_vm.$rules.email, _vm.$rules.required],"validate-on-blur":"","dense":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":_vm.$t('contributor.name'),"validate-on-blur":"","dense":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":_vm.$t('contributor.company'),"validate-on-blur":"","dense":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1)],1)],1),_c(VListItemAction,{staticClass:"flex-row"},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.saveContributor}},[_c(VIcon,[_vm._v("save")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancelEdit}},[_c(VIcon,[_vm._v("close")])],1)],1)]:[_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.toggleInGroup}},'v-btn',attrs,false),on),[(_vm.inGroup)?_c(VIcon,[_vm._v("remove_circle_outline")]):_c(VIcon,[_vm._v("add")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.inGroup ? _vm.$t('contributor.remove_contributor_from_group') : _vm.$t('contributor.add_contributor_to_group'))+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.model.email))]),(_vm.model.name)?_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.model.name))]):_vm._e(),(_vm.model.company)?_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.model.company))]):_vm._e()],1),(_vm.inGroup)?_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.copyLink}},on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi mdi-content-copy")])],1)]}}],null,false,2630807349)},[_c('span',[_vm._v(_vm._s(_vm.$t('plan.copy_contributor_link')))])])],1):_vm._e(),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.setEditMode}},[_c(VIcon,[_vm._v("edit")])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }