<template>
  <div>
    <v-list color="transparent">
      <template v-for="contributorGroup in contributorGroups">
        <v-list-item
          style="background: none;"
          :key="`${contributorGroup.id}_item`"
        >
          <v-list-item-content>
            <v-list-item-title>{{ contributorGroup.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="my-0">
            <v-menu bottom left :close-on-content-click="$vuetify.breakpoint.smAndUp">
              <template v-slot:activator="{ on }">
                <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">more_vert</v-icon></v-btn>
              </template>
              <v-list dense class="pt-0 pb-0">
                <v-list-item @click="openEditContributorGroupDialog(contributorGroup)">
                  <v-list-item-avatar><v-icon>lnr lnr-users</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('contributor.manage_members') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="openContributorGroupDetails(contributorGroup)">
                  <v-list-item-avatar><v-icon>edit</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('contributor.rename_group') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteContributorGroup(contributorGroup)">
                  <v-list-item-avatar><v-icon>lnr lnr-circle-minus</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('contributor.remove_group') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-card-actions>
      <v-btn
          @click="openContributorGroupDetails()"
          color="primary"
          text>
        {{ $t('contributor.create_new_group') }}
      </v-btn>
    </v-card-actions>

    <contributor-group-members-dialog ref="contributorGroupMembersDialog" />
    <contributor-group-dialog ref="contributorGroupDialog" :contributor-groups="contributorGroups" @refresh="getContributorGroups" />
  </div>
</template>

<script>
import tenantApi from "@/api/tenant";
import Contributor from "@/components/Tenant/ContributorGroupManagement/Contributor";
import ContributorGroupMembersDialog from "./ContributorGroupMembersDialog";
import ContributorGroupDialog from "./ContributorGroupDialog";

export default {
  name: "ContributorGroupList",
  components: {ContributorGroupDialog, ContributorGroupMembersDialog, Contributor},
  data() {
    return {
      allContributors: [],
      contributorGroups: [],
      dialog: false,
      saving: false,
    }
  },
  methods: {
    openEditContributorGroupDialog(contributorGroup) {
      this.$refs.contributorGroupMembersDialog.open(contributorGroup);
    },
    async getAllContributors() {
      this.allContributors = await tenantApi.getContributors();
    },
    async getContributorGroups() {
      this.contributorGroups = await tenantApi.getContributorGroups();
    },
    async deleteContributorGroup(contributorGroup) {
      try {
        await tenantApi.deleteContributorGroup(contributorGroup.id);
        this.contributorGroups.splice(this.contributorGroups.indexOf(contributorGroup), 1);
        this.$showSuccessNotification(this.$t('contributor.group_deleted'));
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('contributor.group_delete_fail'));
      }
    },
    openContributorGroupDetails(contributorGroup) {
      this.$refs.contributorGroupDialog.open(contributorGroup);
    },
  },
  mounted() {
    this.getContributorGroups();
    this.getAllContributors();
  }
}
</script>

<style scoped>
.contributors-list {
  max-height: 500px;
  overflow: auto;
}
</style>
