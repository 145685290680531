import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';

// Admin views
import UserManagementView from './views/UserManagementView.vue';
import Tenants from './views/TenantsView.vue';

// User views
import UserProfile from './views/UserProfile.vue';

// Tenant views
import TenantContainer from "./views/TenantContainer.vue";
import TenantUserManagementView from "./views/tenant/TenantUserManagementView.vue";
import SingleTenantTestView from "./views/tenant/SingleTenantTestView.vue";
import TenantManagementView from "./views/tenant/TenantManagementView.vue";
import NameYourPlanView from "./views/tenant/NameYourPlanView";
import PlanView from "./views/tenant/PlanView";
import PlansView from "./views/tenant/PlansView";
import Dashboards from "./views/tenant/Dashboards";
import OverviewDashboardView from "./views/tenant/OverviewDashboardView";
import SDGDashboardView from "./views/tenant/SDGDashboardView";
import ImpactsDashboardView from "./views/tenant/ImpactsDashboardView";
import ActionDashboardView from "./views/tenant/ActionDashboardView";
import MySDGPerformanceDashboardView from "./views/tenant/MySDGPerformanceDashboardView";
import NewAccountView from './views/NewAccountView.vue';
import SystemSettingsView from "@/views/SystemSettingsView.vue";
import CustomImpactDashboardView from "@/views/tenant/CustomImpactDashboardView";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/',
      name: 'start',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/EntryView'),
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/dummy',
      name: 'dummy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/StartView.vue'),
    },
    {
      path: '/new_account',
      name: 'new_account',
      component: NewAccountView,
    },
    {
      path: '/t/:tenantId',
      props: true,
      component: TenantContainer,
      children: [
        {
          path: 'nameyourplan',
          name: 'name_your_plan',
          props: true,
          component: NameYourPlanView,
        },
        {
          path: 'plans',
          name: 'plans',
          props: true,
          component: PlansView,
        },
        {
          path: 'plan/:planId',
          name: 'plan',
          props: true,
          component: PlanView,
        },
        {
          path: 'dashboards',
          name: 'dashboards',
          props: true,
          component: Dashboards,
        },
        {
          path: 'plan/:planId/dashboards/overview',
          name: 'overview_dashboard',
          props: true,
          component: OverviewDashboardView,
        },
        {
          path: 'plan/:planId/dashboards/my_sdg_performance',
          name: 'my_sdg_performance_dashboard',
          props: true,
          component: MySDGPerformanceDashboardView,
        },
        {
          path: 'plan/:planId/dashboards/my_custom_impacts/:rootCustomImpactId',
          name: 'my_custom_impacts',
          props: true,
          component: CustomImpactDashboardView,
        },
        {
          path: 'plan/:planId/dashboards/action/:actionId',
          name: 'action_dashboard',
          props: true,
          component: ActionDashboardView,
        },
        {
          path: 'plan/:planId/dashboards/impacts',
          name: 'impacts_dashboard',
          props: true,
          component: ImpactsDashboardView,
        },
        {
          path: 'plan/:planId/dashboards/sdg/:sdgIndex',
          name: 'sdg_dashboard',
          props: true,
          component: SDGDashboardView,
        },
        {
          path: 'tenant_test',
          name: 'tenant_test',
          props: true,
          component: SingleTenantTestView,
        },
        {
          path: 'users',
          name: 'tenant_users',
          props: true,
          component: TenantUserManagementView,
        },
        {
          path: 'settings',
          name: 'tenant_settings',
          props: true,
          component: TenantManagementView,
        },
        {
          path: '*',
          redirect: '/dashboards',
        },
      ]
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
    },
    {
      path: '/system_settings',
      name: 'system_settings',
      component: SystemSettingsView,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
