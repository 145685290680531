import axios from "axios";

const actionApi = {
  async createDataPoint(actionId, dataPoint) {
    const response = await axios.post(`api/tenant/action/${actionId}/datapoint`, dataPoint);
    return response.data;
  },
  async createActionLog(actionId, actionLogData) {
    const response = await axios.post(`api/tenant/action/${actionId}/log`, actionLogData);
    return response.data;
  },
  async updateActionLog(actionId, actionLogId, actionLogData) {
    const url = actionLogId != null ? `api/tenant/action/${actionId}/log/${actionLogId}` : `api/tenant/action/${actionId}/log/note`;
    const response = await axios.post(url, actionLogData);
    return response.data;
  },
  async deleteActionLog(actionId, actionLogId) {
    const url = actionLogId != null ? `api/tenant/action/${actionId}/log/${actionLogId}` : `api/tenant/action/${actionId}/log`;
    const response = await axios.delete(url);
    return response.data;
  },
  async getActionLogs(actionId) {
    const response = await axios.get(`api/tenant/action/${actionId}/log`);
    return response.data;
  },
  async exportActionLogsExcel(actionId) {
    return await axios({
      url: `/api/tenant/action/${actionId}/log/export`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data);
  },
}

export default actionApi;
