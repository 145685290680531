<template>
  <v-dialog v-model="dialog" ref="dialogi"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card ref="xxx">
      <v-toolbar dark color="primary" ref="yyy">
        <v-toolbar-title v-if="customImpact && customImpact.level === 0">{{ customImpact.id ? $t('custom_impacts.edit') : $t('custom_impacts.create') }}</v-toolbar-title>
        <v-toolbar-title v-else-if="customImpact && customImpact.level === 1">{{ customImpact.id ? $t('custom_impacts.edit_child') : $t('custom_impacts.create_child') }}</v-toolbar-title>
        <v-toolbar-title v-else-if="customImpact && customImpact.level === 2">{{ customImpact.id ? $t('custom_impacts.edit_childs_child') : $t('custom_impacts.create_childs_child') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dismissDialog">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5">
<!--        <v-alert class="mt-10" type="info" :value="mode && mode !== 'edit'" outlined>{{ infoText }}</v-alert>-->
        <v-text-field v-model="customImpact.text" :label="$t('custom_impacts.name')" autofocus></v-text-field>
        <v-color-picker v-if="showColorPicker" v-model="customImpact.color"></v-color-picker>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dismissDialog"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :disabled="saving"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :loading="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "@/api/tenant";

export default {
  name: 'CustomImpactDialog',
  data() {
    return {
      dialog: false,
      customImpact: {},
      mode: null,
      parent: null,
      defaultColor: '#59949d',
      saving: false,
    }
  },
  watch: {
    dialog(to) {
      if (!to) {
        this.reset();
      }
    }
  },
  computed: {
    showColorPicker() {
      return this.customImpact.level === 1;
    },
    infoText() {
      // if (this.customImpact.level === 0) {
      //   return 'new-custom-impact';
      // } else if (this.mode === 'new_child') {
      //   if (this.parent.parentId == null) {
      //     return 'uus custom impacti';
      //   } else {
      //     return 'uus detailed custom impacti';
      //   }
      // } else if (this.mode === 'edit') {
      //   return ''
      // }

      return '';
    }
  },
  methods: {
    async save() {
      this.saving = true;

      try {
        if (this.customImpact.id) {
          const customImpact = await tenantApi.updateCustomImpact(this.customImpact.id, {
            text: this.customImpact.text,
            color: this.customImpact.color
          });
          this.$emit('custom-impact-saved', customImpact)
        } else {
          const customImpact = await tenantApi.createCustomImpact(this.customImpact);
          this.$emit('custom-impact-created', customImpact)
        }

        this.dismissDialog();
      } catch (error) {
        this.$handleApiError(error);
      }

      this.saving = false;
    },
    dismissDialog() {
      this.dialog = false;
    },
    reset() {
      this.parent = null;
      this.customImpact = { color: this.defaultColor };
      this.mode = null;
      this.saving = false;
    },
    openNewRoot() {
      this.parent = null;
      this.mode = 'new_root';
      this.customImpact = { text: '', parentId: null, level: 0 };
      this.dialog = true;
    },
    openNewChild(parent) {
      this.parent = parent;
      this.mode = 'new_child';
      const level = parent.level + 1;
      this.customImpact = {
        text: '',
        parentId: parent.id,
        level: level,
        color: level === 1 ? '#' + Math.floor(100000 + Math.random() * 900000) : '',
      };
      this.dialog = true;
    },
    openEdit(customImpact) {
      this.parent = null;
      this.mode = 'edit';
      this.customImpact = { ...customImpact };
      if (this.customImpact.color == null) {
        this.customImpact.color = this.defaultColor;
      }
      this.dialog = true;
    },
  },
}
</script>

<style scoped>

</style>
