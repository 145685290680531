<template>

    <v-card class="grey lighten-4">
      <v-card-title>
        <div class="headline">{{ $t('custom_impacts.title') }}</div>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" :value="true" prominent text>
          <div>Custom impacts allows you to create your own impacts and detailed impacts.</div>
          <div>Detailed impacts are subcategories of your
          impacts. Your custom impacts are visible in Plans
            when creating Actions.</div>
        </v-alert>
      </v-card-text>
      <v-treeview :items="customImpactsStructure" open-all v-if="!loading">
        <template v-slot:label="{item}">
        <span :class="{ 'font-weight-bold': item.level === 0 }">
        {{ item.text }} <span v-if="item.level === 1" :style="{'background-color': item.color}" style="border-radius:20px" class="px-5 ml-1"></span>
        </span>
          <v-btn small text color="primary" class="ml-1" v-if="item.level < 2" @click="addChildClicked(item)">
            <v-icon left>lnr lnr-plus-circle</v-icon>
            {{
              item.level === 0 ? $t('custom_impacts.add_impact') : $t('custom_impacts.add_detailed_impact')
            }}
          </v-btn>
        </template>
        <template v-slot:append="{item}">

          <v-btn small icon color="primary" @click="editCustomImpactClicked(item)"><v-icon size="16">lnr lnr-pencil</v-icon></v-btn>
          <v-btn small icon color="error" :loading="deleting[item.id]" @click="deleteCustomImpactClicked(item)"><v-icon size="16">lnr lnr-trash</v-icon></v-btn>
        </template>
      </v-treeview>
      <v-card-actions>
        <v-btn text color="primary" @click="newCustomImpactClicked">{{ $t('custom_impacts.add') }}</v-btn>
      </v-card-actions>

      <custom-impact-dialog ref="customImpactDialog"
                            @custom-impact-saved="reloadImpacts"
                            @custom-impact-created="reloadImpacts"></custom-impact-dialog>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>

    </v-card>

</template>

<script>
import tenant from "@/api/tenant";
import CustomImpactDialog from "@/components/Tenant/TenantManagement/CustomImpactDialog";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: 'CustomImpactManagement',
  components: { ConfirmDialog, CustomImpactDialog },
  data() {
    return {
      loading: true,
      impacts: [],
      deleting: {},
    }
  },
  computed: {
    customImpactsStructure() {
      const impacts = this.impacts.map(i => ({ ...i, children: [] }));
      const roots = [];

      const getLevel = (impact) => {
        if (impact.parentId) {
          const parent = impacts.find(i => i.id === impact.parentId);
          return getLevel(parent) + 1;
        } else {
          return 0;
        }
      }

      impacts.forEach(i => {
        i.level = getLevel(i);
        if (i.parentId) {
          const parent = impacts.find(ii => ii.id === i.parentId);
          parent.children.push(i);
        } else {
          roots.push(i);
        }
      })

      return roots;
    }
  },
  methods: {
    addChildClicked(customImpact) {
      this.$refs.customImpactDialog.openNewChild(customImpact);
    },
    editCustomImpactClicked(customImpact) {
      this.$refs.customImpactDialog.openEdit(customImpact);
    },
    async deleteCustomImpactClicked(customImpact) {
      const confirm = await this.$refs.confirmDialog.open(this.$t('custom_impacts.delete_confirm_title'), this.$t('custom_impacts.delete_confirm_text'), { color: 'error', agree: this.$t('delete'), cancel: this.$t('cancel')});
      if (!confirm) {
        return;
      }

      this.$set(this.deleting, customImpact.id, true);
      try {
        await tenant.deleteCustomImpact(customImpact.id);
        await this.reloadImpacts();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.$set(this.deleting, customImpact.id, false);
    },
    async newCustomImpactClicked() {
      this.$refs.customImpactDialog.openNewRoot();
    },
    async reloadImpacts() {
      try {
        this.impacts = await tenant.getCustomImpacts();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
    }
  },
  mounted() {
    this.reloadImpacts();
  }
}
</script>

<style scoped>

</style>
