<template>
  <v-col cols="12">

    <hb-heading class="mt-0" :value="options.header" />

    <v-alert prominent class="text-left" text :value="true" v-if="error" type="error">
      {{ error }}
    </v-alert>

    <v-text-field  v-model="username"
                   :placeholder="$t('login.username')"
                   :label="$t('login.username')"
                   full-width
                   solo
                   :disabled="sent"
                   autofocus
                   background-color="#f4f4f4"
                   flat
                   :height="$vuetify.breakpoint.smAndUp ? 65 : ''"
                   :style="{fontSize: $vuetify.breakpoint.xsOnly ? '22px' : '33px'}" />

    <v-text-field  v-model="code"
                   v-if="sent"
                   :placeholder="$t('login.code')"
                   :label="$t('login.code')"
                   full-width
                   solo
                   background-color="#f4f4f4"
                   flat
                   :height="$vuetify.breakpoint.smAndUp ? 65 : ''"
                   :style="{fontSize: $vuetify.breakpoint.xsOnly ? '22px' : '33px'}" />

    <v-text-field  v-model="password"
                   v-if="sent"
                   type="password"
                   :placeholder="$t('login.new_password')"
                   full-width
                   solo
                   background-color="#f4f4f4"
                   flat
                   hide-details
                   :height="$vuetify.breakpoint.smAndUp ? 65 : ''"
                   :style="{fontSize: $vuetify.breakpoint.xsOnly ? '22px' : '33px'}" />


    <v-row justify="center mt-5">
      <v-btn v-if="!sent" x-large color="primary" text @click="signIn">{{ $t('login.back_to_sign_in') }}</v-btn>
      <v-btn v-if="sent" x-large color="primary" text @click="signIn">{{ $t('login.resend_code') }}</v-btn>
      <v-btn v-if="!sent" x-large color="primary" @click="submit" :disabled="!username">{{ $t('login.send_code') }}</v-btn>
      <v-btn v-if="sent" x-large color="primary" @click="verify" :disabled="!username">{{ $t('login.submit') }}</v-btn>
    </v-row>

  </v-col>

</template>

<script>
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import * as AmplifyUI from '@aws-amplify/ui';

  export default {
    name: 'ForgotPassword',
    props: ['forgotPasswordConfig'],
    data() {
      return {
        username: '',
        code: '',
        password: '',
        error: '',
        sent: false,
        logger: {},
        amplifyUI: AmplifyUI,
      }
    },
    computed: {
      options() {
        const defaults = {
          header: this.$Amplify.I18n.get('Reset your password'),
        };
        return Object.assign(defaults, this.forgotPasswordConfig || {})
      },
    },
    mounted() {
      this.logger = new this.$Amplify.Logger(this.$options.name)
    },
    methods: {
      submit: function () {
        this.username = this.username.toLowerCase();
        this.$Amplify.Auth.forgotPassword(this.username)
          .then(() => {
            this.sent = true;
            this.logger.info('forgotPassword success');
          })
          .catch(e => this.setError(e));
      },
      verify: function () {
        this.username = this.username.toLowerCase();
        this.$Amplify.Auth.forgotPasswordSubmit(this.username, this.code, this.password)
          .then(() => {
            this.logger.info('forgotPasswordSubmit success');
            AmplifyEventBus.$emit('authState', 'signIn');
          })
          .catch(e => this.setError(e));
      },
      signIn: function () {
        AmplifyEventBus.$emit('authState', 'signIn');
      },
      setError: function (e) {
        this.error = this.$Amplify.I18n.get(e.message || e);
        this.logger.error(this.error);
      },
    },
  }
</script>
