import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import i18n from './i18n.js';
import vuetify from './plugins/vuetify.plugin.js';
import Axios from 'axios' // set defaults before any other imports
import dayjs from 'dayjs'

import HelperPlugin from './plugins/helper.plugin'
import ValidationPlugin from './plugins/validation.plugin'
import RolesPlugin from './plugins/roles.plugin'
import App from './App.vue'
import router from './router'
import store from './store'
import UiEventBus from './UiEventBus'

//import messages from './config/lang'

import AppBar from "./components/AppBar";
import BackButton from './components/BackButton.vue';
import MainMenuButton from './components/MainMenuButton.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import Heading from "./components/Heading.vue";

import UserService from './service/user.service';
import TenantService from './service/tenant.service';

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsmobile from './aws-exports'
import userApi from "./api/user";

Amplify.configure(awsmobile)

AmplifyModules.I18n.putVocabulariesForLanguage("fi", {
  'Username': 'Sähköposti',
  'Password': 'Salasana',
  'Forgot your password? ': 'Unohditko salasanan?',
  'Reset password': 'Nollaa salasana',
  'Sign In': 'Kirjaudu sisään',
  'Enter your username': 'Sähköposti',
  'Enter your password': 'Salasana',
  'Reset your password': 'Nollaa salasanasi',
  'Back to Sign In': 'Takaisin kirjautumiseen',
  'Resend Code': 'Lähetä nollauskoodi uudestaan',
  'Send Code': 'Lähetä',
  'New Password': 'Uusi salasana',
  'Code': 'Koodi',
  'Submit': 'Tallenna',
  'Enter new password': 'Anna uusi salasana',
});

AmplifyModules.I18n.setLanguage('en');

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(HelperPlugin);
Vue.use(ValidationPlugin);
Vue.use(RolesPlugin);

Vue.component('hb-back-button', BackButton);
Vue.component('hb-main-menu-button', MainMenuButton);
Vue.component('hb-loading-indicator', LoadingIndicator);
Vue.component('hb-app-bar', AppBar);
Vue.component('hb-heading', Heading);

const hasCustomBaseUrl = !!process.env.VUE_APP_API_BASEURL;
if (hasCustomBaseUrl) {
  Axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL
}
Axios.defaults.withCredentials = true;
Axios.defaults.headers.common['Pragma'] = 'no-cache';
Axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';
Vue.config.productionTip = false;

async function getUserModelObject() {
  let response = await userApi.getMe();

  const session = UserService.getSession()
  const email = session.email;

  if (response.email == null || response.email.length === 0) {
    response = await userApi.updateProfile({
      email,
      firstName: response.firstName,
      lastName: response.lastName,
    });
  }

  try {
    const userInfo = {};
    userInfo.roles = response.roles;
    userInfo.firstName = response.firstName;
    userInfo.lastName = response.lastName;
    userInfo.username = response.username;
    userInfo.agentUser = response.agentUser;
    userInfo.enabled = response.enabled;
    userInfo.subject = response.subject;
    userInfo.tosAcceptDate = response.tosAcceptDate;
    userInfo.id = response.id;
    userInfo.email = response.email;
    userInfo.tenants = response.tenants;
    UserService.setUserInfo(userInfo);

    const currentTenant = TenantService.tenant;
    if (currentTenant == null || response.tenants.findIndex(t => t.id === currentTenant) === -1) {
      if (response.tenants.length > 0) {
        TenantService.setTenant(response.tenants[0].id);
      }
    }

    return userInfo;
  } catch (error) {
    UserService.clearUserInfo();
    console.log(error);
    return null;
  }
}

UiEventBus.$on('userLoggedIn', async (/*email*/) => {
  console.log('userLoggedIn');
  try {
    await store.dispatch('loadSystemData');
    await getUserModelObject();
  } catch (error) {
    console.log(error)
  }
});

UiEventBus.$on('loginSuccess', () => {
  router.push({ name: 'start' });
});

function getCurrentSession(amplify) {
  let userInfo = UserService.getSession();
  let isValid = userInfo != null && dayjs().add(5,'minutes').isBefore(dayjs(userInfo.accessTokenExpires));
  if (!isValid) {
    return amplify.Auth.currentSession()
      .then((session) => {
        const accessTokenExpires = dayjs(session.accessToken.payload.exp * 1000);
        const sessionInfo = {
          sub: session.idToken.payload.sub,
          email: session.idToken.payload.email,
          roles: '',
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          accessTokenExpires
        };
        UserService.setSession(sessionInfo);
        return Promise.resolve(sessionInfo);
      })
      .catch(() => {
        UserService.clearUserInfo();
        return Promise.reject(null)
      })
  } else {
    return Promise.resolve(userInfo)
  }
}

Axios.interceptors.request.use( (config) => {
  UiEventBus.$emit('addProgress');
  let userInfo = UserService.getSession();
  let isValid = userInfo != null && dayjs().add(5,'minutes').isBefore(dayjs(userInfo.accessTokenExpires));
  console.log({ isValid })
  if (!isValid) {

    return getCurrentSession(vue.$Amplify)
      .then((userInfo) => {
        config.headers.Authorization = 'Bearer ' + userInfo.accessToken;
        return Promise.resolve(config)
      }).then()
      .catch((error) => {
        console.log(error);
        return Promise.resolve(config)
      })
  } else if (isValid && userInfo != null) {
//     console.log(userInfo);
    config.headers.Authorization = 'Bearer ' + userInfo.accessToken;
  }
  return Promise.resolve(config)
},(error) => {
  UiEventBus.$emit('removeProgress');
  return Promise.reject(error)
});

const vue = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

if (UserService.getSession() != null) {
  getUserModelObject().then(userInfo => {
    if (userInfo == null) {
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login' });
      }
    } else {
      store.dispatch('loadSystemData');
      console.log('app opened with valid session')
    }
  });
} else {
  store.commit('setLoadingOverlay', false);
  if (router.currentRoute.name !== 'login') {
    router.push({ name: 'login' });
  }
}

router.beforeEach((to, from, next) => {
  console.log('route change', from.path, to.path);
  const isLoggedIn = UserService.getUserInfo() != null && UserService.getSession() != null;
  console.log({isLoggedIn})
  if (to.name !== 'login' && !isLoggedIn) {
    next({ name: 'login' });
  } else {
    next();
  }
});

// Add a response interceptor
Axios.interceptors.response.use((response) => {
  UiEventBus.$emit('removeProgress');
  return response
}, (error) => {
  UiEventBus.$emit('removeProgress');
  return Promise.reject(error)
});
