<template>
  <v-col cols="12">

    <hb-heading class="mt-0" :value="options.header" />

    <v-alert prominent class="text-left" text :value="true" v-if="error" type="error">
      {{ error }}
    </v-alert>

    <v-text-field  v-model="password"
                   type="password"
                   :placeholder="$t('login.new_password')"
                   full-width
                   solo
                   autofocus
                   background-color="#f4f4f4"
                   flat
                   hide-details
                   :height="$vuetify.breakpoint.smAndUp ? 65 : ''"
                   :style="{fontSize: $vuetify.breakpoint.xsOnly ? '22px' : '33px'}" />

    <div v-bind:class="amplifyUI.formField"
         v-for="attribute in options.user.challengeParam.requiredAttributes"
         :attribute="attribute"
         v-bind:key="attribute">
      <div v-bind:class="amplifyUI.inputLabel">{{attribute.charAt(0).toUpperCase() + attribute.slice(1)}}</div>
      <input
        v-bind:class="amplifyUI.input"
        v-model="requiredAttributes[attribute]"
        :placeholder="attribute.charAt(0).toUpperCase() + attribute.slice(1)"
      />
    </div>

    <v-row justify="center mt-5">
      <v-btn x-large color="primary" text @click="signIn">{{ $t('login.back_to_sign_in') }}</v-btn>
      <v-btn x-large color="primary" @click="change">{{ $t('login.submit') }}</v-btn>
    </v-row>

  </v-col>
</template>
<script>
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import * as AmplifyUI from '@aws-amplify/ui';

  export default {
    name: 'RequireNewPassword',
    props: ['requireNewPasswordConfig'],
    data () {
      return {
        user: '',
        error: '',
        password: '',
        logger: {},
        requiredAttributes: {},
        amplifyUI: AmplifyUI
      }
    },
    computed: {
      options() {
        const defaults = {
          header: this.$Amplify.I18n.get('Enter new password'),
          user: {
            challengeParam: {
              requiredAttributes: []
            }
          }
        }
        return Object.assign(defaults, this.requireNewPasswordConfig || {})
      }
    },
    mounted() {
      this.logger = new this.$Amplify.Logger(this.$options.name)
    },
    methods: {
      setError: function(e) {
        this.error = this.$Amplify.I18n.get(e.message || e);
        this.logger.error(this.error);
      },
      checkContact(user) {
        this.$Amplify.Auth.verifiedContact(user)
          .then(/*data*/ () => {
            AmplifyEventBus.$emit('localUser', this.user)
            AmplifyEventBus.$emit('authState', 'signedIn')
          })
          .catch((e) => this.setError(e))
      },
      change() {
        this.$Amplify.Auth.completeNewPassword(this.options.user, this.password, this.requiredAttributes)
          .then(/* user */() => {
            if (this.options.user.challengeName === 'SMS_MFA') {
              AmplifyEventBus.$emit('localUser', this.options.user)
              AmplifyEventBus.$emit('authState', 'confirmSignIn')
            } else if (this.options.user.challengeName === 'MFA_SETUP') {
              AmplifyEventBus.$emit('localUser', this.options.user)
              AmplifyEventBus.$emit('authState', 'setMfa')
            } else {
              this.checkContact(this.options.user);
            }
          })
          .catch(err => this.setError(err));
      },
      signIn: function() {
        AmplifyEventBus.$emit('authState', 'signIn')
      },
    }
  }
</script>
