import axios from 'axios';

const dataPointApi = {
  async updateDataPoint(dataPointId, dataPoint) {
    const response = await axios.post(`api/tenant/datapoint/${dataPointId}`, dataPoint);
    return response.data;
  },
  async deleteDataPoint(dataPointId) {
    const response = await axios.delete(`api/tenant/datapoint/${dataPointId}`);
    return response.data;
  }
}

export default dataPointApi;
