<template>
  <div>
    <v-select :items="countries" item-value="code" item-text="name" v-model="selectedCountry" :rules="rules"
              v-if="solo"
              validate-on-blur
              :label="$t('tenants.details.country')"
              solo
              background-color="#f4f4f4"
              flat
              autocomplete="country">
    </v-select>
    <v-select :items="countries" item-value="code" item-text="name" v-model="selectedCountry" :rules="rules"
              v-else
              validate-on-blur
              :label="$t('tenants.details.country')"
              autocomplete="country">
    </v-select>
  </div>
</template>

<script>
  import countries from "../config/countries";

  export default {
    name: 'CountrySelect',
    props: {
      value: {
        type: String,
        default: '',
      },
      rules: {
        type: [Array, String, Function],
      },
      solo: {
        type: [Boolean],
      }
    },
    data() {
      return {
        selectedCountry: '',
      }
    },
    watch: {
      selectedCountry(newValue) {
        this.$emit('input', newValue);
      },
      value(newValue) {
        this.selectedCountry = newValue;
      }
    },
    computed: {
      countries() {
        return countries;
      },
    },
    mounted() {
      if (this.value != null && this.value.length > 0) {
        this.selectedCountry = this.value;
      }
    }
  }
</script>

<style scoped>

</style>
