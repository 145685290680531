<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('system_settings.edit_indicator') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">
          <v-text-field
            class="mt-5"
            :label="$t('system_settings.short_description')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="short_description"
            v-model="currentIndicator.shortDescription"
          ></v-text-field>

          <v-textarea
            class="mt-5"
            :label="$t('system_settings.description')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="code"
            v-model="currentIndicator.description"
          ></v-textarea>
          <v-textarea
            class="mt-5"
            :label="$t('system_settings.keywords')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="keywords"
            v-model="currentIndicator.keywords"
          ></v-textarea>

          <v-select label="Impact" :items="impacts" v-model="selectedImpact" :rules="[$rules.required]" item-text="fullText" item-value="id"></v-select>

        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields')}}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveIndicator"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditIndicatorDialog",
  props: ['impacts'],
  data() {
    return {
      dialog: false,
      showAlert: false,
      loading: false,
      currentIndicator: {},
      selectedImpact: null,
    }
  },
  methods: {
    async saveIndicator() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      this.loading = true;

      try {
        const currentIndicator = this.currentIndicator;
        const updatedIndicator = await this.$systemApi.updateIndicator(this.currentIndicator.code, {
          description: currentIndicator.description,
          shortDescription: currentIndicator.shortDescription,
          keywords: currentIndicator.keywords,
          impactId: this.selectedImpact,
        });

        this.$emit('indicator-updated', updatedIndicator);

        this.dialog = false;
        this.$showSuccessNotification(this.$t('system_settings.indicator_update_success'));
      } catch (err) {
        this.$handleApiError(err, this.$t('system_settings.indicator_update_failed'));
      }

      this.loading = false;
    },
    open(indicator) {
      this.dialog = true;
      this.currentIndicator = Object.assign({}, indicator);
      this.selectedImpact = indicator.impact.id;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.currentIndicator = {};
        this.selectedImpact = null;
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
}
</script>

<style scoped>

</style>
