import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"tenant-test",attrs:{"fill-height":_vm.loading}},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.subtleLoading),expression:"subtleLoading"}],staticStyle:{"position":"fixed","left":"0","right":"0","margin":"0","z-index":"99"},style:({top: _vm.$vuetify.breakpoint.smAndDown ? '54px' : '62px'}),attrs:{"color":"primary lighten-2","height":"2","indeterminate":true}}),_c('hb-app-bar'),(_vm.loading)?_c('hb-loading-indicator',{attrs:{"align-middle":""}}):_vm._e(),_c(VFadeTransition,[(!_vm.loading)?_c(VRow,{staticStyle:{"position":"relative"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"type":"info","value":true,"prominent":"","text":""}},[_vm._v(" Tenanttijuttujen testausta varten, sivu on sellanen että pitää olla tenantti valittuna että toimii ja toiminnot tapahtuvat vain kyseisen tenantin dataan. ")]),_c(VCard,[_c(VCardText,[_c(VTextField,{attrs:{"label":"Viesti","hint":"Kirjoita viesti ja lähetä se niin se näkyy vain tällä tenantilla.","persistent-hint":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","icon":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.saveNewHello}},[(_vm.$vuetify.breakpoint.xsOnly)?[_c(VIcon,[_vm._v("send")])]:[_vm._v("Lähetä")]],2)]},proxy:true}],null,false,1833882805),model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})],1)],1),_c(VList,{staticClass:"mt-5",attrs:{"color":"transparent"}},[_c(VDivider),_vm._l((_vm.hellos),function(hello){return [_c(VListItem,{key:hello.id},[_c(VListItemTitle,[_vm._v(_vm._s(hello.msg))]),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteHello(hello)}}},[_c(VIcon,[_vm._v("delete")])],1)],1)],1),_c(VDivider,{key:((hello.id) + "_divider")})]})],2)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }