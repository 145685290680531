<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('plan.copy_action') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-alert class="mt-10" type="info" :value="true" outlined>{{ $t('plan.copy_action_to_tenant_info' )}}</v-alert>
        <v-select :items="accounts" item-text="name" :label="$tc('plan.copy_action_account', 1)" item-value="id" v-model="selectedAccountId"></v-select>
        <v-select :disabled="selectedAccountId == null" :items="plans" item-text="name" :label="$tc('plan.title', 1)" item-value="id" v-model="selectedPlanId"></v-select>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dismissDialog"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :disabled="saving"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="copyToPlan"
               :loading="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('copy') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "@/api/tenant";

export default {
  name: 'CopyActionToTenantDialog',
  data() {
    return {
      dialog: false,
      action: null,
      selectedPlanId: null,
      selectedAccountId: null,
      saving: false,
      plans: [],
    }
  },
  computed: {
    accounts() {
      return this.$userInfo.tenants.filter(t => t.id !== this.$tenant);
    },
  },
  watch: {
    selectedAccountId(to) {
      if (to == null) {
        this.plans = [];
      }

      this.reloadPlans();
    },
  },
  methods: {
    open(action) {
      this.reloadPlans();
      this.action = action;
      this.dialog = true;
    },
    dismissDialog() {
      this.dialog = false;
      this.selectedPlanId = null;
      this.action = null;
      this.plans = [];
    },
    async copyToPlan() {
      if (this.selectedPlanId != null) {
        this.saving = true;
        try {
          const actionData = Object.assign({}, this.action);
          delete actionData['id'];
          delete actionData['dataPoints'];
          delete actionData['created'];
          delete actionData['updated'];

          const dataPoints = await tenantApi.getDataPoints(this.action.id);

          tenantApi.setSelectedTenant(this.selectedAccountId);
          const createdAction = await tenantApi.createAction(this.selectedPlanId, actionData);
          const dataPointRequests = dataPoints.map(dp => ({
            value: dp.value,
            date: dp.date,
            type: dp.type,
            source: dp.source,
          }));

          for(let i = 0; i < dataPointRequests.length; ++i) {
            const dpr = dataPointRequests[i];
            await tenantApi.createDataPoint(createdAction.id, dpr);
          }

          this.$showSuccessNotification(this.$t('plan.action_copy_success'));
          this.$emit('action-copied', createdAction);
          this.dismissDialog();
        } catch (error) {
          this.$handleApiError(error);
        }
        tenantApi.setSelectedTenant(this.$tenant);
        this.saving = false;
      }
    },
    async reloadPlans() {
      if (this.selectedAccountId == null) {
        return;
      }

      try {
        const plans = await tenantApi.getPlansForTenant(this.selectedAccountId);
        this.plans = plans;
      } catch (error) {
        this.$handleApiError(error);
      }
    }
  },
}
</script>

<style scoped>

</style>
