<template>
  <v-col cols="12">

    <hb-heading class="mt-0" :value="$t('login.title')" />

    <v-alert prominent class="text-left" text :value="true" v-if="error" type="error">
      {{ error }}
    </v-alert>
    <v-alert prominent class="text-left" text :value="true" v-if="isConfirmSignup && !confirmationSent" type="error">
      <div>{{ $t('login.confirm_email') }}</div>
      <v-btn :loading="sendingConfirmation" outlined color="error" small class="mt-2" @click="sendConfirmation">{{ $t('login.resend_confirmation') }}</v-btn>
    </v-alert>

    <v-alert prominent class="text-left" text :value="true" v-if="confirmationSent" type="info">
      <div>{{ $t('login.confirm_email_sent') }}</div>
    </v-alert>

    <v-text-field  v-model="options.username"
                   :placeholder="$t('login.username')"
                   :label="$t('login.username')"
                   full-width
                   solo
                   autofocus
                   background-color="#f4f4f4"
                   flat
                   :height="$vuetify.breakpoint.lgAndDown ? '' : 95"
                   :style="{fontSize: $vuetify.breakpoint.lgAndDown ? '22px' : '33px'}"
                   v-on:keyup.enter="signIn" />

    <v-text-field  v-model="password"
                   type="password"
                   :placeholder="$t('login.password')"
                   full-width
                   solo
                   background-color="#f4f4f4"
                   flat
                   hide-details
                   :height="$vuetify.breakpoint.lgAndDown ? '' : 95"
                   :style="{fontSize: $vuetify.breakpoint.lgAndDown ? '22px' : '33px'}"
                   v-on:keyup.enter="signIn" />

    <div class="text-center text-sm-right font-weight-light pt-3 pr-sm-5 pb-5" :style="{fontSize: $vuetify.breakpoint.xsOnly ? '15px' : ''}">
      {{$Amplify.I18n.get('Forgot your password? ')}}
      <a v-bind:class="amplifyUI.a" v-on:click="forgot">{{$Amplify.I18n.get('Reset password')}}</a>
    </div>

    <div class="text-center mt-3">
      <v-btn :x-large="$vuetify.breakpoint.xlOnly" color="primary" :loading="signingIn" @click="signIn">{{ $t('login.sign_in') }}</v-btn>
    </div>

  </v-col>
</template>

<script>
  // import Auth from '@aws-amplify/auth';
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import * as AmplifyUI from '@aws-amplify/ui';

  export default {
    name: 'SignIn',
    props: ['signInConfig', 'isConfirmSignup'],
    data() {
      return {
        sendingConfirmation: false,
        confirmationSent: false,
        password: '',
        error: '',
        amplifyUI: AmplifyUI,
        logger: {},
        signingIn: false,
      }
    },
    computed: {
      options() {
        const defaults = {
          header: this.$Amplify.I18n.get('Sign In Account'),
          username: '',
          isSignUpDisplayed: true,
        };
        return Object.assign(defaults, this.signInConfig || {})
      },
    },
    mounted() {
      this.logger = new this.$Amplify.Logger(this.$options.name);
    },
    methods: {
      async sendConfirmation() {
        this.sendingConfirmation = true;
        try {
          await this.$Amplify.Auth.resendSignUp(this.options.username);
          this.confirmationSent = true;
        } catch (e) {
          this.setError(e);
        }
        this.sendingConfirmation = false;
      },
      async signIn(/*event*/) {
        this.options.username = this.options.username.toLowerCase().trim();
        this.signingIn = true;
        try {
          const data = await this.$Amplify.Auth.signIn(this.options.username, this.password);

          this.logger.info('sign in success');
          if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'confirmSignIn')
          } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'requireNewPassword');
          } else if (data.challengeName === 'MFA_SETUP') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'setMfa');
          } else {
            return AmplifyEventBus.$emit('authState', 'signedIn')
          }
        } catch(e) {
          if (e.code && e.code === 'UserNotConfirmedException') {
            AmplifyEventBus.$emit('localUser', { username: this.options.username })
            AmplifyEventBus.$emit('authState', 'confirmSignUp')
          } else {
            this.setError(e);
          }
        }
        this.signingIn = false;
      },
      forgot: function () {
        AmplifyEventBus.$emit('authState', 'forgotPassword')
      },
      signUp: function () {
        AmplifyEventBus.$emit('authState', 'signUp')
      },
      setError: function (e) {
        this.error = this.$Amplify.I18n.get(e.message || e);
        this.logger.error(this.error)
      },
    },
  }
</script>
