<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '1000'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('contributor.manage_members') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-4">
        <template v-if="selectedContributorGroup && selectedContributorGroup.id !== null" >
          <v-btn
            @click="createNewContributor()"
            color="primary"
            :small="$vuetify.breakpoint.smAndUp"
            :disabled="!!newContributor"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">lnr-plus-circle</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('contributor.create_new_contributor') }}</span>
          </v-btn>
          <v-list class="contributors-list" dense color="transparent" >
            <v-subheader>{{ $t('contributor.contributors_in_group') }}</v-subheader>
            <template v-if="newContributor || contributorsInGroup.length > 0">
              <contributor v-if="newContributor"
                           :contributor="newContributor"
                           :inGroup="true"
                           @add-contributor-to-group="addContributorToGroup"
                           @create-contributor="newContributor = null"
                           @cancel-edit-contributor="newContributor = null"/>
              <contributor v-for="contributor in contributorsInGroup"
                           :key="`${contributor.id}`"
                           :contributor="contributor"
                           :inGroup="true"
                           @remove-contributor-from-group="removeContributorFromGroup"/>
            </template>
            <template v-else>
              <v-list-item class="px-2">
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contributor.no_contributors_in_group') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-if="contributorsNotInGroup && contributorsNotInGroup.length > 0">
              <v-subheader class="mt-5">{{ $t('contributor.contributors_not_in_group') }}</v-subheader>
              <contributor v-for="contributor in contributorsNotInGroup"
                           :key="`${contributor.id}`"
                           :contributor="contributor"
                           :inGroup="false"
                           @add-contributor-to-group="addContributorToGroup"/>
            </template>
          </v-list>
        </template>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :disabled="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('contributor.close_group_dialog') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "../../../api/tenant";
import Contributor from "./Contributor";

export default {
  name: "ContributorGroupMembersDialog",
  components: {Contributor},
  data() {
    return {
      loading: true,
      dialog: false,
      saving: false,
      allContributors: [],
      newContributor: null,
      selectedContributorGroup: null,
    }
  },
  computed: {
    contributorsInGroup() {
      return this.allContributors.filter(a => this.isContributorInGroup(a));
    },
    contributorsNotInGroup() {
      return this.allContributors.filter(a => !this.isContributorInGroup(a));
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.newContributor = null;
        this.selectedContributorGroup = null;
      }
    }
  },
  methods: {
    async open(contributorGroup) {
      this.loading = true;
      this.dialog = true;
      this.selectedContributorGroup = contributorGroup;
      await this.getAllContributors();
      this.loading = false;
    },
    async getAllContributors() {
      this.allContributors = await tenantApi.getContributors();
    },
    isContributorInGroup(contributor) {
      return this.selectedContributorGroup.contributors.some(c => c.id === contributor.id);
    },
    async addContributorToGroup(contributor) {
      try {
        await tenantApi.addContributorToGroup(contributor.id, this.selectedContributorGroup.id);
        this.selectedContributorGroup.contributors.push({ ...contributor });
        await this.getAllContributors();
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('contributor.add_to_group_fail'));
      }
    },
    async removeContributorFromGroup(contributor) {
      try {
        await tenantApi.removeContributorFromGroup(contributor.id, this.selectedContributorGroup.id);
        this.selectedContributorGroup.contributors = this.selectedContributorGroup.contributors.filter(c => c.id !== contributor.id);
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('contributor.remove_from_group_fail'));
      }
    },
    createNewContributor() {
      this.newContributor = {
        id: null,
        name: null,
        company: null,
      };
    }
  }
}
</script>

<style scoped>

</style>
