<template>
  <div class="dashboard">
    <v-container :fill-height="loading" :fluid="$vuetify.breakpoint.lgAndDown" class="impacts-dashboard">

      <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;"
                         :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2"
                         height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

      <hb-app-bar></hb-app-bar>

      <hb-loading-indicator v-if="loading" align-middle/>

      <v-fade-transition>
        <v-row v-if="!loading" style="position: relative;">
          <v-col cols="12">

            <v-row justify="space-between" class="ignore-export">
              <v-col class="pb-0 text-center text-sm-left">
                <v-btn text @click="backToDashboards" class="hidden-print-only"><v-icon left>lnr lnr-arrow-left</v-icon>{{ $t('dashboards.back_to_dashboards') }}</v-btn>
              </v-col>
              <v-col class="text-center text-sm-right pb-0">
                <DashboardSettings v-model="settings" v-if="settings != null" :plan-id="planId"></DashboardSettings>
              </v-col>
            </v-row>

            <ImpactDashboard :editable="true" :actions="actions" :plan="plan"
                             :settings="settings" v-if="plan.id != null"  @title-changed="titleChanged"></ImpactDashboard>
            <DashboardFooter />

          </v-col>
        </v-row>
      </v-fade-transition>

    </v-container>
  </div>
</template>

<script>
  import tenantApi from "../../api/tenant";
  import ImpactDashboard from "../../components/ImpactDashboard/ImpactDashboard";
  import DashboardSettings from "../../components/DashboardSettings";
  import DashboardFooter from "@/components/DashboardFooter";

  export default {
    name: 'ImpactsDashboardView',
    props: {
      planId: {
        type: [Number, String],
        default: 1,
      },
    },
    components: {
      DashboardFooter,
      DashboardSettings,
      ImpactDashboard,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        plan: {},
        actions: [],
        settings: null,
      }
    },
    methods: {
      async titleChanged(newTitle) {
        this.settings.title = newTitle;
        await tenantApi.updateDashboardSettings(`impact-${this.planId}`, {
          title: newTitle,
          shared: this.settings.shared,
          meta: this.settings.meta,
        });
      },
      async reloadActions() {
        this.loading = true;
        try {
          const plan = await tenantApi.getPlan(this.planId);
          this.plan = plan;
          this.actions = plan.actions;
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;
      },
      backToDashboards() {
        this.$router.push({ name: 'dashboards' });
      },
    },
    async mounted() {
      this.settings = await tenantApi.getDashboardSettings('impact', this.planId);
      await this.reloadActions();
    },
  }
</script>

<style scoped>

</style>
