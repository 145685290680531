<template>
  <div class="users-list">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <template v-for="user in users">
      <div :key="user.id">
        <v-list-item
          style="background: none;"
        >
          <v-list-item-avatar :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject, 75, 70) : 'grey lighten-2'" class="user-avatar">
            <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
            <v-icon v-if="type === 'user'" color="white" style="margin-top: 8px; margin-left: 2px;" size="36">lnr lnr-user</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3" :class="!user.enabled ? 'grey--text text--lighten-1' : ''">
                  {{ user.email ? user.email : user.subject }}
                  <template v-if="user.firstName && user.lastName">
                    <br/>
                    <span class="caption grey--text">{{ user.firstName }} {{ user.lastName }}</span>
                  </template>
                  <template v-if="user.tenants.length > 0">
                    <br/>
                    <span class="caption grey--text">{{ getUserTenantList(user) }}</span>
                  </template>
                  <template v-if="$vuetify.breakpoint.xsOnly">
                    <div>
                      <span v-for="role in user.roles" :key="role" class="caption" :style="{color: $randomizeColorFromString(role, 60, 45)}">{{ $t('user_management.role.' + role) }} </span>
                    </div>
                  </template>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6" lg="7" xl="9">
                  <template v-for="role in user.roles">
                    <v-chip :key="role" @click:close="removeRoleFromUser(user, role)" class="mr-1" small close outlined :color="$randomizeColorFromString(role, 60, 45)"><strong>{{ $t('user_management.role.' + role) }}</strong></v-chip>
                  </template>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon class="ma-0" color="grey lighten-2" v-on="on"><v-icon size="22">add_circle</v-icon></v-btn>
                    </template>
                    <v-list dense class="pt-0 pb-0">
                      <v-subheader>{{ $t('user_management.add_role_to_user') }}:</v-subheader>
                      <v-list-item v-for="role in availableRolesForUser(user)" @click="addRoleToUser(user, role)" :key="role">
                        <v-list-item-content :style="{color: $randomizeColorFromString(role, 75, 70)}">
                          <v-list-item-title class="font-weight-bold">{{ $t('user_management.role.' + role) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu bottom left :close-on-content-click="$vuetify.breakpoint.smAndUp">
              <template v-slot:activator="{ on }">
                <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">more_vert</v-icon></v-btn>
              </template>
              <v-list dense class="pt-0 pb-0">
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ user.agentUser ? $t('user_management.machine_id') : $t('user_management.email') }}:</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon v-if="!user.agentUser" size="18">lnr lnr-user</v-icon>
                      <v-icon v-else size="18">fas fa-users-cog</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ user.username }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-subheader>{{ $t('user_management.manage_user') }}:</v-subheader>
                <v-list-item @click="openUserDetails(user)">
                  <v-list-item-avatar><v-icon>lnr lnr-cog</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('user_management.user_settings') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="user.enabled" @click="toggleUserState(user)">
                  <v-list-item-avatar><v-icon>lnr lnr-circle-minus</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('user_management.disable') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!user.enabled" @click="toggleUserState(user)">
                  <v-list-item-avatar><v-icon size="18">lnr lnr-checkmark-circle</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('user_management.enable') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ $t('user_management.manage_user_roles') }}:</v-subheader>
                  <v-list-item v-for="role in allRoles" @click="toggleUserRole(user, role)" :key="role">
                    <v-list-item-avatar>
                      <v-icon v-if="user.roles.findIndex(r => r === role) > -1" :color="$randomizeColorFromString(role, 75, 70)">check_box</v-icon>
                      <v-icon v-else :color="$randomizeColorFromString(role, 75, 70)">check_box_outline_blank</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content :style="{color: $randomizeColorFromString(role, 75, 70)}">
                      <v-list-item-title class="font-weight-bold">{{ $t('user_management.role.' + role) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </template>

    <user-details-dialog ref="userDetailsDialog" @on-dismiss="reloadUsers"/>
  </div>
</template>

<script>
  import UserDetailsDialog from "./UserDetailsDialog.vue";
  import adminApi from '../../api/admin';

  export default {
    name: "UsersList",
    components: {
      UserDetailsDialog: UserDetailsDialog,
    },
    props: {
      users: {
        type: Array,
        default: () => [],
      },
      allRoles: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: 'user',
      },
    },
    data() {
      return {
        subtleLoading: false,
      }
    },
    methods: {
      getUserTenantList(user) {
        if (user.tenants.length > 3) {
          const shownTenants = [...user.tenants].slice(0, 3).map(t => t.name);
          shownTenants.push('...');
          return shownTenants.join(', ');
        } else {
          return user.tenants.map(t => t.name).join(', ');
        }
      },
      reloadUsers() {
        this.$emit('reload-users');
      },
      openUserDetails(user) {
        this.$refs.userDetailsDialog.openUser(user);
      },
      availableRolesForUser(user) {
        let availableRoles = [];
        this.allRoles.forEach(role => {
          if (user.roles.findIndex(r => r === role) < 0) availableRoles.push(role);
        });
        return availableRoles;
      },
      toggleUserRole(user, role) {
        if (user.roles.findIndex(r => r === role) > -1) {
          this.removeRoleFromUser(user, role);
        } else {
          this.addRoleToUser(user, role);
        }
      },
      addRoleToUser(user, role) {
        let roles = [...user.roles];
        roles.push(role);
        this.updateUserRoles(user, roles);
      },
      removeRoleFromUser(user, role){
        let roles = [...user.roles];
        roles.splice(user.roles.findIndex(r => r === role), 1);
        this.updateUserRoles(user, roles);
      },
      async updateUser(user, data) {
        return adminApi.updateUser(user.subject, data);
      },
      async updateUserRoles(user, roleSet) {
        try {
          await this.updateUser(user, { roleSet });
          user.roles = roleSet;
          this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
        } catch (err) {
          this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
        }
      },
      async toggleUserState(user) {
        if (user.username === this.$userInfo.username) {
          const message = user.enabled ? this.$t('user_management.you_cannot_disable_yourself') : this.$t('user_management.you_cannot_enable_yourself');
          this.$showWarningNotification(message);
          return;
        }

        let response = {};
        let error = false;

        this.subtleLoading = true;

        try {
          if (user.enabled) {
            response = await adminApi.disableUser(user.username);
          } else if (!user.enabled) {
            response = await adminApi.enableUser(user.username);
          }
        } catch (e) {
          error = e;
        }

        if (response && response.result) {
          user.enabled = !user.enabled;
          this.$showSuccessNotification(user.enabled ? this.$t('user_management.user_state_set_to_enabled') : this.$t('user_management.user_state_set_to_disabled'));
        } else if (error) {
          this.$handleApiError(error, this.$t('user_management.user_state_change_failed'));
        }

        this.subtleLoading = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .user-avatar {
    overflow: hidden;
  }
</style>
