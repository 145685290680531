<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        dark
        :loading="saving"
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
        :large="$vuetify.breakpoint.smAndUp"
        :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('tenants.add_new_tenant') }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('tenants.add_new_tenant') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">

          <v-text-field
            class="mt-5 organization_name"
            :label="$t('tenants.organization_name')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="organization"
            v-model="organization"
          ></v-text-field>
        </v-form>
        <v-checkbox :label="$t('tenants.copy_sample_plan')" v-model="copySamplePlan"></v-checkbox>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('tenants.create_tenant_fill_fields') }}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               :loading="saving"
               @click="saveOrganization"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "AddTenantDialog",
    data() {
      return {
        saving: false,
        dialog: false,
        organization: '',
        showAlert: false,
        copySamplePlan: true,
      }
    },
    methods: {
      async saveOrganization() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        this.saving = true;
        try {
          await this.$adminApi.addTenant(this.organization, this.copySamplePlan);
          this.dialog = false;
          this.$showSuccessNotification(this.$t('tenants.tenant_created_success'));
        } catch (err) {
          this.$handleApiError(err, this.$t('tenants.tenant_creation_failed'));
        }

        this.saving = false;
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.organization = '';
          this.copySamplePlan = true;
          this.showAlert = false;
          this.$refs.form.reset();
          this.$emit('on-dismiss');
        }
      }
    },
  }
</script>

<style scoped>

</style>
