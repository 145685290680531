<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ actionLog && actionLog.id ? $t('plan.action_log_dialog_title_edit') : $t('plan.action_log_dialog_title_add') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="actionLog" class="pt-5">
        <v-textarea v-model="notes"
                    hide-details
                    rows="6"
                    class="log-entry-textarea"
                    @focus="focused = true"
                    @blur="focused = false"
                    :placeholder="placeHolderText"
                    persistent-placeholder
                    :label="$t('plan.notes')"></v-textarea>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :disabled="saving"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :loading="saving"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import actionApi from "@/api/actionApi";

export default {
  name: 'ActionLogDialog',
  components: {  },
  props: ['action'],
  data() {
    return {
      focused: false,
      dialog: false,
      actionLog: null,
      saving: false,
      notes: '',
      type: null,
    }
  },
  watch: {
    dialog(to) {
      if (!to) {
        this.dataPoint = null;
      }
    },
  },
  computed: {
    placeHolderText() {
      return !this.focused ? this.$t('plan.action_log_placeholder_1') : this.$t('plan.action_log_placeholder_2');
    }
  },
  methods: {
    openAdd() {
      this.type = 'add';
      this.notes = '';
      this.actionLog = {
        id: null,
        note: null,
      };
      this.dialog = true;
    },
    openEdit(actionLog) {
      this.type = 'edit';
      this.notes = actionLog.note;
      this.actionLog = { ...actionLog };
      this.dialog = true;
    },
    async save() {
      this.saving = true;
      try {
        const data = {
          note: this.notes,
        }

        if (this.type === 'edit') {
          const dataPoint = await actionApi.updateActionLog(this.action.id, this.actionLog.id, data);
          if (this.actionLog.id) {
            this.$emit('action-log-updated', dataPoint);
          } else {
            this.$emit('action-log-updated', data);
          }
        } else {
          const dataPoint = await actionApi.createActionLog(this.action.id, data);
          this.$emit('action-log-added', dataPoint);
        }
      } catch (error) {
        this.$handleApiError(error);
      }
      this.saving = false;
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    }
  },
}
</script>

<style lang="scss">
.log-entry-textarea {
  textarea {
    line-height: 1.15rem !important;
  }
}
</style>
