import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"grey lighten-4"},[_c(VCardTitle,[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('custom_impacts.title')))])]),_c(VCardText,[_c(VAlert,{attrs:{"type":"info","value":true,"prominent":"","text":""}},[_c('div',[_vm._v("Custom impacts allows you to create your own impacts and detailed impacts.")]),_c('div',[_vm._v("Detailed impacts are subcategories of your impacts. Your custom impacts are visible in Plans when creating Actions.")])])],1),(!_vm.loading)?_c(VTreeview,{attrs:{"items":_vm.customImpactsStructure,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': item.level === 0 }},[_vm._v(" "+_vm._s(item.text)+" "),(item.level === 1)?_c('span',{staticClass:"px-5 ml-1",staticStyle:{"border-radius":"20px"},style:({'background-color': item.color})}):_vm._e()]),(item.level < 2)?_c(VBtn,{staticClass:"ml-1",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.addChildClicked(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("lnr lnr-plus-circle")]),_vm._v(" "+_vm._s(item.level === 0 ? _vm.$t('custom_impacts.add_impact') : _vm.$t('custom_impacts.add_detailed_impact'))+" ")],1):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.editCustomImpactClicked(item)}}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v("lnr lnr-pencil")])],1),_c(VBtn,{attrs:{"small":"","icon":"","color":"error","loading":_vm.deleting[item.id]},on:{"click":function($event){return _vm.deleteCustomImpactClicked(item)}}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v("lnr lnr-trash")])],1)]}}],null,false,4236361549)}):_vm._e(),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.newCustomImpactClicked}},[_vm._v(_vm._s(_vm.$t('custom_impacts.add')))])],1),_c('custom-impact-dialog',{ref:"customImpactDialog",on:{"custom-impact-saved":_vm.reloadImpacts,"custom-impact-created":_vm.reloadImpacts}}),_c('confirm-dialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }