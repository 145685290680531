<template>
  <v-card :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : ''">
    <v-toolbar light color="white" height="75" elevation="0">
      <v-toolbar-title style="width: 100%;">
        <v-text-field ref="filterField"
                      v-model="filterPhrase"
                      autocomplete="off"
                      autofocus
                      hide-details
                      solo-inverted
                      prepend-inner-icon="search"
                      append-outer-icon="close"
                      @click:append-outer="closeSelector"
                      flat />
      </v-toolbar-title>
    </v-toolbar>
    <v-divider class="grey lighten-3"></v-divider>
    <v-card-text class="pa-0 card-content">
      <v-list>
        <template v-if="filteredBestMatches.length > 0">
          <v-subheader>{{ $t('plan.best_matches') }}</v-subheader>
          <v-list-item v-for="indicator in filteredBestMatches"
                       :key="indicator.id"
                       @click="selectIndicator(indicator)"
                       :class="selectedIndicator && indicator.code === selectedIndicator.code ? 'v-list-item--active' : ''"
                       :color="$sdg(indicator.sdgIndex).color">
            <v-list-item-content style="padding-left: 3px;">
              <v-list-item-title>{{ indicator.shortDescription }}</v-list-item-title>
              <v-list-item-subtitle>{{ indicator.code }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click.stop.prevent="() => { showLongDescription(indicator) }"><v-icon>lnr lnr-question-circle</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <template v-if="filteredIndicators.length > 0">
          <v-subheader>{{ $t('plan.all_indicators') }}</v-subheader>
          <template v-for="group in indicatorsGroupedBySDG">
            <v-list-group :key="group[0].sdgIndex" :color="$sdg(group[0].sdgIndex).color" :style="{borderColor: $sdg(group[0].sdgIndex).color}" class="item-group">
              <template v-slot:activator>
                <v-list-item-avatar tile>
                  <v-img :src="$getSDGImage(group[0].sdgIndex)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>SDG{{ group[0].sdgIndex }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $sdg(group[0].sdgIndex).goalText }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list-item v-for="indicator in group"
                           :key="indicator.id"
                           @click="selectIndicator(indicator)"
                           :class="selectedIndicator && indicator.code === selectedIndicator.code ? 'v-list-item--active' : ''">
                <v-list-item-content>
                  <v-list-item-title>{{ indicator.shortDescription }}</v-list-item-title>
                  <v-list-item-subtitle>{{ indicator.code }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click.stop.prevent="() => { showLongDescription(indicator) }"><v-icon>lnr lnr-question-circle</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <template v-if="noMatches">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('plan.no_results_matching') }} "<strong>{{ filterPhrase }}</strong>".
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import _ from "lodash";
  import IndicatorSelector from '@/service/indicatorselector.service';

  export default {
    name: "SDGBusinessIndicatorList",
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      bestMatches: {
        type: Array,
        default: () => [],
      },
      allIndicators: {
        type: Array,
        default: () => [],
      },
      selectedIndicator: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        filterPhrase: '',
        maxWidth: 'auto',
      }
    },
    methods: {
      closeSelector() {
        this.$emit('close-without-changes');
      },
      selectIndicator(indicator) {
        this.$emit('select-indicator', indicator.code);
      },
      showLongDescription(indicator) {
        this.$emit('show-long-description', indicator);
      },
    },
    computed: {
      filteredBestMatches() {
        return IndicatorSelector.filterByTextAndKeywords(this.bestMatches, this.filterPhrase);
      },
      filteredIndicators() {
        return IndicatorSelector.filterByTextAndKeywords(this.allIndicators, this.filterPhrase);
      },
      indicatorsGroupedBySDG() {
        return _.groupBy(this.filteredIndicators, 'sdgIndex');
      },
      noMatches() {
        return this.filteredBestMatches.length === 0 && this.filteredIndicators.length === 0;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card-content {
    max-height: calc(100vh - 76px);
    overflow: auto;
  }

  .item-group {
    padding-left: 3px;

    &.v-list-group--active {
      padding-left: 0;
      border-left: 3px solid;
    }
  }

  @media only screen and (min-width: 961px) {
    .card-content {
      max-height: 324px;
    }
  }
</style>
