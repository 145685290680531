<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('plan.copy_action') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-alert class="mt-10" type="info" :value="true" outlined>{{ $t('plan.copy_action_info' )}}</v-alert>
        <v-select :items="plans" item-text="name" :label="$tc('plan.title', 1)" item-value="id" v-model="selectedPlanId"></v-select>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dismissDialog"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :disabled="saving"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="copyToPlan"
               :loading="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('copy') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "@/api/tenant";

export default {
  name: 'CopyActionToPlanDialog',
  data() {
    return {
      dialog: false,
      action: null,
      selectedPlanId: null,
      saving: false,
      plans: [],
    }
  },
  methods: {
    open(action) {
      this.reloadPlans();
      this.action = action;
      this.dialog = true;
    },
    dismissDialog() {
      this.dialog = false;
      this.selectedPlanId = null;
      this.action = null;
      this.plans = [];
    },
    async copyToPlan() {
      if (this.selectedPlanId != null) {
        this.saving = true;
        try {
          const action = await tenantApi.copyActionToPlan(this.action.id, this.selectedPlanId)
          this.$showSuccessNotification(this.$t('plan.action_copy_success'));
          this.$emit('action-copied', action);
          this.dismissDialog();
        } catch (error) {
          this.$handleApiError(error);
        }
        this.saving = false;
      }
    },
    async reloadPlans() {
      try {
        const plans = await tenantApi.getPlans();
        this.plans = plans.filter(p => p.id !== this.action.planId);
      } catch (error) {
        this.$handleApiError(error);
      }
    }
  },
}
</script>

<style scoped>

</style>
