import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"name-your-plan",attrs:{"fill-height":""}},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.subtleLoading),expression:"subtleLoading"}],staticStyle:{"position":"fixed","left":"0","right":"0","margin":"0","z-index":"99"},style:({top: _vm.$vuetify.breakpoint.smAndDown ? '54px' : '62px'}),attrs:{"color":"primary lighten-2","height":"2","indeterminate":true}}),_c('hb-app-bar'),(_vm.loading)?_c('hb-loading-indicator',{attrs:{"align-middle":""}}):_vm._e(),_c(VFadeTransition,[(!_vm.loading)?_c(VRow,{staticStyle:{"position":"relative"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","lg":"10","offset-lg":"1","xl":"8","offset-xl":"2"}},[_c('hb-heading',{attrs:{"value":_vm.$t('plan.name_your_plan')}}),_c(VTextField,{style:({fontSize: _vm.$vuetify.breakpoint.xsOnly ? '22px' : '33px'}),attrs:{"height":_vm.$vuetify.breakpoint.xsOnly ? 60 : 94,"full-width":"","placeholder":_vm.$t('plan.plan_name_placeholder'),"solo":"","background-color":"#f4f4f4","flat":""},model:{value:(_vm.planName),callback:function ($$v) {_vm.planName=$$v},expression:"planName"}}),_c(VBtn,{attrs:{"x-large":"","disabled":_vm.planName.length === 0,"color":"primary"},on:{"click":_vm.savePlanName}},[_vm._v(_vm._s(_vm.$t('continue')))])],1),_c(VCol,{attrs:{"cols":"12"}}),_c(VCol,{attrs:{"cols":"12"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }