import Vue from 'vue'
import UserService from '../service/user.service';
import UiEventBus from '../UiEventBus';

const RolesPlugin = {
  install(/* vue */) {
    Vue.mixin({
      watch: {
        $isLoggedIn(newValue) {
          console.log('isLoggedInChange', newValue);
          if (newValue === true) {
            UiEventBus.$emit('loginSuccess');
          }
        },
      },
      computed: {
        $isLoggedIn() {
          return UserService.getUserInfo() != null && UserService.getSession() != null;
        },
        $userInfo() {
          return UserService.getUserInfo();
        },
        $isAdmin() {
          return this.$hasMatchingRoles(['admin']);
        },
        $isBasicUser() {
          return this.$hasMatchingRoles(['basic']);
        },
        $isAgentUser() {
          return this.$hasMatchingRoles(['agent']);
        }
      },
      methods: {
        $clearUserInfo() {
          UserService.clearUserInfo();
        },
        $hasMatchingRoles(roles) {
          const userInfo = UserService.getUserInfo();
          if (userInfo == null) {
            return false;
          }
          const userRoles = userInfo.roles;
          const matchingRoles = roles.findIndex(role => {
            if (userRoles.length > 0) {
              return userRoles.findIndex(r => r.toLowerCase() === role.toLowerCase()) > -1;
            }
          });
          return matchingRoles > -1;
        }
      }
    });
  },
};

export default RolesPlugin;
