<template>
  <div class="dashboard">
    <v-container :fill-height="loading" :fluid="$vuetify.breakpoint.lgAndDown" class="actions-dashboard">

      <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

      <hb-app-bar></hb-app-bar>

      <hb-loading-indicator v-if="loading" align-middle />

      <v-fade-transition>
        <v-row v-if="!loading" style="position: relative;">
          <v-col cols="12">

            <v-row justify="space-between" class="ignore-export">
              <v-col class="pb-0 text-center text-sm-left">
                <v-btn text @click="backToDashboards" class="hidden-print-only"><v-icon left>lnr lnr-arrow-left</v-icon>{{ $t('dashboards.back_to_dashboards') }}</v-btn>
              </v-col>
              <v-col class="text-center text-sm-right pb-0">
                <DashboardSettings v-model="settings" v-if="settings != null" :plan-id="planId"></DashboardSettings>
              </v-col>
            </v-row>

            <CustomImpactDashboard :editable="true" :settings="settings" :dashboardData="dashboardData" :custom-impacts="customImpacts"
                                   :root-custom-impact-id="rootCustomImpactId" @title-changed="titleChanged"></CustomImpactDashboard>
            <DashboardFooter />

          </v-col>
        </v-row>
      </v-fade-transition>

    </v-container>
  </div>
</template>

<script>
import tenantApi from "../../api/tenant";
import DashboardSettings from "../../components/DashboardSettings";
import DashboardFooter from "@/components/DashboardFooter";
import CustomImpactDashboard from "@/components/CustomImpactDashboard/CustomImpactDashboard";

export default {
  name: 'CustomImpactDashboardView',
  components: { CustomImpactDashboard, DashboardFooter, DashboardSettings },
  props: {
    planId: {
      type: [Number, String],
      default: 1,
    },
    rootCustomImpactId: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      settings: null,
      customImpacts: [],
      dashboardData: null,
    }
  },
  methods: {
    titleChanged(newTitle) {
      this.settings.title = newTitle;
      tenantApi.updateDashboardSettings(`custom_impacts-${this.planId}-${this.rootCustomImpactId}`, {
        title: newTitle,
        shared: this.settings.shared,
        meta: this.settings.meta,
      });
    },
    backToDashboards() {
      this.$router.push({name: 'dashboards'});
    },
    async reloadPlan() {
      this.loading = true;
      try {
        const dashboardData = await tenantApi.getDashboardData('custom_impacts', this.planId, this.rootCustomImpactId);
        this.dashboardData = dashboardData;
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
    },
    async reloadSettings() {
      this.customImpacts = await tenantApi.getCustomImpacts();
      this.settings = await tenantApi.getDashboardSettings('custom_impacts', this.planId, this.rootCustomImpactId);
    },
  },
  computed: {
  },
  async mounted() {
    await this.reloadSettings();
    await this.reloadPlan();
  }
}
</script>
