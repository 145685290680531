<template>
  <v-container :fill-height="loading" class="user-profile">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12" md="6">
          <v-card color="grey lighten-4">
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div>
                <v-avatar size="68" :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject, 75, 70) : 'grey lighten-2'" class="user-avatar" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'">
                  <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
                  <v-icon color="white" style="margin-top: 15px; margin-left: 4px;" size="60">lnr lnr-user</v-icon>
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">{{ user.email ? user.email : user.sub }}</h3>
                <div class="body-1 text-center text-sm-left"><span v-for="role in user.roles" :key="role" :style="{color: $randomizeColorFromString(role, 75, 70)}">{{ role }} </span></div>
              </div>
            </v-card-title>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
              <v-btn class="ma-1" text color="primary" @click="openChangePasswordDialog">{{ $t('user_profile.change_password') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>
    <change-password-dialog :user="user" ref="changePasswordDialog" />
  </v-container>
</template>

<script>
  import ChangePasswordDialog from '../components/UserProfile/ChangePasswordDialog.vue';

  export default {
    name: "UserProfile",
    components: {
      ChangePasswordDialog: ChangePasswordDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        user: {},
      }
    },
    methods: {
      openChangePasswordDialog() {
        this.$refs.changePasswordDialog.open();
      },
    },
    computed: {

    },
    mounted() {
      if (this.$isLoggedIn) {
        this.user = this.$userInfo;
      }
      this.loading = false;
    },
  }
</script>

<style lang="scss">
  .user-avatar {
    overflow: hidden;
  }

  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
