<template>
  <div class="dashboard">
    <v-container :fill-height="loading" :fluid="$vuetify.breakpoint.lgAndDown" class="actions-dashboard">

      <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

      <hb-app-bar></hb-app-bar>

      <hb-loading-indicator v-if="loading" align-middle />

      <v-fade-transition>
        <v-row v-if="!loading" style="position: relative;">
          <v-col cols="12">

            <v-row justify="space-between" class="ignore-export">
              <v-col class="pb-0 text-center text-sm-left">
                <v-btn text @click="backToDashboards" class="hidden-print-only"><v-icon left>lnr lnr-arrow-left</v-icon>{{ $t('dashboards.back_to_dashboards') }}</v-btn>
              </v-col>
              <v-col class="text-center text-sm-right pb-0">
                <DashboardSettings v-model="settings" v-if="settings != null" :plan-id="planId" :action-id="actionId"></DashboardSettings>
              </v-col>
            </v-row>

            <ActionDashboard :editable="true" :settings="settings" :action="action" v-if="action != null" @title-changed="titleChanged"></ActionDashboard>

            <DashboardFooter />

          </v-col>
        </v-row>
      </v-fade-transition>

    </v-container>
  </div>
</template>

<script>
  import tenantApi from "../../api/tenant";
  import ActionDashboard from "../../components/ActionDashboard/ActionDashboard";
  import DashboardSettings from "../../components/DashboardSettings";
  import DashboardFooter from "@/components/DashboardFooter";

  export default {
    name: 'ActionDashboardView',
    components: {DashboardFooter, DashboardSettings, ActionDashboard },
    props: {
      actionId: {
        type: [Number, String],
        default: 0,
      },
      planId: {
        type: [Number, String],
        default: 1,
      },
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        action: {},
        settings: null,
      }
    },
    methods: {
      titleChanged(newTitle) {
        this.settings.title = newTitle;
        tenantApi.updateDashboardSettings(`action-${this.planId}-${this.actionId}`, {
          title: newTitle,
          shared: this.settings.shared,
          meta: this.settings.meta,
        });
      },
      backToDashboards() {
        this.$router.push({name: 'dashboards'});
      },
      async loadAction() {
        this.loading = true;
        try {
          const action = await tenantApi.getAction(this.actionId);
          this.action = action;
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;
      },
      async reloadSettings() {
        this.settings = await tenantApi.getDashboardSettings('action', this.planId, this.actionId);
      },
    },
    computed: {
    },
    async mounted() {
      await this.reloadSettings();
      await this.loadAction();
    }
  }
</script>
