import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settings)?_c('div',{staticClass:"ignore-export",staticStyle:{"display":"inline-block"}},[_c(VSwitch,{staticClass:"mt-0",on:{"change":_vm.sharedChanged},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"switch-label"},[_vm._v(_vm._s(_vm.$t('dashboards.share_dashboard')))])]},proxy:true},{key:"prepend",fn:function(){return [_c(VTooltip,{staticClass:"ignore-export",attrs:{"bottom":"","content-class":"ignore-export"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","loading":_vm.exporting},on:{"click":_vm.exportPng}},on),[_c(VIcon,[_vm._v("mdi mdi-download")])],1)]}}],null,false,2587087231)},[_c('span',{staticClass:"ignore-export"},[_vm._v("Export as PNG")])]),(_vm.settings.shared)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.copyLink}},on),[_c(VIcon,[_vm._v("mdi mdi-content-copy")])],1)]}}],null,false,3996184006)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboards.copy_link')))])]):_vm._e(),(_vm.settings.shared)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","href":_vm.$getSharedUrl(_vm.$tenant, _vm.settings.uuid),"target":"_blank"}},on),[_c(VIcon,[_vm._v("mdi mdi-open-in-new")])],1)]}}],null,false,2600327310)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboards.open_in_new_tab')))])]):_vm._e()]},proxy:true}],null,false,681580075),model:{value:(_vm.settings.shared),callback:function ($$v) {_vm.$set(_vm.settings, "shared", $$v)},expression:"settings.shared"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }