<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '1000'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ selectedContributorGroup.id ? $t('contributor.rename_group') : $t('contributor.create_new_group') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          class="mt-5"
          :label="$t('contributor.group_name')"
          :rules="[$rules.required]"
          validate-on-blur
          type="text"
          v-model="selectedContributorGroup.name"
        ></v-text-field>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :disabled="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('contributor.close_group_dialog') }}</span>
        </v-btn>
        <v-btn @click="saveContributorGroup"
               :loading="saving"
               color="primary"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "../../../api/tenant";

export default {
  name: "ContributorGroupDialog",
  props: ['contributorGroups'],
  data() {
    return {
      saving: false,
      dialog: false,
      selectedContributorGroup: {
        id: null,
        name: null,
        contributors: []
      },
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selectedContributorGroup = {
          id: null,
          name: null,
          contributors: []
        };
      }
    }
  },
  methods: {
    open(contributorGroup) {
      this.dialog = true;
      if (contributorGroup && contributorGroup.id) {
        this.selectedContributorGroup = { ...contributorGroup };
      } else {
        // new contributor group
        this.selectedContributorGroup = {
          id: null,
          name: null,
          contributors: [],
        };
      }
    },
    async saveContributorGroup() {
      this.saving = true;
      if (this.selectedContributorGroup.id) {
        try {
          const updatedContributorGroup = await tenantApi.updateContributorGroup(this.selectedContributorGroup.id, this.selectedContributorGroup);
          this.selectedContributorGroup = updatedContributorGroup;
          this.$showSuccessNotification(this.$t('contributor.group_updated'));
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('contributor.group_update_fail'));
        }
      } else {
        try {
          const newContributorGroup = await tenantApi.createContributorGroup(this.selectedContributorGroup);
          this.selectedContributorGroup = newContributorGroup;
          this.$showSuccessNotification(this.$t('contributor.group_created'));
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('contributor.group_create_fail'));
        }
      }
      this.saving = false;
      this.$emit('refresh');
    },
  }
}
</script>

<style scoped>

</style>
