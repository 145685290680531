<template>
  <v-row no-gutters>
    <v-col cols="12">
      <div class="text-center mb-3">{{ root.text }}</div>
      <v-select :items="nonRootImpacts" v-model="selectedImpactId" :no-data-text="$t('plan.no_impacts_in_custom_impact')" item-value="id" clearable @change="selectedImpactIdChanged" solo-inverted flat>
        <template v-slot:item="{item, attrs}">
          <template v-if="maxLevel === 2 && item.level === 1">
            <v-list-item disabled class="py-0 mt-2" style="min-height: 20px !important; height: 20px !important;">
              <v-list-item-title class="caption" :style="{color: item.color ? item.color : ''}">
                {{ item.text }}<!-- 2tasosen välitasoo ei saa valita -->
              </v-list-item-title>
            </v-list-item>
          </template>
          <div v-else>
            {{ item.text }}
          </div>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import {max} from 'mathjs';

export default {
  props: ['root', 'value', 'customImpacts'],
  name: 'ActionCustomImpactSelector',
  data() {
    return {
      selectedImpactId: null,
    }
  },
  watch: {
    value(to) {
      this.selectedImpactId = to;
    },
    selectedImpactId() {
      // this.$emit('input', this.root.id, this.selectedImpactId);
    }
  },
  methods: {
    selectedImpactIdChanged() {
      this.$emit('input', this.root.id, this.selectedImpactId);
    }
  },
  computed: {
    allImpacts() {
      const filtered = this.customImpacts.filter(ci => ci.root === this.root.id);
      return filtered;
    },
    nonRootImpacts() {
      const sorted = [];
      this.level1Impacts.forEach(l1i => {
        sorted.push(l1i);
        const children = _.orderBy(this.customImpacts.filter(i => i.parentId === l1i.id), ['text']);
        children.forEach(child => sorted.push(child));
      })

      return sorted;
    },
    level1Impacts() {
      return this.allImpacts.filter(i => i.level === 1);
    },
    maxLevel() {
      return max(this.allImpacts.map(i => i.level));
    }
  },
  mounted() {
    this.selectedImpactId = this.value;
  }
}
</script>

<style scoped>

</style>
