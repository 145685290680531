<template>
  <v-card class="grey lighten-2">
    <v-card-title>
      <div>
        <div class="subtitle-1" v-html="$t('plan.action_logs_help')"></div>
      </div>
    </v-card-title>
    <v-list class="transparent" dense max-height="300" style="overflow:auto;">
      <v-list-item v-for="actionLog in allActionLogs" :key="actionLog.id">
        <v-list-item-content>
          <v-list-item-subtitle class="caption font-weight-regular">{{ $formatDateTime(actionLog.created) }}</v-list-item-subtitle>
          <v-list-item-subtitle class="caption font-weight-regular">
            <template v-if="actionLogs.userFirstName && actionLogs.userLastName">
              {{ actionLog.userFirstName }} {{ actionLog.userLastName }} -
            </template>
            {{ actionLog.userEmail }}
          </v-list-item-subtitle>
          <v-list-item-title class="text-wrap font-weight-regular">{{ actionLog.note }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div>
          <v-btn icon @click="openEdit(actionLog)">
            <v-icon>lnr lnr-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteLog(actionLog)">
            <v-icon>lnr lnr-trash </v-icon>
          </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn v-if="allActionLogs.length > 0" text color="primary" @click="exportExcel" :loading="exporting">
        <v-icon left>lnr lnr-download</v-icon>
        {{ $t('plan.action_logs_export') }}
      </v-btn>
      <v-btn text color="primary" @click="openAdd">
        <v-icon left>lnr lnr-plus-circle</v-icon>
        {{ $t('plan.action_logs_add') }}
      </v-btn>
    </v-card-actions>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <action-log-dialog ref="actionLogDialog" :action="action"
                       @action-log-updated="actionLogUpdated"
                       @action-log-added="actionLogAdded"></action-log-dialog>
  </v-card>
</template>

<script>
import ActionLogDialog from "@/components/Plan/ActionLogDialog.vue";
import actionApi from "@/api/actionApi";
import downloadjs from "downloadjs";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog, ActionLogDialog },
  props: ['actionLogs', 'action'],
  name: 'ActionLogs',
  data() {
    return {
      exporting: false,
      actionLogDeleted: false,
    }
  },
  computed: {
    allActionLogs() {
      const actionLogs = [...this.actionLogs].sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });

      if (this.action.notes && !this.actionLogDeleted) {
        actionLogs.push({
          created: null,
          userFirstName: '',
          userLastName: '',
          userEmail: '',
          note: this.action.notes,
        });
      }

      return actionLogs;
    }
  },
  methods: {
    async deleteLog(actionLog) {
      const confirm = await this.$refs.confirm.open(this.$t('plan.delete_action_log'), this.$t('plan.delete_action_log_confirm'), { color: 'error', agree: this.$t('delete'), cancel: this.$t('cancel')});
      if (!confirm)
        return;

      this.$set(actionLog, '_deleting', true);
      try {
        await actionApi.deleteActionLog(this.action.id, actionLog.id);
        if (actionLog.id) {
          this.$emit('action-log-deleted', actionLog);
        } else {
          this.$emit('action-log-notes-deleted', actionLog);
          this.actionLogDeleted = true;
        }
      } catch (error) {
        this.$handleApiError(error);
      }
      this.$set(actionLog, '_deleting', false);
    },
    async exportExcel() {
      this.exporting = true;
      try {
        const blob = await actionApi.exportActionLogsExcel(this.action.id);
        downloadjs(blob, 'export.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      } catch (error) {
        this.$handleApiError(error);
      }
      this.exporting = false;
    },
    openAdd() {
      this.$refs.actionLogDialog.openAdd();
    },
    openEdit(actionLog) {
      this.$refs.actionLogDialog.openEdit(actionLog);
    },
    actionLogAdded(actionLog) {
      this.$emit('action-log-added', actionLog);
    },
    actionLogUpdated(actionLog) {
      this.$emit('action-log-updated', actionLog);
    },
  },
}
</script>

<style scoped>

</style>
