import { render, staticRenderFns } from "./NameYourPlanView.vue?vue&type=template&id=18896bc5&scoped=true&"
import script from "./NameYourPlanView.vue?vue&type=script&lang=js&"
export * from "./NameYourPlanView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18896bc5",
  null
  
)

export default component.exports