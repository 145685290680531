<template>
  <v-container :fill-height="loading" class="tenant-management">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12" md="6" order="1">
          <v-card color="grey lighten-4">
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div>
                <v-avatar size="102" :tile="logo != null" :color="tenant.status === 'active' ? logo != null ? 'white' : $randomizeColorFromString(tenant.id, 75, 70) : 'grey lighten-2'" class="white--text tenant-avatar elevation-10 display-1" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'">
                  <template v-if="logo != null">
                    <v-img :src="getImageUrl()" contain></v-img>
                  </template>
                  <template v-else>
                    {{ tenant.name ? tenant.name.substring(0, 2) : '' }}
                  </template>
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">{{ tenant.name }}</h3>
                <div class="body-1 text-center text-sm-left"><span>{{ $t('tenants.statuses.' + tenant.status) }}</span></div>
              </div>
            </v-card-title>
            <v-card-text>
              <div class="body-1 text-center text-sm-left"><span>{{ fullAddress }}</span></div>
              <div class="body-1 text-center text-sm-left pb-5"><span>{{ $getCountryNameFromCode(tenant.country) }}</span></div>
              <div class="body-1 text-center text-sm-left" v-if="business"><span>{{ $t('tenants.details.business') }}: {{ business }}</span></div>
              <div class="body-1 text-center text-sm-left" v-if="headcount"><span>{{ $t('tenants.details.headcount') }}: {{ headcount }}</span></div>
              <div class="body-1 text-center text-sm-left" v-if="turnover"><span>{{ $t('tenants.details.turnover') }}: {{ turnover }}</span></div>
            </v-card-text>
            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
              <v-btn class="ma-1" text color="primary" @click="openEditTenantDialog">{{ $t('tenants.edit_organization') }}</v-btn>
              <v-btn class="ma-1" text color="primary" @click="uploadImage">{{ $t('tenants.upload_logo') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" order-md="1">
          <v-row>
            <v-col cols="12" order="1">
              <v-card class="grey lighten-4">
                <v-card-title class="pb-0">
                  <div class="headline">{{ $t('contributor.contributor_groups') }}</div>
                </v-card-title>
                <contributor-group-list />
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="12" order="1">
          <ApiKeys />
        </v-col>
        <v-col cols="12" md="12" order="2">
          <custom-impact-management></custom-impact-management>
        </v-col>
      </v-row>
    </v-fade-transition>

    <tenant-details-dialog ref="tenantDetailsDialog" @on-dismiss="onTenantDetailsDialogDismiss" />
    <input type="file" style="display:none" accept="image/*" :multiple="false" ref="imageFileInput" @change="onImageFileChanged">
  </v-container>
</template>

<script>
  import TenantDetailsDialog from "../../components/Tenant/TenantManagement/TenantDetailsDialog";
  import tenantApi from "../../api/tenant";
  import params from '../../config/params';
  import ContributorGroupList from "@/components/Tenant/ContributorGroupManagement/ContributorGroupList";
  import ApiKeys from "@/components/Tenant/TenantManagement/ApiKeys";
  import CustomImpactManagement from "@/components/Tenant/TenantManagement/CustomImpactManagement";

  export default {
    name: "TenantManagementView",
    components: {
      CustomImpactManagement,
      ApiKeys,
      TenantDetailsDialog: TenantDetailsDialog,
      ContributorGroupList: ContributorGroupList,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        tenant: {},
        logo: null,
        savingImage: false,
      }
    },
    computed: {
      fullAddress() {
        const tenant = this.tenant;
        const parts = [tenant.streetAddress, tenant.zipCode, tenant.city].filter(t => t != null && t.length > 0);
        return parts.join(', ');
      },
      headcount() {
        const headcount = params.headcount.find(h => h.id === this.tenant.headcount);
        if (headcount) {
          return headcount.text;
        }

        return '';
      },
      turnover() {
        const turnover = params.turnover.find(h => h.id === this.tenant.turnover);
        if (turnover) {
          return turnover.text;
        }

        return '';
      },
      business() {
        const business = params.businesses.find(h => h.id === this.tenant.business);
        if (business) {
          return business.text;
        }

        return '';
      },
    },
    methods: {
      async getLogo() {
        try {
          const logo = await tenantApi.getLogo()
          this.logo = logo;
        } catch (error) {
          console.log('error loading logo', error);
        }
      },
      getImageUrl() {
        return `${this.$baseUrl}/api/shared/image/${this.$tenant}/logo?` + new Date().getTime()
      },
      async onImageFileChanged(fileAddedEvent) {
        const image = {
          file: fileAddedEvent.target.files[0],
          filename: fileAddedEvent.target.value.split('\\').pop()
        };

        this.savingImage = true;
        try {
          if (this.logo != null) {
            await tenantApi.deleteLogo(this.logo.key);
            this.logo = null;
          }

          this.logo = await tenantApi.uploadLogo(image.file, image.filename);
        } catch (error) {
          this.$handleApiError(error);
          console.log(error);
        }
        this.savingImage = false;
      },
      uploadImage() {
        this.$refs.imageFileInput.click();
      },
      openEditTenantDialog() {
        this.$refs.tenantDetailsDialog.open(this.tenant);
      },
      onTenantDetailsDialogDismiss() {
        this.getTenant();
      },
      async subscriptionChanged() {
        await this.getTenant();
        this.$store.commit('hideTenantPaymentWarning');
      },
      async getTenant() {
        if (this.tenant.id) {
          this.subtleLoading = true;
        } else {
          this.loading = true;
        }

        try {
          this.tenant = await tenantApi.getCurrentTenant();
          this.$store.commit('setTenant', this.tenant);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.loading = false;
        this.subtleLoading = false;
      },
    },
    async mounted() {
      await this.getTenant();
      await this.getLogo();
    },
  }
</script>

<style lang="scss">
  .tenant-avatar {
    overflow: hidden;
  }

  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
