<template>
  <div>
    <v-alert :value="isNoGroupSelected" text type="info">Select contributor groups for action</v-alert>

    <v-expansion-panels v-if="groups.length > 0" v-model="panels" >
      <v-expansion-panel
        v-for="group in groups"
        class="grey lighten-2"
        :key="`${group.id}_group`">
        <v-expansion-panel-header class="pt-2 pr-5 pb-2 pl-2">
          <v-row style="max-width: 100%;">
            <v-col cols="11" class="text-truncate">
              <v-simple-checkbox class="float-left" :ripple="false" v-model="group.selected" @input="selectContributorGroup(group)"></v-simple-checkbox>
              <span style="line-height: 26px; margin-left: 10px;">{{ group.name }}</span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn color="primary"
                 class="mb-2 mt-2"
                 small
                 :block="$vuetify.breakpoint.xsOnly"
                 @click.stop="openContributorDialog(group)">
            <v-icon>mdi-plus</v-icon> {{ $t('contributor.create_new_contributor') }}
          </v-btn>
          <v-data-table style="border-radius: 0"
                        class="transparent contributor-table"
                        :headers="contributorHeaders"
                        :items="group.contributors"
                        :hide-default-footer="true"
                        item-key="id">
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="group.selected">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" small @click="copyLink(item)"><v-icon small>mdi mdi-content-copy</v-icon></v-btn>
                </template>
                <span>{{ $t('plan.copy_contributor_link') }}</span>
              </v-tooltip>
              <span v-else></span>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="groupDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
      :hide-overlay="$vuetify.breakpoint.xsOnly"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('contributor.create_new_group') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="groupDialog = false">
            <v-icon>lnr lnr-cross</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="groupForm">
            <v-text-field
              class="mt-5"
              :label="$t('contributor.group_name')"
              :rules="[$rules.required]"
              validate-on-blur
              type="text"
              v-model="contributorGroupName"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default"
                 @click="groupDialog = false"
                 :fab="$vuetify.breakpoint.xsOnly"
                 :fixed="$vuetify.breakpoint.xsOnly"
                 :bottom="$vuetify.breakpoint.xsOnly"
                 :left="$vuetify.breakpoint.xsOnly">
            <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary"
                 @click="saveGroup"
                 :fab="$vuetify.breakpoint.xsOnly"
                 :fixed="$vuetify.breakpoint.xsOnly"
                 :bottom="$vuetify.breakpoint.xsOnly"
                 :right="$vuetify.breakpoint.xsOnly">
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="contributorDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
      :hide-overlay="$vuetify.breakpoint.xsOnly"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('contributor.create_new_contributor') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="contributorDialog = false">
            <v-icon>lnr lnr-cross</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="contributorForm">
            <v-text-field
              class="mt-5"
              :label="$t('contributor.email')"
              :rules="[$rules.required, $rules.email]"
              validate-on-blur
              type="text"
              v-model="contributor.email"
            ></v-text-field>
            <v-text-field
              class="mt-5"
              :label="$t('contributor.name')"
              validate-on-blur
              type="text"
              v-model="contributor.name"
            ></v-text-field>
            <v-text-field
              class="mt-5"
              :label="$t('contributor.company')"
              validate-on-blur
              type="text"
              v-model="contributor.company"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default"
                 @click="contributorDialog = false"
                 :fab="$vuetify.breakpoint.xsOnly"
                 :fixed="$vuetify.breakpoint.xsOnly"
                 :bottom="$vuetify.breakpoint.xsOnly"
                 :left="$vuetify.breakpoint.xsOnly">
            <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary"
                 @click="saveContributor"
                 :fab="$vuetify.breakpoint.xsOnly"
                 :fixed="$vuetify.breakpoint.xsOnly"
                 :bottom="$vuetify.breakpoint.xsOnly"
                 :right="$vuetify.breakpoint.xsOnly">
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tenantApi from '@/api/tenant';

export default {
  name: 'ContributorGroups',
  props: {
    actionId: Number,
  },
  data() {
    return {
      groups: [],
      contributorHeaders: [
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Company', value: 'company', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      panels: null,
      groupDialog: false,
      contributorDialog: false,
      contributorGroupName: null,
      contributorGroupId: null,
      contributor: {
        email: null,
        name: null,
        company: null,
      },
    }
  },
  watch: {
    actionId() {
      this.panels = null;
      this.updateSelected();
      this.groups = [...this.groups];
    }
  },
  computed: {
    isNoGroupSelected() {
      return !this.groups.find(g => g.selected);
    }
  },
  methods: {
    updateSelected() {
      this.groups.forEach(g => g.selected = !!g.actionIds.find(i => i === this.actionId));
    },
    async getContributorGroups() {
      try {
        this.groups = await tenantApi.getContributorGroups();
        this.groups.sort((a, b) => a.name < b.name ? -1 : b.name < a.name ? 1 : 0);
        this.updateSelected();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async selectContributorGroup(group) {
      try {
        if (group.selected) {
          await tenantApi.addActionToGroup(this.actionId, group.id);
          const i = group.actionIds.indexOf(this.actionId);
          if (i === -1) {
            group.actionIds.push(this.actionId);
          }
        } else {
          await tenantApi.removeActionFromGroup(this.actionId, group.id);
          const i = group.actionIds.indexOf(this.actionId);
          if (i >= 0) {
            group.actionIds.splice(i, 1);
          }
        }
        this.groups = [...this.groups];
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async saveGroup() {
      if (!this.$refs.groupForm.validate()) {
        return;
      }
      try {
        const group = await tenantApi.createContributorGroup({ name: this.contributorGroupName });
        await tenantApi.addActionToGroup(this.actionId, group.id);
        this.groupDialog = false;
        this.contributorGroupName = null;
        this.$showSuccessNotification(this.$t('contributor.group_created'));
      } catch (error) {
        this.$handleApiError(error, this.$t('contributor.group_create_fail'));
      }
      await this.getContributorGroups();
    },
    async saveContributor() {
      if (!this.$refs.contributorForm.validate()) {
        return;
      }
      try {
        const contributor = await tenantApi.createContributor(this.contributor);
        await tenantApi.addContributorToGroup(contributor.id, this.contributorGroupId);
        this.contributorDialog = false;
        this.contributor = {
          email: null,
          name: null,
          company: null,
        };
        this.$showSuccessNotification(this.$t('contributor.contributor_created'));
      } catch (error) {
        this.$handleApiError(error, this.$t('contributor.contributor_create_fail'));
      }
      await this.getContributorGroups();
    },
    openGroupDialog() {
      if (this.$refs.groupForm) {
        this.$refs.groupForm.resetValidation();
      }
      this.groupDialog = true;
    },
    openContributorDialog(group) {
      if (this.$refs.contributorForm) {
        this.$refs.contributorForm.resetValidation();
      }
      this.contributorDialog = true
      this.contributorGroupId = group.id;
    },
    copyLink(contributor) {
      const el = document.createElement('textarea');
      el.value = this.$getSharedContributorUrl(this.$tenant, contributor.uuid);
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
  },
  async mounted() {
    await this.getContributorGroups();
  }
}
</script>

<style lang="scss">
  .contributor-table {
    .v-data-table-header-mobile {
      th {
        padding-top: 10px !important;
        height: 0 !important;
      }
    }
  }
</style>
