<template>
  <div class="users-list">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <template v-for="user in users">
      <div :key="user.id">
        <v-list-item
          style="background: none;"
        >
          <v-list-item-avatar :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject, 75, 70) : 'grey lighten-2'" class="user-avatar">
            <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
            <v-icon v-if="type === 'user'" color="white" style="margin-top: 8px; margin-left: 2px;" size="36">lnr lnr-user</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3" :class="!user.enabled ? 'grey--text text--lighten-1' : ''">
                  {{ user.email ? user.email : user.subject }}
                  <template v-if="user.firstName && user.lastName">
                    <br >
                    <span class="caption grey--text">{{ user.firstName }} {{ user.lastName }}</span>
                  </template>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6" lg="7" xl="9"></v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu bottom left :close-on-content-click="$vuetify.breakpoint.smAndUp">
              <template v-slot:activator="{ on }">
                <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">more_vert</v-icon></v-btn>
              </template>
              <v-list dense class="pt-0 pb-0">
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ $t('user_management.email') }}:</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="18">lnr lnr-user</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ user.username }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-subheader>{{ $t('user_management.manage_user') }}:</v-subheader>
                <v-list-item @click="removeUserFromTenant(user)">
                  <v-list-item-avatar><v-icon>lnr lnr-circle-minus</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('tenants.delete_user') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </template>

  </div>
</template>

<script>
  import tenantApi from "../../../api/tenant";

  export default {
    name: "UsersList",
    props: {
      users: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: 'user',
      },
    },
    data() {
      return {
        subtleLoading: false,
      }
    },
    methods: {
      reloadUsers() {
        this.$emit('reload-users');
      },
      async removeUserFromTenant(user) {
        try {
          await tenantApi.removeUserFromTenant(user.id);
          this.reloadUsers();
          this.$showSuccessNotification(this.$t('tenants.user_delete_success'));
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('tenants.user_delete_fail'));
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .user-avatar {
    overflow: hidden;
  }
</style>
