<template>
  <v-container :fill-height="loading || plans && plans.length === 0" class="plans-list">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12">
          <hb-heading :class="plans && plans.length > 0 ? '' : 'mt-0'" :value="$tc('plan.title', 2)"></hb-heading>
        </v-col>

        <template v-if="plans && plans.length === 0">
          <v-col cols="12" class="headline text-center">
            <div class="mb-5">{{ $t('plan.get_started_guide') }}</div>
            <v-btn x-large color="primary" @click="newPlan" :disabled="$store.getters.tenantNotActive"><v-icon left>mdi-plus</v-icon>{{ $t('plan.create_new_plan') }}</v-btn>
            <v-btn color="primary" x-large :disabled="$store.getters.tenantNotActive" :loading="copyingSamplePlan" :class="{'ml-5': $vuetify.breakpoint.smAndUp,
              'mt-5': $vuetify.breakpoint.xsOnly} "
                   @click="createSamplePlan"><v-icon left>mdi-plus</v-icon>{{ $t('plan.get_sample_plan') }}</v-btn>
          </v-col>
        </template>

        <template v-if="plans && plans.length > 0">
          <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="4" offset-xl="4">
            <v-alert type="info" :value="true" outlined v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t('plan.reorder_plans_tooltip')}}
            </v-alert>
            <v-list two-line color="transparent">
              <draggable @change="dragOver" :options="{ draggable: '.single-plan' }" handle=".handle" v-model="plans">
                <div v-for="plan in plans" :key="plan.id" class="single-plan">
                  <v-list-item @click="openPlan(plan)">
                    <v-list-item-avatar class="handle">
                      <v-icon large>
                        mdi mdi-reorder-horizontal
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title :class="$vuetify.breakpoint.xsOnly ? 'font-weight-medium' : 'headline'">{{ plan.name }}</v-list-item-title>
                      <v-list-item-subtitle><span class="sdg-label">{{ $t('plan.created') }}:</span> {{ $formatDateWithMonthName(plan.created) }}</v-list-item-subtitle>
                      <v-list-item-subtitle><span class="sdg-label">{{ $t('plan.updated') }}:</span> {{ $formatDateWithMonthName(plan.updated) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on }">
                          <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">more_vert</v-icon></v-btn>
                        </template>
                        <v-list class="pt-0 pb-0">
                          <v-list-item @click="deletePlan(plan)">
                            <v-list-item-icon><v-icon>lnr lnr-trash</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('plan.delete_plan') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item :key="`${plan.id}_info`" v-if="plan.fromSample">
                    <v-list-item-content>
                    <span class="caption">
                      {{ $t('plan.sample_plan_info')}}
                    </span>
                    </v-list-item-content>
                  </v-list-item>
                <v-divider :key="`${plan.id}_divider`"></v-divider>
                </div>
              </draggable>
            </v-list>
            <div class="text-center mt-5">
              <v-btn color="primary" :disabled="$store.getters.tenantNotActive" @click="newPlan"><v-icon left>mdi-plus</v-icon>{{ $t('plan.create_new_plan') }}</v-btn>
              <v-btn color="primary" v-if="showCopySamplePlanButton" :disabled="$store.getters.tenantNotActive" :loading="copyingSamplePlan" :class="{'ml-5': $vuetify.breakpoint.smAndUp,
              'mt-5': $vuetify.breakpoint.xsOnly} "
                     @click="createSamplePlan"><v-icon left>mdi-plus</v-icon>{{ $t('plan.get_sample_plan') }}</v-btn>
            </div>
          </v-col>
        </template>

      </v-row>
    </v-fade-transition>

    <ConfirmDialog ref="confirm" />

  </v-container>

</template>

<script>
  import draggable from 'vuedraggable';
  import tenantApi from "../../api/tenant";
  import ConfirmDialog from "@/components/ConfirmDialog";

  export default {
    name: 'PlansView',
    components: {ConfirmDialog, draggable},
    data() {
      return {
        loading: false,
        subtleLoading: false,
        copyingSamplePlan: false,
        plans: [],
      }
    },
    computed: {
      showCopySamplePlanButton() {
        return this.plans.filter(p => p.fromSample).length === 0;
      }
    },
    methods: {
      async createSamplePlan() {
        this.copyingSamplePlan = true;
        try {
          await tenantApi.getSamplePlan();
          await this.reloadPlans();
        } catch (error) {
          this.$handleApiError(error);
        }

        this.copyingSamplePlan = false;
      },
      async dragOver() {
        this.subtleLoading = true;
        try {
          await tenantApi.reorderPlans(this.plans.map(p => p.id));
        } catch (error) {
          this.$handleApiError(error);
        }

        this.subtleLoading = false;
      },
      openPlan(plan) {
        this.$router.push({ name: 'plan', params: { planId: plan.id } });
      },
      newPlan() {
        this.$router.push({ name: 'name_your_plan' });
      },
      async reloadPlans() {
        this.loading = true;

        try {
          this.plans = await tenantApi.getPlans();
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;
      },
      async deletePlan(plan) {
        const confirm = await this.$refs.confirm.open(this.$t('plan.delete_plan'), this.$t('plan.delete_plan_confirm'), { color: 'error', agree: this.$t('delete'), cancel: this.$t('cancel')});
        if (!confirm) {
          return;
        }

        this.loading = true;

        try {
          await tenantApi.deletePlan(plan.id);
          await this.reloadPlans();
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;
      },
    },
    mounted() {
      this.reloadPlans();
    }
  }
</script>

<style scoped>

</style>
