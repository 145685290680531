<template>
  <v-app light id="app">
    <template>
      <template v-if="loadingOverlay">
        <v-fade-transition>
          <div class="loading-overlay">
            <v-row class="fill-height" justify="center" align="center">
              <v-col cols="4" class="text-center">
                <hb-loading-indicator align-middle />
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </template>
      <template v-else>
        <v-snackbar v-model="snackbar" top multi-line :timeout="notification.timeOut" :color="notification.color">
          {{ notification.text }}
          <template v-slot:action>
            <template v-if="notification.showButton">
              <v-btn small outlined text @click="() => { notification.callback(); snackbar = false;}">
                {{ notification.buttonText }}
              </v-btn>
            </template>
            <template v-else>
              <v-btn icon @click="snackbar = false">
                <v-icon>lnr lnr-cross</v-icon>
              </v-btn>
            </template>
          </template>
        </v-snackbar>

        <v-navigation-drawer
          v-if="shouldMenuBeAvailable"
          v-model="menuOpenState"
          class="ignore-export"
          app
          fixed
          light
          right
          color="grey lighten-5"
          width="300"
        >
          <v-list class="pa-0" color="transparent" :style="$vuetify.breakpoint.smAndUp ? 'height: 110px; padding-top: 20px !important;' : ''">
            <v-list-item style="padding: 2px 8px;">
              <v-list-item-avatar width="30" height="30" tile @click.stop="toggleMenu" style="margin-top: 10px; margin-bottom: 10px;">
                <img style="height: 100%; width: auto;" src="/img/sdgmonitor-logo-m.svg" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="subheading font-weight-bold">{{ $t('title') }}</v-list-item-title>
                <v-list-item-subtitle class="caption" v-if="$isLoggedIn">{{ $userInfo.email }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-scale-transition>
                  <v-btn
                    v-if="menuOpenState"
                    icon
                    @click.stop="toggleMenu"
                  >
                    <v-icon size="38">lnr lnr-cross</v-icon>
                  </v-btn>
                </v-scale-transition>
              </v-list-item-action>

            </v-list-item>
          </v-list>

          <v-list :dense="$vuetify.breakpoint.mdAndDown">

            <template v-if="$userInfo && $userInfo.tenants.length > 1">
              <TenantSelect :miniMenu="miniMenu" />
            </template>

            <v-list-item
              v-for="(page, index) in filteredPrimaryPages"
              :key="index+'primary'"
              :to="getPageLink(page)"
              exact
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <template v-if="filteredSecondaryPages.length > 0">
              <v-list-item
                v-for="(page, index) in filteredSecondaryPages"
                :key="index+'secondary'"
                @click="closeMenuIfOnSamePage(page)"
                :to="getPageLink(page)"
                exact
              >
                <v-list-item-action>
                  <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ page.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>

            <template v-if="filteredTenantAdminPages.length > 0">
              <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
              <v-list-item
                v-for="(page, index) in filteredTenantAdminPages"
                :key="index+'tadmin'"
                @click="closeMenuIfOnSamePage(page)"
                :to="getPageLink(page)"
                exact>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ page.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider ></v-divider>
            </template>

            <template v-if="filteredAdminPages.length > 0">
              <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.application_admin') }}</v-subheader>
              <v-list-item
                v-for="(page, index) in filteredAdminPages"
                :key="index+'admin'"
                @click="closeMenuIfOnSamePage(page)"
                :to="getPageLink(page)"
                exact>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ page.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider ></v-divider>
            </template>

            <v-list-item @click="closeMenuIfOnSamePage({link: 'userprofile'})"
                         :to="{name: 'userprofile'}"
                         exact>
              <v-list-item-action>
                <v-icon color="grey lighten-1">lnr lnr-user</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('user_profile.title') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-action>
                <v-icon color="grey lighten-1">lnr lnr-exit</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>

        </v-navigation-drawer>
        <tos-accept-dialog />
        <v-main>
          <router-view/>
        </v-main>

      </template>
    </template>
  </v-app>
</template>

<script>
  import TenantSelect from "./components/TenantSelect.vue";
  import TenantService from './service/tenant.service';
  import TosAcceptDialog from "@/components/TosAcceptDialog.vue";

  export default {
    components: { TosAcceptDialog, TenantSelect },
    data() {
      return {
        notification: {
          color: '',
          text: '',
          buttonText: '',
          showButton: false,
          timeOut: -1,
          callback: () => {},
        },
        snackbar: false,
        miniMenu: false,
        bottomNavSelected: '',
        showBottomNav: true,
        menuOpenState: false,
        noTenantPages: [
          {
            title: 'New account',
            icon: 'lnr lnr-cog',
            link: 'new_account',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
        ],
        tenantPages: [
          {
            title: 'Dashboards',
            icon: 'lnr lnr-pie-chart',
            link: 'dashboards',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: 'Plans',
            icon: 'lnr lnr-magic-wand',
            link: 'plans',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
        ],
        tenantManagementPages: [
          {
            title: this.$t('tenants.management_title'),
            icon: 'lnr lnr-cog',
            link: 'tenant_settings',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: false,
          },
          {
            title: this.$t('tenants.organization_users'),
            icon: 'lnr lnr-users',
            link: 'tenant_users',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: false,
          },
        ],
        adminPages: [
          {
            title: this.$t('user_management.title'),
            icon: 'lnr lnr-users',
            link: 'users',
            roles: ['ADMIN'],
            primary: false,
          },
          {
            title: this.$t('tenants.title'),
            icon: 'lnr lnr-apartment',
            link: 'tenants',
            roles: ['ADMIN'],
            primary: false,
          },
          {
            title: this.$t('system_settings.title'),
            icon: 'lnr lnr-cog',
            link: 'system_settings',
            roles: ['ADMIN'],
            primary: false,
          },
        ],
      }
    },
    methods: {
      getPageLink(page) {
        const link = {
          name: page.link,
        }

        if ('params' in page) {
          link.params = page.params();
        }
        return link;
      },
      logout() {
        // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
        this.$Amplify.Auth.signOut().then(() => {
          // this is client side only logout
          this.$router.push('/')
          this.$clearUserInfo();
          this.$store.commit('setLoggedIn', false);
          window.location.reload();
        })
      },
      goToLink(name) {
        this.$router.push({name: name});
      },
      toggleMenu() {
        this.$store.commit('toggleMainMenuState');
      },
      closeMenuIfOnSamePage(page) {
        if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === page.link) {
          this.menuOpenState = false;
        }
      },
      handleSelectedBottomNavItem(name) {
        const isPagePrimary = this.filteredTenantPages.findIndex(p => p.primary && p.link === name) > -1;
        if (isPagePrimary) {
          this.bottomNavSelected = name;
          this.showBottomNav = true;
        } else {
          this.showBottomNav = false;
        }
      },
    },
    watch: {
      $tenant(to) {
        const dashboardKey = 'selected-dashboard-' + to;
        if (localStorage.getItem(dashboardKey) != null) {
          const planId = localStorage.getItem(dashboardKey);
          this.$store.commit('setPlanForDashboards', Number.parseInt(planId));
        }
      },
      notificationObject(to) {
        this.notification.color = to.color;
        this.notification.text = to.message;
        this.snackbar = true;
        this.notification.buttonText = to.buttonText;
        this.notification.showButton = to.showButton;
        this.notification.timeOut = to.timeOut;
        this.notification.callback = to.callback;
      },
      menuOpenComputed(to) {
        this.menuOpenState = to;
      },
      menuOpenState(to) {
        if (to !== this.$store.state.mainMenuOpen) {
          this.$store.commit('toggleMainMenuState');
        }
      },
      $route(to, from) {
        if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
        this.handleSelectedBottomNavItem(to.name);
      },
      currentRouteName(val) {
        if (!this.$isLoggedIn && val !== 'login') {
          this.$router.push({name: 'login'});
        }
      },
    },
    computed: {
      tosAcceptDate() {
        return this.$userInfo && this.$userInfo.tosAcceptDate;
      },
      tenant() {
        return this.$store.state.tenant;
      },
      loadingOverlay() {
        return this.$store.state.loadingOverlay;
      },
      shouldMenuBeAvailable() {
        return this.$isLoggedIn;
      },
      notificationObject() {
        return this.$store.state.notificationObject;
      },
      menuOpenComputed() {
        return this.$store.state.mainMenuOpen;
      },
      filteredSecondaryPages() {
        let filteredTenantPages = [];
        this.filteredTenantPages.forEach(page => {
          if (!page.primary) {
            filteredTenantPages.push(page);
          }
        })
        return filteredTenantPages;
      },
      filteredTenantPages() {
        if (this.$tenant) {
          return this.tenantPages;
        }
        return this.noTenantPages;
      },
      filteredTenantAdminPages() {
        if (this.$tenant) {
          return this.tenantManagementPages;
        }
        return [];
      },
      filteredAdminPages() {
        if (this.$isAdmin) {
          return this.adminPages;
        }
        return [];
      },
      filteredPrimaryPages() {
        let pages = [];
        this.filteredTenantPages.forEach(page => {
          if (page.primary) {
            pages.push(page);
          }
        })
        return pages;
      },
      currentRouteName() {
        return this.$route.name;
      },
    },
    created() {
      // If application is opened from link, and some other tenant id has been saved in tenant service
      // This sets the one from URL
      if ('tenantId' in this.$router.currentRoute.params) {
        TenantService.setTenant(this.$router.currentRoute.params.tenantId);
      }
    },
    mounted() {
      this.menuOpenState = this.$store.state.mainMenuOpen;
      this.handleSelectedBottomNavItem(this.$route.name);
    },
  }
</script>

<style lang="scss">
  @import "./sass/commonstyles.scss";
</style>
