<template>
  <v-card v-if="action" elevation="0" color="#f5f5f5">
    <v-tabs
      v-model="actionTab"
      background-color="transparent"
      grow
      class="action-tabs"
    >
      <v-tab style="text-transform: none;">
        {{ $t('plan.action_details') }}
      </v-tab>
      <v-tab style="text-transform: none;">
        {{ $t('plan.custom_impacts') }}
      </v-tab>
      <v-tab style="text-transform: none;">
        {{ $t('plan.data_limits') }}
      </v-tab>
      <v-tab style="text-transform: none;" :disabled="newAction">
        {{ $t('plan.collected_data') }}
      </v-tab>
      <v-tab style="text-transform: none;" :disabled="newAction">
        {{ $t('plan.contributors') }}
      </v-tab>

      <v-tabs-items v-model="actionTab" style="background-color: transparent;" id="tabItems">
        <v-tab-item>
          <v-form ref="actionFormDetails">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="pa-1" v-if="newAction">
                  <v-alert type="info" :value="true" prominent text>
                    {{ $t('plan.action_creation_1') }}
                  </v-alert>
                </v-col>
                <v-col cols="12" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.your_action_or_goal') }}</div>
                  <v-text-field :placeholder="$t('plan.start_typing_here')" validate-on-blur :rules="[$rules.required]" v-model="description" :hint="$t('plan.action_name_hint')" solo-inverted flat />
                </v-col>
                <v-col cols="12" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.sdg_business_indicator') }}</div>

                  <SDGBusinessIndicatorSelector :best-matches="filteredIndicators"
                                                :all-indicators="indicators"
                                                @select-indicator="handleIndicatorSelected"
                                                :selected-indicator="selectedIndicator" />

                </v-col>
                <template v-if="impact && esg">
                  <v-col cols="12" sm="6" class="pa-1">
                    <v-alert :value="true" color="primary" colored-border border="left">
                      <div class="subtitle-1 font-weight-medium primary--text">{{ $t('plan.detailed_impact') }}</div>
                      {{ impact }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-1">
                    <v-alert :value="true" color="primary" colored-border border="left">
                      <div class="subtitle-1 font-weight-medium primary--text">{{ $t('plan.esg_impact') }}</div>
                      {{ esg }}
                    </v-alert>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-form ref="actionCustomImpacts">
            <v-card-text>
              <v-row no-gutters>
                <action-custom-impacts v-model="selectedCustomImpacts"></action-custom-impacts>
              </v-row>
            </v-card-text>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-form ref="actionFormDataLimits">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="pa-1" v-if="newAction">
                <v-alert type="info" :value="true" prominent text>
                  <div>{{ $t('plan.action_creation_2') }}</div>
                  <div class="mt-2">{{ $t('plan.action_creation_2_2') }}</div>
                </v-alert>
              </v-col>
              <v-col cols="12" class="px-2">
                <div class="text-center mb-3">{{ $t('plan.choose_the_status_of_your_data') }}</div>
                <v-select solo-inverted flat v-model="actionMeasurementStatus" :label="$t('plan.choose_the_status_of_your_data')" :items="statusDataOptions"></v-select>
              </v-col>
              <template v-if="actionMeasurementStatus">
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.start_value') }}</div>
                  <v-text-field inputmode="decimal" solo-inverted flat :placeholder="$t('type_here')" :rules="[$rules.required, $rules.number]" v-model="startValue"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.target_value') }}</div>
                  <v-text-field inputmode="decimal" solo-inverted flat :placeholder="$t('type_here')" :rules="[$rules.required, $rules.number]" v-model="targetValue"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">
                    {{ $t('plan.value_type') }}
                    <v-tooltip top left>
                      <template v-slot:activator="{on, attrs}">
                        <v-icon v-on="on" v-bind="attrs" size="16" class="ml-1" style="margin-top: -1px;" color="primary">lnr lnr-question-circle</v-icon>
                      </template>
                      <data-point-types-info :tooltip="true"></data-point-types-info>
                    </v-tooltip>
                  </div>
                  <v-select :items="dataTypes" v-model="dataPointType"
                            :rules="[$rules.required]"
                            flat solo-inverted></v-select>
                </v-col>
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.unit') }}</div>
                  <v-combobox :items="unitItems" item-text="unit" solo-inverted flat
                              ref="unitCombo"
                              :rules="[$rules.required]" :placeholder="$t('select')" v-model="selectedUnit"></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.start_date') }}</div>
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateModal"
                    :rules="[$rules.required]"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    offset-y
                    :min-width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 24px)' : 'auto'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :value="$formatDateNoTime(startDate)"
                        solo-inverted
                        flat
                        :rules="[$rules.required]"
                        readonly>
                        <template slot="append">
                          <v-icon color="secondary" size="16">lnr lnr-calendar-full</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :full-width="$vuetify.breakpoint.xsOnly"
                      v-model="startDateShort"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      @input="startDateModal = false"
                      color="secondary"
                      locale="en"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.target_date') }}</div>
                  <v-menu
                    ref="targetDateMenu"
                    v-model="targetDateModal"
                    :rules="[$rules.required]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :min-width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 24px)' : 'auto'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :value="$formatDateNoTime(endDate)"
                        solo-inverted
                        flat
                        :rules="[$rules.required]"
                        readonly>
                        <template slot="append">
                          <v-icon color="secondary" size="16">lnr lnr-calendar-full</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :full-width="$vuetify.breakpoint.xsOnly"
                      v-model="endDateShort"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      color="secondary"
                      @input="targetDateModal = false"
                      locale="en"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" xl="3" class="pa-1">
                  <div class="text-center mb-3">{{ $t('plan.action_owner') }}</div>
                  <v-combobox :rules="[$rules.required]"
                              flat
                              solo-inverted
                              v-model="source"
                              class="rounded-lg"
                              :items="userEmails"

                  ></v-combobox>
                </v-col>
              </template>
            </v-row>
            <v-row v-if="!newAction">
              <v-col cols="12">
                <action-logs :action-logs="actionLogs" :action="action"
                             @action-log-updated="actionLogUpdated"
                             @action-log-added="actionLogAdded"
                             @action-log-note-deleted="actionLogNoteDeleted"
                            @action-log-deleted="actionLogDeleted"></action-logs>
              </v-col>
            </v-row>
          </v-card-text>
          </v-form>
        </v-tab-item>

        <v-tab-item>
         <data-points :data-points="dataPoints" :attachments="attachments" :action="action" @click:edit="openEditDataPointDialog" @click:delete="deleteDataPoint" :unit="unit"></data-points>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <contributor-groups ref="contributorGroups" :action-id="id"></contributor-groups>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-divider></v-divider>

    <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''">
      <template v-if="!newAction">
        <v-spacer></v-spacer>
        <!-- show add new datapoint in all tabs -->
        <v-btn class="action-button" :block="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly"
               color="secondary" @click="addNewValue" :disabled="$store.getters.tenantNotActive || alreadyNewDatapoint">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('plan.add_new_date_and_value') }}
        </v-btn>
        <!-- hidden only in contributor tab -->
        <v-btn class="action-button" v-if="actionTab !== 4 && actionTab !== 3" :block="$vuetify.breakpoint.xsOnly"
               :small="$vuetify.breakpoint.xsOnly" color="primary" :loading="loading" @click="saveAction"
               :disabled="$store.getters.tenantNotActive">{{ $t('plan.save_data') }}
        </v-btn>
        <!-- save contributors only in contributor tab -->
        <v-btn class="action-button" v-if="actionTab === 4" :block="$vuetify.breakpoint.xsOnly"
               :small="$vuetify.breakpoint.xsOnly" color="primary" :loading="loading" @click="openContributorDialog"
               :disabled="$store.getters.tenantNotActive">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('contributor.create_new_group') }}
        </v-btn>
      </template>
      <template v-if="newAction && actionTab === 0">
        <v-spacer></v-spacer>
        <v-btn class="action-button" :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" color="primary" :loading="loading" @click="moveToNextTab">{{ $t('next') }}</v-btn>
      </template>
      <template v-if="newAction && actionTab > 0">
        <v-btn class="action-button" :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" color="secondary" :loading="loading" @click="moveToPreviousTab">{{ $t('previous') }}</v-btn>
        <v-spacer></v-spacer>
        <template v-if="newAction && actionTab === 1">
          <v-btn class="action-button" :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" color="primary" :loading="loading" @click="moveToNextTab">{{ $t('next') }}</v-btn>
        </template>
        <template v-else>
          <v-btn class="action-button" :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" color="primary" :loading="loading" @click="saveAction" :disabled="$store.getters.tenantNotActive">{{ $t('plan.save_action') }}</v-btn>
        </template>
      </template>
    </v-card-actions>
    <data-point-dialog ref="dataPointDialog" :action="action"
                       :unit="unit"
                       @data-point-added="dataPointAdded"
                       @data-point-updated="dataPointUpdated"></data-point-dialog>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
  import tenantApi from "@/api/tenant";
  import ContributorGroups from "@/components/Plan/ContributorGroups";
  import SDGBusinessIndicatorSelector from "./SDGBusinessIndicatorSelector";
  import IndicatorSelector from '@/service/indicatorselector.service';
  import ActionCustomImpacts from "@/components/Plan/ActionCustomImpacts";
  import DataPointDialog from "@/components/Plan/DataPointDialog.vue";
  import DataPoints from "@/components/Plan/DataPoints.vue";
  import ConfirmDialog from "@/components/ConfirmDialog.vue";
  import dataPointApi from "@/api/dataPointApi";
  import ActionLogs from "@/components/Plan/ActionLogs.vue";
  import actionApi from "@/api/actionApi";
  import file from "@/api/file";
  import DataPointTypesInfo from "../DataPointTypesInfo";

  export default {
    name: 'Action',
    components: {
      DataPointTypesInfo,
      ActionLogs,
      ConfirmDialog,
      DataPoints,
      DataPointDialog,
      ActionCustomImpacts,
      SDGBusinessIndicatorSelector,
      ContributorGroups
    },
    props: ['users', 'action'],
    data() {
      return {
        search: null,
        actionTab: null,
        selectedIndicatorCode: '',
        selectedIndicator: null,
        selectedCustomImpacts: [],
        loading: false,
        dataPointTemplate: {
          id: null,
          date: null,
          value: null,
          edited: true,
          source: '',
        },
        attachments: [],
        startDateModal: false,
        targetDateModal: false,
        dataPointType: 'ABSOLUTE',
        unit: '',
        description: '',
        notes: '',
        indicatorDescription: null,
        indicatorCode: null,
        indicatorIndex: null,
        impactId: null,
        impactText: '',
        impactLongText: '',
        esg: null,
        sdgIndex: null,
        currentValue: null,
        startValue: null,
        targetValue: null,
        startDate: null,
        source: '',
        endDate: null,
        selectedUnit: null,
        unitDescription: null,
        dataPoints: [],
        actionLogs: [],
        id: null,
        planId: null,
        actionMeasurementStatus: 1,
        statusDataOptions: [
          { value: 0, text: this.$t('plan.action_no_data_yet') },
          { value: 1, text: this.$t('plan.action_measuring_started') }
        ]
      }
    },
    watch: {
      startValue(to) {
        if (typeof(to) === 'string' && to.indexOf(',') !== -1) {
          this.startValue = to.replace(',', '.');
        }
      },
      targetValue(to) {
        if (typeof(to) === 'string' && to.indexOf(',') !== -1) {
          this.targetValue = to.replace(',', '.');
        }
      },
      selectedUnit(to) {
        if (to != null && to.id != null) {
          this.unitId = to.id;
          this.unitDescription = to.description;
          this.unit = to.unit;
        } else {
          this.unitId = null;
          this.unitDescription = to;
          this.unit = to;
        }
      },
      selectedIndicatorCode(to) {
        const i = this.indicators.find(ind => ind.code === to);
        if (i != null) {
          this.selectedIndicator = i;
          this.indicatorCode = i.code;
          this.impactId = i.impact.id;
          this.impactText = i.impact.description;
          this.impactLongText = i.impact.longDesc;
          this.esg = i.impact.esg;
          this.indicatorDescription = i.shortDescription;
          this.sdgIndex = i.sdgIndex;
        } else {
          this.selectedIndicator = null;
          this.indicatorCode = null;
          this.impactId = null;
          this.impactText = null;
          this.impactLongText = null;
          this.esg = null;
          this.indicatorDescription = null;
          this.sdgIndex = null;
        }
      },
      action(a) {
        this.setActionData(a);
      },
    },
    computed: {
      userEmails() {
        return this.users.map(u => u.email);
      },
      dataTypes() {
        return ['CUMULATIVE', 'ABSOLUTE'].map(type => ({
          text: this.$t(`plan.${type}`),
          value: type
        }));
      },
      unitItems() {
        return [{ header: 'Select or type' }, ...this.units];
      },
      indicators() {
        return this.$store.state.systemData.indicators;
      },
      units() {
        return this.$store.state.systemData.units;
      },
      startDateShort: {
        get() {
          return this.startDate ? new Date(this.startDate).toISOString().slice(0, 10) : null;
        },
        set(val) {
          this.startDate = new Date(val).toISOString();
        }
      },
      endDateShort: {
        get() {
          return this.endDate ? new Date(this.endDate).toISOString().slice(0, 10) : null;
        },
        set(val) {
          this.endDate = new Date(val).toISOString();
        }
      },
      impact() {
        const i = this.selectedIndicator;
        if (i != null) {
          return i.impact.description;
        }
        return '';
      },
      filteredIndicators() {
        return IndicatorSelector.filterByKeywords(this.indicators, this.description);
      },
      newAction() {
        return !this.action.id;
      },
      alreadyNewDatapoint() {
        return this.dataPoints.filter(dp => dp.id == null).length > 0;
      },
    },
    methods: {
      actionLogNoteDeleted() {
        this.notes = null;
        this.action.notes = null;
      },
      actionLogDeleted() {
        this.reloadActionLogs();
      },
      async reloadActionLogs() {
        this.actionLogs = await actionApi.getActionLogs(this.action.id);
      },
      actionLogUpdated(al) {
        if (al.id) {
          this.reloadActionLogs();
        } else {
          this.notes = al.note;
          this.action.notes = al.note;
        }
      },
      actionLogAdded() {
        this.reloadActionLogs();
      },
      async deleteDataPoint(dataPoint) {
        const result = await this.$refs.confirm.open(this.$t('plan.action_delete_datapoint_confirm'),
          this.$t('plan.action_delete_datapoint'),
          { color: 'error', agree: this.$t('delete'), cancel: this.$t('cancel') }
        );

        if (!result) {
          return;
        }

        this.$set(dataPoint, '_deleting', true);
        try {
          await dataPointApi.deleteDataPoint(dataPoint.id);
          this.dataPoints.splice(this.dataPoints.indexOf(dataPoint), 1);
        } catch (error) {
          this.$handleApiError(error);
        }

        this.$set(dataPoint, '_deleting', false);
      },
      async reloadFiles() {
        this.attachments = await file.getFiles(this.action.id, 'action');
      },
      dataPointAdded(dataPoint) {
        this.dataPoints.push(dataPoint);
        this.reloadFiles();
      },
      dataPointUpdated(dataPoint) {
        const existingIndex = this.dataPoints.findIndex(dp => dp.id === dataPoint.id);
        this.dataPoints.splice(existingIndex, 1, dataPoint);
        this.reloadFiles();
      },
      openEditDataPointDialog(datapoint) {
        this.$refs.dataPointDialog.openEdit(datapoint);
      },
      setTab(tab) {
        this.actionTab = tab;
      },
      openContributorDialog() {
        this.$refs.contributorGroups.openGroupDialog();
      },
      moveToNextTab() {
        this.actionTab = this.actionTab ? this.actionTab + 1 : 1;
        setTimeout(this.scrollToTopOfTabItems, 360);
      },
      moveToPreviousTab() {
        this.actionTab = this.actionTab ? this.actionTab - 1 : 0;
        setTimeout(this.scrollToTopOfTabItems, 360);
      },
      scrollToTopOfTabItems() {
        this.$vuetify.goTo('#tabItems', { offset: 70 });
      },
      async saveAction() {
        setTimeout(async () => {
          if (this.$refs.actionFormDetails && !this.$refs.actionFormDetails.validate()) {
            this.actionTab = 0;
            return;
          }
          if (this.$refs.actionCustomImpacts && !this.$refs.actionCustomImpacts.validate()) {
            this.actionTab = 1;
            return;
          }
          if (this.$refs.actionFormDataLimits && !this.$refs.actionFormDataLimits.validate()) {
            this.actionTab = 2;
            return;
          }

          if (this.action) {
            this.loading = true;

            const data = {
              description: this.description,
              notes: this.notes,
              indicatorDescription: this.indicatorDescription,
              indicatorCode: this.indicatorCode,
              indicatorIndex: this.indicatorIndex,
              impactId: this.impactId,
              impactText: this.impactText,
              impactLongText: this.impactLongText,
              esg: this.esg,
              dataPointType: this.dataPointType,
              sdgIndex: this.sdgIndex,
              containsData: this.actionMeasurementStatus === 1,
              startValue: this.startValue,
              targetValue: this.targetValue,
              startDate: this.startDate,
              endDate: this.endDate,
              unitId: this.unitId,
              unitDescription: this.unitDescription,
              unit: this.unit,
              id: this.id,
              source: this.source,
              planId: this.planId,
              customImpactIds: this.selectedCustomImpacts.map(ci => ci.id),
            };

            if (this.action.id === null) {
              try {
                const newAction = await tenantApi.createAction(this.action.planId, data);
                this.$emit('create-action', newAction);
                this.$showSuccessNotification(this.$t('plan.action_created'));
              } catch (error) {
                console.log(error);
                this.$handleApiError(error, this.$t('plan.action_create_fail'));
              }
            } else {
              try {
                const updatedAction = await tenantApi.updateAction(this.action.id, data);
                this.$emit('update-action', updatedAction);
                this.$showSuccessNotification(this.$t('plan.action_updated'));
              } catch (error) {
                console.log(error);
                this.$handleApiError(error, this.$t('plan.action_update_fail'));
              }
            }

            this.loading = false;
          }
        }, 0)
      },
      async addNewValue() {
        this.actionTab = 3;
        this.$refs.dataPointDialog.openAdd();
      },
      editDataPoint(oldValue, newValue) {
        this.dataPoints.splice(this.dataPoints.indexOf(oldValue), 1, { ...newValue, edited: true });
      },
      async setActionData(action) {
        if (this.$refs.dataForm != null) {
          this.$refs.dataForm.resetValidation();
        }
        if (this.$refs.actionFormDetails != null) {
          this.$refs.actionFormDetails.resetValidation();
        }
        if (this.$refs.actionFormDataLimits != null) {
          this.$refs.actionFormDataLimits.resetValidation();
        }
        this.description = action.description;
        this.indicatorDescription = action.indicatorDescription;
        this.notes = action.notes;
        this.indicatorCode = action.indicatorCode;
        this.indicatorIndex = action.indicatorIndex;
        this.impactId = action.impactId;
        this.impactText = action.impactText;
        this.impactLongText = action.impactLongText;
        this.esg = action.esg;
        this.sdgIndex = action.sdgIndex;
        this.dataPointType = action.dataPointType;
        this.startValue = action.startValue;
        this.targetValue = action.targetValue;
        this.startDate = action.startDate;
        this.endDate = action.endDate;
        this.currentValue = action.currentValue;
        this.unitId = action.unitId;
        this.source = action.source;
        this.id = action.id;
        this.planId = action.planId;
        this.actionMeasurementStatus = action.containsData ? 1 : 0;
        this.selectedCustomImpacts = action.customImpacts;

        this.selectedIndicatorCode = action.indicatorCode;

        this.unitDescription = action.unitDescription;
        this.unit = action.unit;

        if (this.unitId != null && this.unitId !== '') {
          const usedUnit = this.units.find(u => u.id === Number.parseInt(this.unitId + ''));
          if (usedUnit != null) {
            this.selectedUnit = usedUnit;
          }
        } else {
          this.selectedUnit = this.unit;
        }

        if (this.id === null) {
          this.dataPoints = [];
          this.actionLogs = [];
          this.actionTab = 0;
        } else {
          this.reloadActionLogs();
          this.dataPoints = await tenantApi.getDataPoints(this.action.id);
          this.attachments = await file.getFiles(this.action.id, 'action');
        }
      },
      handleIndicatorSelected(indicatorCode) {
        this.selectedIndicatorCode = indicatorCode;
      },
    },
    mounted() {
      if (this.action) {
        this.setActionData(this.action);
      }
    },

  }
</script>

<style lang="scss" scoped>
  @media only screen and (max-width: 599px) {
    .action-button {
      margin-left: 0 !important;
      margin-top: 5px;
    }
  }
</style>
