<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :disabled="readonly"
    min-width="auto"
    offset-y
    transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :clearable="clearable"
        :dense="dense"
        :disabled="saving"
        :error="error"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :label="label"
        :prepend-inner-icon="prependInnerIcon"
        readonly
        v-bind="{ ...attrs, ...inputStyles }"
        @blur="/*parseDate(dateFormatted)*/"
        v-on="on"
        @click:clear="onChange(null)">
        <template v-if="!!append" v-slot:append>
          <v-btn icon x-small @click.prevent="append.callback">
            <v-icon size="20">{{ append.icon }}</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :active-picker.sync="activePicker"
      :max="birthDate ? maxDate : ''"
      :min="birthDate ? '1950-01-01' : ''"
      :show-week="true"
      :type="type"
      :value="isoFormattedDate()"
      color="primary"
      no-title
      @change="onChange"
      @input="menu = false"></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'DatePicker',
  props: {
    birthDate: Boolean,
    value: [Object, String],
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'date',
    },
    inputStyles: Object,
    saving: Boolean,
    prependInnerIcon: String,
    append: Object,
    clearable: Boolean,
    dense: Boolean,
    hideDetails: Boolean,
    error: Boolean,
    errorMessages: [String, Array],
    readonly: Boolean,
  },
  data() {
    return {
      activePicker: null,
      dateFormatted: '',
      content: this.value,
      menu: false,
    }
  },
  computed: {
    maxDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
    },
  },
  methods: {
    onChange(e) {
      console.log("onchange", e)
      this.$emit('input', e)
    },
    parseDate(d) {
      if (d === null) {
        return ''
      }
      if (this.type === 'month') {
        this.$emit('input', dayjs(d, ['MM/YYYY', 'MMYYYY', 'MMYY']).format('YYYY-MM-DD'))
      } else {
        this.$emit('input', dayjs(d, ['DD.MM.YYYY', 'DDMMYYYY', 'DDMMYY']).format('YYYY-MM-DD'))
      }
    },
    formatDate() {
      const date = dayjs(this.value)
      if (!this.value || !date.isValid()) {
        this.dateFormatted = ''
        return
      }

      if (this.type === 'month') {
        this.dateFormatted = date.format('MM/YYYY')
      } else {
        this.dateFormatted = date.format('DD.MM.YYYY')
      }
    },
    isoFormattedDate() {
      const date = dayjs(this.value)
      if (!date.isValid()) return
      if (this.type === 'month') {
        return (date && date.format('YYYY-MM')) || null
      }
      return (date && date.format('YYYY-MM-DD')) || null
    },
  },
  mounted() {
    this.formatDate()
  },
  watch: {
    value() {
      this.formatDate()
    },
    menu(val) {
      val && setTimeout(() => (this.birthDate ? (this.activePicker = 'YEAR') : (this.activePicker = null)))
    },
  },
}
</script>

<style scoped></style>
