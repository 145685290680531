<template>
  <div v-if="tooltip" style="max-width: 500px;" class="mt-3">
    <ol class="caption">
      <li>{{ $t('plan.value_types_absolute') }}</li>
      <li>{{ $t('plan.value_types_incremental') }}</li>
    </ol>
  </div>
  <v-alert v-else :value="true" text prominent type="info" icon="lnr lnr-question-circle" class="mt-5">
    <ol class="caption">
      <li>{{ $t('plan.value_types_absolute') }}</li>
      <li>{{ $t('plan.value_types_incremental') }}</li>
    </ol>
  </v-alert>
</template>

<script>
export default {
  name: 'DataPointTypesInfo',
  props: ['tooltip']
}
</script>

<style scoped>

</style>
