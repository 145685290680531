<template>
  <v-card-text>
    <v-alert :value="action.containsData && dataPoints && dataPoints.length === 0" text type="info">{{ $t('plan.no_added_data_yet') }}</v-alert>
    <v-alert :value="!action.containsData" v-html="$t('plan.no_data_yet_help')" type="warning" text></v-alert>
    <v-data-table class="transparent" v-if="action.containsData && dataPoints && dataPoints.length > 0" :headers="headers" :items="dataPointsSorted" hide-default-footer>
      <template v-slot:item.date="{item}">
        {{ $formatDateNoTime(item.date) }}
      </template>
      <template v-slot:item.type="{item}">
        <td v-if="showValueType">
          {{ $t('plan.' + item.type) }}
        </td>
      </template>
      <template v-slot:item.value="{item}">
        <td class="text-right"> {{ item.value }} {{ unit }}</td>
      </template>
      <template v-slot:item.source="{item}">
        <td class="text-center">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small icon v-on="on"><v-icon>lnr lnr-eye</v-icon></v-btn>
            </template>
            <v-list dark dense class="transparent pa-0 ma-0" width="350">
              <v-list-item v-if="item.userEmail" class="px-0">
                <v-list-item-content style="max-width: 125px;" >
                  <v-list-item-title class="white--text text-wrap">{{ $t('plan.source_user') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text text-right text-wrap">
                    <template v-if="item.userFirstName">
                      {{ item.userFirstName ? `${item.userFirstName} ${item.userLastName}` : ''}}
                      <br />
                      <span class="caption">{{ item.userEmail }}</span>
                    </template>
                    <template v-else>
                      {{ item.userEmail }}
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.contributorEmail" class="px-0">
                <v-list-item-content style="max-width: 125px;" >
                  <v-list-item-title class="white--text text-wrap">{{ $t('plan.source_contributor') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text text-right text-wrap">
                    <template v-if="item.contributorName">
                      {{ item.contributorName }}
                      <br />
                      <span class="caption">{{ item.contributorEmail }}</span>
                    </template>
                    <template v-else>
                      {{ item.contributorEmail }}
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.source" class="px-0">
                <v-list-item-content style="max-width: 125px;">
                  <v-list-item-title class="white--text text-wrap">{{ $t('plan.data_source') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text text-right text-wrap">
                    {{ item.source }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.created" class="px-0">
                <v-list-item-content style="max-width: 125px;">
                  <v-list-item-title class="white--text text-wrap">{{ $t('plan.source_created_date') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text text-right text-wrap">
                    {{ $formatDateTime(item.created) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:item.attachments="{item}">
        <td>
          <template v-for="attachment in getAttachmentsForDataPoint(item)">
            <v-btn :key="attachment.objectKey" text x-small class="my-1" :href="attachment.publicUrl" target="_blank">
              <v-icon x-small left>lnr lnr-download</v-icon>
              <div style="max-width: 100px;" class="text-truncate">{{ attachment.description }}</div>
            </v-btn>
          </template>
        </td>
      </template>
      <template v-slot:item.actions="{item}">
        <td class="text-right">
          <v-btn icon small @click="openEdit(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon small @click="deleteDataPoint(item)" :loading="item._deleting">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <data-point-types-info v-if="showValueType"></data-point-types-info>
  </v-card-text>
</template>

<script>
import { sortBy, uniqBy } from 'lodash';
import DataPointTypesInfo from "@/components/DataPointTypesInfo.vue";

export default {
  components: { DataPointTypesInfo },
  props: ['dataPoints', 'action', 'unit', 'attachments'],
  name: 'DataPoints',
  data() {
    return {}
  },
  computed: {
    showValueType() {
      return uniqBy(this.dataPoints, 'type').length > 1;
    },
    headers() {
      const headers = [
        {
          text: this.$t('plan.date'),
          value: 'date',
          sortable: false,
        },
        {
          text: this.$t('plan.value_type'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('plan.value'),
          value: 'value',
          align: 'right',
          sortable: false,
        },
        {
          text: this.$t('plan.attachments'),
          value: 'attachments',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('plan.source'),
          value: 'source',
          align: 'center',
          sortable: false,
          width: '100px',
        },
        {
          text: '',
          value: 'actions',
          class: 'text-right',
          sortable: false,
          width: '100px',
        },
      ];

      if (!this.showValueType) {
        return headers.filter(h => h.value !== 'type');
      }

      return headers;
    },
    dataPointsSorted() {
      return sortBy(this.dataPoints, 'date', 'asc');
    }
  },
  methods: {
    getAttachmentsForDataPoint(item) {
      return this.attachments.filter(a => a.field === item.id + '');
    },
    openEdit(item) {
      this.$emit('click:edit', item);
    },
    deleteDataPoint(item) {
      this.$emit('click:delete', item);
    },
  },
}
</script>

<style scoped>

</style>
