import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"plans-list",attrs:{"fill-height":_vm.loading || _vm.plans && _vm.plans.length === 0}},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.subtleLoading),expression:"subtleLoading"}],staticStyle:{"position":"fixed","left":"0","right":"0","margin":"0","z-index":"99"},style:({top: _vm.$vuetify.breakpoint.smAndDown ? '54px' : '62px'}),attrs:{"color":"primary lighten-2","height":"2","indeterminate":true}}),_c('hb-app-bar'),(_vm.loading)?_c('hb-loading-indicator',{attrs:{"align-middle":""}}):_vm._e(),_c(VFadeTransition,[(!_vm.loading)?_c(VRow,{staticStyle:{"position":"relative"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('hb-heading',{class:_vm.plans && _vm.plans.length > 0 ? '' : 'mt-0',attrs:{"value":_vm.$tc('plan.title', 2)}})],1),(_vm.plans && _vm.plans.length === 0)?[_c(VCol,{staticClass:"headline text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t('plan.get_started_guide')))]),_c(VBtn,{attrs:{"x-large":"","color":"primary","disabled":_vm.$store.getters.tenantNotActive},on:{"click":_vm.newPlan}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('plan.create_new_plan')))],1),_c(VBtn,{class:{'ml-5': _vm.$vuetify.breakpoint.smAndUp,
            'mt-5': _vm.$vuetify.breakpoint.xsOnly},attrs:{"color":"primary","x-large":"","disabled":_vm.$store.getters.tenantNotActive,"loading":_vm.copyingSamplePlan},on:{"click":_vm.createSamplePlan}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('plan.get_sample_plan')))],1)],1)]:_vm._e(),(_vm.plans && _vm.plans.length > 0)?[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2","lg":"6","offset-lg":"3","xl":"4","offset-xl":"4"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VAlert,{attrs:{"type":"info","value":true,"outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('plan.reorder_plans_tooltip'))+" ")]):_vm._e(),_c(VList,{attrs:{"two-line":"","color":"transparent"}},[_c('draggable',{attrs:{"options":{ draggable: '.single-plan' },"handle":".handle"},on:{"change":_vm.dragOver},model:{value:(_vm.plans),callback:function ($$v) {_vm.plans=$$v},expression:"plans"}},_vm._l((_vm.plans),function(plan){return _c('div',{key:plan.id,staticClass:"single-plan"},[_c(VListItem,{on:{"click":function($event){return _vm.openPlan(plan)}}},[_c(VListItemAvatar,{staticClass:"handle"},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi mdi-reorder-horizontal ")])],1),_c(VListItemContent,[_c(VListItemTitle,{class:_vm.$vuetify.breakpoint.xsOnly ? 'font-weight-medium' : 'headline'},[_vm._v(_vm._s(plan.name))]),_c(VListItemSubtitle,[_c('span',{staticClass:"sdg-label"},[_vm._v(_vm._s(_vm.$t('plan.created'))+":")]),_vm._v(" "+_vm._s(_vm.$formatDateWithMonthName(plan.created)))]),_c(VListItemSubtitle,[_c('span',{staticClass:"sdg-label"},[_vm._v(_vm._s(_vm.$t('plan.updated'))+":")]),_vm._v(" "+_vm._s(_vm.$formatDateWithMonthName(plan.updated)))])],1),_c(VListItemAction,[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VBtn,{staticClass:"mt-0 mb-0",attrs:{"text":"","icon":"","color":"grey"}},[_c(VIcon,_vm._g({attrs:{"size":"28"}},on),[_vm._v("more_vert")])],1)]}}],null,true)},[_c(VList,{staticClass:"pt-0 pb-0"},[_c(VListItem,{on:{"click":function($event){return _vm.deletePlan(plan)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("lnr lnr-trash")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('plan.delete_plan')))])],1)],1)],1)],1)],1)],1),(plan.fromSample)?_c(VListItem,{key:((plan.id) + "_info")},[_c(VListItemContent,[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('plan.sample_plan_info'))+" ")])])],1):_vm._e(),_c(VDivider,{key:((plan.id) + "_divider")})],1)}),0)],1),_c('div',{staticClass:"text-center mt-5"},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.$store.getters.tenantNotActive},on:{"click":_vm.newPlan}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('plan.create_new_plan')))],1),(_vm.showCopySamplePlanButton)?_c(VBtn,{class:{'ml-5': _vm.$vuetify.breakpoint.smAndUp,
            'mt-5': _vm.$vuetify.breakpoint.xsOnly},attrs:{"color":"primary","disabled":_vm.$store.getters.tenantNotActive,"loading":_vm.copyingSamplePlan},on:{"click":_vm.createSamplePlan}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('plan.get_sample_plan')))],1):_vm._e()],1)],1)]:_vm._e()],2):_vm._e()],1),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }