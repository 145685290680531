import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c(VContainer,{staticClass:"overview-dashboard",attrs:{"fill-height":_vm.loading}},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.subtleLoading),expression:"subtleLoading"}],staticStyle:{"position":"fixed","left":"0","right":"0","margin":"0","z-index":"99"},style:({top: _vm.$vuetify.breakpoint.smAndDown ? '54px' : '62px'}),attrs:{"color":"primary lighten-2","height":"2","indeterminate":true}}),_c('hb-app-bar'),(_vm.loading)?_c('hb-loading-indicator',{attrs:{"align-middle":""}}):_vm._e(),_c(VFadeTransition,[(!_vm.loading)?_c(VRow,{staticStyle:{"position":"relative"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"ignore-export",attrs:{"justify":"space-between"}},[_c(VCol,{staticClass:"pb-0 text-center text-sm-left"},[_c(VBtn,{staticClass:"hidden-print-only",attrs:{"text":""},on:{"click":_vm.backToDashboards}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("lnr lnr-arrow-left")]),_vm._v(_vm._s(_vm.$t('dashboards.back_to_dashboards')))],1)],1),_c(VCol,{staticClass:"text-center text-sm-right pb-0"},[(_vm.settings != null)?_c('DashboardSettings',{attrs:{"plan-id":_vm.planId},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}}):_vm._e()],1)],1),(_vm.plan != null)?_c('OverviewDashboard',{attrs:{"actions":_vm.actions,"plan":_vm.plan,"settings":_vm.settings,"editable":true},on:{"title-changed":_vm.titleChanged,"long-term-saved":_vm.longTermSaved}}):_vm._e(),_c('DashboardFooter')],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }