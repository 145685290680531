<template>
  <div class="dashboard">
  <v-container :fill-height="loading" :fluid="$vuetify.breakpoint.lgAndDown" class="sdg-dashboard">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;"
                       :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2"
                       height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12">

          <v-row justify="space-between" class="ignore-export">
            <v-col class="pb-0 text-center text-sm-left">
              <v-btn text @click="backToDashboards" class="hidden-print-only"><v-icon left>lnr lnr-arrow-left</v-icon>{{ $t('dashboards.back_to_dashboards') }}</v-btn>
            </v-col>
            <v-col class="text-center text-sm-right pb-0">
              <DashboardSettings v-model="settings" v-if="settings != null" :plan-id="planId"></DashboardSettings>
            </v-col>
          </v-row>

          <SDGDashboard :plan="plan" :sdg-index="sdgIndex" :actions="actions" :settings="settings"
                        :editable="true" @title-changed="titleChanged"
                        @sdg-clicked="sdgClicked" v-if="plan.id != null"></SDGDashboard>

          <DashboardFooter />

        </v-col>

      </v-row>
    </v-fade-transition>

  </v-container>
  </div>
</template>

<script>
  import tenantApi from "../../api/tenant";
  import SDGDashboard from "../../components/SDGDashboard/SDGDashboard";
  import DashboardSettings from "../../components/DashboardSettings";
  import DashboardFooter from "@/components/DashboardFooter";

  export default {
    name: 'SDGDashboardView',
    components: {DashboardFooter, DashboardSettings, SDGDashboard },
    props: {
      sdgIndex: {
        type: [Number, String],
        default: 1,
      },
      planId: {
        type: [Number, String],
        default: 1,
      },
    },
    data() {
      return {
        loading: false,
        subtleLoading: false,
        plan: {},
        actions: [],
        settings: null,
        logo: null,
      }
    },
    methods: {
      async titleChanged(newTitle) {
        this.settings.title = newTitle;
        await tenantApi.updateDashboardSettings(`sdg-${this.planId}-${this.sdgIndex}`, {
          title: newTitle,
          shared: this.settings.shared,
          meta: this.settings.meta,
        });
      },
      sdgClicked(sdgIndex) {
        this.$router.push({ name: 'sdg_dashboard', params: { sdgIndex, planId: this.planId } });
      },
      async reloadActions() {
        this.loading = true;
        try {
          const plan = await tenantApi.getPlan(this.planId);
          this.plan = plan;
          this.actions = plan.actions;
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;
      },
      backToDashboards() {
        this.$router.push({ name: 'dashboards' });
      },
    },
    async mounted() {
      this.settings = await tenantApi.getDashboardSettings('sdg', this.planId, this.sdgIndex);
      await this.reloadActions();
    },
  }
</script>

<style scoped>

</style>
