<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('system_settings.edit_indicator') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">
          <v-text-field
            class="mt-5"
            label="Unit"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="short_description"
            v-model="currentUnit.unit"
          ></v-text-field>

          <v-textarea
            class="mt-5"
            label="Description"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="code"
            v-model="currentUnit.description"
          ></v-textarea>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields')}}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">lnr lnr-cross</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveUnit"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditUnitDialog",
  data() {
    return {
      dialog: false,
      showAlert: false,
      currentUnit: {},
      originalUnit: {}
    }
  },
  methods: {
    saveUnit() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      if (this.currentUnit.id) {
        this.updateUnit();
      } else {
        this.createUnit();
      }
    },
    async updateUnit() {
      try {
        const currentUnit = this.currentUnit;
        const unit = await this.$systemApi.updateUnit(this.currentUnit.id, {
          description: currentUnit.description,
          unit: currentUnit.unit,
        });

        this.$emit('unit-updated', unit);

        this.dialog = false;
        this.$showSuccessNotification('Unit updated');
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async createUnit() {
      try {
        const currentUnit = this.currentUnit;
        const unit = await this.$systemApi.createUnit({
          description: currentUnit.description,
          unit: currentUnit.unit,
        });

        this.$emit('unit-updated', unit);

        this.dialog = false;
        this.$showSuccessNotification('Unit created');
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    open(unit) {
      if (unit != null) {
        this.currentUnit = Object.assign({}, unit);
      } else {
        this.currentUnit = {
          unit: '',
          description: '',
        }
      }

      this.dialog = true;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.currentUnit = {};
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
}
</script>

<style scoped>

</style>
