<template>
  <v-list-item>
    <template v-if="miniMenu">
      <v-list-item-action class="justify-center">
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on" size="34" :color="$randomizeColorFromString(selectedTenant, 75, 70)" class="organization-avatar">
              <span class="text-uppercase font-weight-black white--text">{{ organizationLetters }}</span>
            </v-avatar>
          </template>
          <v-list dense class="pt-0 pb-0">
            <v-subheader>{{ $t('tenants.choose_organization') }}:</v-subheader>
            <v-list-item v-for="tenant in possibleTenants" @click="selectTenant(tenant)" :key="tenant.id">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ tenant.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </template>
    <template v-else>
      <v-select :label="$t('tenants.organization')"
                outlined
                hide-details
                class="mt-3 mb-3"
                v-model="selectedTenant"
                :items="possibleTenants"
                item-text="name"
                item-value="id"
                @change="tenantSelected"></v-select>
    </template>
  </v-list-item>
</template>

<script>
  import _ from 'lodash';
  import tenantService from "../service/tenant.service";

  export default {
    name: 'TenantSelect',
    props: {
      miniMenu: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        selectedTenant: null,
      }
    },
    methods: {
      selectTenant(tenant) {
        this.selectedTenant = tenant.id;
        this.tenantSelected();
      },
      async tenantSelected() {
        tenantService.setTenant(this.selectedTenant);
        window.location = '/';
      },
    },
    computed: {
      possibleTenants() {
        return _.orderBy(this.$userInfo.tenants, ['created']);
      },
      organizationLetters() {
        const orgIndex = this.possibleTenants.findIndex(tenant => tenant.id === this.selectedTenant);
        if (orgIndex > -1) {
          return this.possibleTenants[orgIndex].name.substring(0, 2);
        }
        return '';
      },
    },
    mounted() {
      this.selectedTenant = tenantService.tenant;
    }
  }
</script>

<style lang="scss" scoped>
  .organization-avatar {
    margin: 0;
  }
</style>
