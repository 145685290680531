import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,{attrs:{"color":"transparent"}},[_vm._l((_vm.contributorGroups),function(contributorGroup){return [_c(VListItem,{key:((contributorGroup.id) + "_item"),staticStyle:{"background":"none"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(contributorGroup.name))])],1),_c(VListItemAction,{staticClass:"my-0"},[_c(VMenu,{attrs:{"bottom":"","left":"","close-on-content-click":_vm.$vuetify.breakpoint.smAndUp},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"mt-0 mb-0",attrs:{"text":"","icon":"","color":"grey"}},[_c(VIcon,_vm._g({attrs:{"size":"28"}},on),[_vm._v("more_vert")])],1)]}}],null,true)},[_c(VList,{staticClass:"pt-0 pb-0",attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.openEditContributorGroupDialog(contributorGroup)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("lnr lnr-users")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('contributor.manage_members')))])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.openContributorGroupDetails(contributorGroup)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("edit")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('contributor.rename_group')))])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.deleteContributorGroup(contributorGroup)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("lnr lnr-circle-minus")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('contributor.remove_group')))])],1)],1)],1)],1)],1)],1)]})],2),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.openContributorGroupDetails()}}},[_vm._v(" "+_vm._s(_vm.$t('contributor.create_new_group'))+" ")])],1),_c('contributor-group-members-dialog',{ref:"contributorGroupMembersDialog"}),_c('contributor-group-dialog',{ref:"contributorGroupDialog",attrs:{"contributor-groups":_vm.contributorGroups},on:{"refresh":_vm.getContributorGroups}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }