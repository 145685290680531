import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center mb-3"},[_vm._v(_vm._s(_vm.root.text))]),_c(VSelect,{attrs:{"items":_vm.nonRootImpacts,"no-data-text":_vm.$t('plan.no_impacts_in_custom_impact'),"item-value":"id","clearable":"","solo-inverted":"","flat":""},on:{"change":_vm.selectedImpactIdChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [(_vm.maxLevel === 2 && item.level === 1)?[_c(VListItem,{staticClass:"py-0 mt-2",staticStyle:{"min-height":"20px !important","height":"20px !important"},attrs:{"disabled":""}},[_c(VListItemTitle,{staticClass:"caption",style:({color: item.color ? item.color : ''})},[_vm._v(" "+_vm._s(item.text))])],1)]:_c('div',[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.selectedImpactId),callback:function ($$v) {_vm.selectedImpactId=$$v},expression:"selectedImpactId"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }