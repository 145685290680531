<template>
  <v-container :fill-height="loading || plans.length === 0" class="dashboards">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12">
          <hb-heading :class="plans && plans.length > 0 ? '' : 'mt-0'" :value="$tc('dashboards.title', 2)"></hb-heading>
        </v-col>

        <template v-if="!loading && plans && plans.length === 0">
          <v-col cols="12" class="headline text-center">
            <div class="mb-5">{{ $t('dashboards.no_plans_guide') }}</div>
            <v-btn x-large color="primary" @click="moveToPlanCreation" :disabled="$store.getters.tenantNotActive"><v-icon left>mdi-plus</v-icon> {{ $t('dashboards.create_plan') }}</v-btn>
          </v-col>
        </template>

        <template v-if="!loading && plans && plans.length > 0">
          <v-col cols="12">

            <v-row justify="center" v-if="plans.length > 1">
              <v-col cols="12" md="6">
                <v-select :disabled="$store.getters.tenantNotActive" :label="$t('dashboards.select_plan')" class="rounded-pill" outlined :items="plans" item-value="id" item-text="name" v-model="selectedPlan"></v-select>
              </v-col>
            </v-row>

            <v-row>

              <v-col cols="12" md="4" xl="4">
                <v-card class="sdg-card blue-border rounded-0" :to="{ name: 'overview_dashboard', params: { planId: selectedPlan } }" :disabled="$store.getters.tenantNotActive">
                  <v-card-title>
                    <div class="headline primary--text text-truncate"><v-icon color="accent" left style="margin-top: -7px;">lnr lnr-pie-chart</v-icon>{{ $t('dashboards.overview') }}</div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" xl="4">
                <v-card class="sdg-card blue-border rounded-0" :to="{ name: 'impacts_dashboard', params: { planId: selectedPlan } }" :disabled="$store.getters.tenantNotActive">
                  <v-card-title>
                    <div class="headline primary--text text-truncate"><v-icon color="accent" left style="margin-top: -7px;">lnr lnr-chart-bars</v-icon>{{ $t('dashboards.impacts') }}</div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" xl="4">
                <v-card class="sdg-card blue-border rounded-0" :to="{ name: 'my_sdg_performance_dashboard', params: { planId: selectedPlan } }" :disabled="$store.getters.tenantNotActive">
                  <v-card-title>
                    <div class="headline primary--text text-truncate"><v-icon color="accent" left style="margin-top: -7px;">lnr lnr-list</v-icon>{{ $t('dashboards.my_sdg_performance') }}</div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" v-if="rootCustomImpactsInUse.length > 0">
                <v-card class="sdg-card blue-border rounded-0" :disabled="$store.getters.tenantNotActive">
                  <v-card-text>
                    <div class="sdg-heading-2 mt-5">{{ $t('dashboards.my_custom_impacts') }}</div>
                    <v-list color="transparent">
                      <template v-for="customImpact in rootCustomImpactsInUse">
                        <v-list-item :key="customImpact.id" :to="{ name: 'my_custom_impacts', params: { planId: selectedPlan, rootCustomImpactId: customImpact.id } }" :disabled="$store.getters.tenantNotActive">
                          <v-list-item-content>
                            <v-list-item-title>{{ customImpact.text }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="customImpact.id + 'divider'"></v-divider>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="sdg-card blue-border rounded-0">
                  <v-card-text>
                    <div class="sdg-heading-2 mt-5">{{ $t('dashboards.sdgs') }}</div>
                    <v-list color="transparent">
                      <template v-for="item in usedSdgs" >
                        <v-list-item :key="item" :to="{ name: 'sdg_dashboard', params: { sdgIndex: item, planId: selectedPlan } }" :disabled="$store.getters.tenantNotActive">
                          <v-list-item-avatar tile><v-img :src='$getSDGImage(item)'></v-img></v-list-item-avatar>
                          <v-list-item-content :style="`color: `">
                            <v-list-item-title>SDG{{ item }}</v-list-item-title>
                            <v-list-item-subtitle>{{ sdgs[item].text }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="item + 'divider'"></v-divider>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="sdg-card blue-border rounded-0">
                  <v-card-text>
                    <div class="sdg-heading-2 mt-5">{{ $tc('dashboards.action', 2) }}</div>
                    <v-list color="transparent">
                      <template v-for="action in actions" >
                        <v-list-item :key="action.id" :to="{ name: 'action_dashboard', params: { actionId: action.id, planId: selectedPlan } }" :disabled="$store.getters.tenantNotActive">
                          <v-list-item-content>
                            <v-list-item-title>{{ action.description }}</v-list-item-title>
                            <v-list-item-subtitle v-if="action.startDate">{{ $formatDateWithMonthName(action.startDate) }} - {{ $formatDateWithMonthName(action.endDate) }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-else>{{ $t('plan.action_no_data_yet') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="action.id + 'divider'"></v-divider>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </template>

      </v-row>
    </v-fade-transition>

  </v-container>

</template>

<script>
  import tenantApi from "../../api/tenant";
  import _ from 'lodash';
  import params from "../../config/params";

  export default {
    name: 'Dashboards',
    data() {
      return {
        plans: [],
        usedSdgs: [],
        actions: [],
        loading: true,
        subtleLoading: false,
        selectedPlan: null,
        customImpacts: [],
      }
    },
    methods: {
      async reloadActions() {
        const plan = await tenantApi.getPlan(this.selectedPlan);
        this.actions = plan.actions;
        const sdgs = _.groupBy(this.actions, 'sdgIndex');
        this.usedSdgs = Object.keys(sdgs)
      },
      moveToPlanCreation() {
        this.$router.push({ name: 'name_your_plan' });
      },
    },
    computed: {
      usedCustomImpacts() {
        const customImpacts = this.actions.flatMap(a => a.customImpacts);
        return customImpacts;
      },
      rootCustomImpactsInUse() {
        const usedCustomImpacts = this.usedCustomImpacts;
        const getRoot = (impact) => {
          if (impact.parentId) {
            return getRoot(this.customImpacts.find(i => i.id === impact.parentId));
          }
          return impact;
        }

        const roots = {};

        usedCustomImpacts.forEach(i => {
          const root = getRoot(i);
          if (!(root.id in roots)) {
            roots[root.id] = root;
          }
        })

        return Object.values(roots);
      },
      sdgs() {
        return params.sdgs;
      },
    },
    watch: {
      selectedPlan() {
        this.reloadActions();
        this.$store.commit('setPlanForDashboards', this.selectedPlan);
        const dashboardKey = 'selected-dashboard-' + this.$tenant;
        localStorage.setItem(dashboardKey, this.selectedPlan);
      },
    },
    async mounted() {
      try {
        this.plans = await tenantApi.getPlans()
        if (this.plans.length > 0) {
          if (this.$store.state.planSelectedForDashboard == null) {
            this.selectedPlan = this.plans[0].id;
            this.$store.commit('setPlanForDashboards', this.plans[0].id);
          } else {
            this.selectedPlan = this.$store.state.planSelectedForDashboard;
          }
        }
        this.customImpacts = await tenantApi.getCustomImpacts();
      } catch (error) {
        this.$handleApiError(error);
      }

      this.loading = false;
    }
  }
</script>

<style scoped>

</style>
