import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"system-settings-view",attrs:{"fill-height":_vm.loading,"fluid":""}},[_c('hb-app-bar'),(_vm.loading)?_c('hb-loading-indicator',{attrs:{"align-middle":""}}):_vm._e(),_c(VFadeTransition,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTabs,{attrs:{"background-color":"transparent"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTab,[_vm._v("Indicators")]),_c(VTab,[_vm._v("Units")]),_c(VTabsItems,{staticClass:"pt-5",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabItem,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtnToggle,{model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}},[_c(VBtn,[_vm._v(" Search from all ")]),_c(VBtn,[_vm._v(" Search from keywords ")])],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"outlined":"","label":"Search"},model:{value:(_vm.searchPhrase),callback:function ($$v) {_vm.searchPhrase=$$v},expression:"searchPhrase"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.filteredIndicators,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" lnr lnr-pencil ")])]}}])})],1)],1)],1),_c(VTabItem,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.createNewUnit}},[_c('span',[_vm._v("Create new unit")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.units,"headers":_vm.unitHeaders},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.editUnit(item)}}},[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.editUnit(item)}}},[_vm._v(" lnr lnr-pencil ")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteUnit(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" lnr lnr-trash ")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1),_c('EditIndicatorDialog',{ref:"editDialog",attrs:{"impacts":_vm.impacts},on:{"indicator-updated":_vm.indicatorUpdated}}),_c('EditUnitDialog',{ref:"editUnitDialog",on:{"unit-updated":_vm.unitUpdated}}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }