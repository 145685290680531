<template>
  <v-container fluid :fill-height="loading" class="new-account" :pa-0="$vuetify.breakpoint.xsOnly">
    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;"
                       :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2"
                       height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row no-gutters v-if="!loading">
        <v-col cols="12" lg="10" offset-lg="1" xl="8" offset-xl="2" class="text-center">
          <hb-heading :value="$t('tenants.new_tenant_title')"/>
          <v-row>
            <v-col cols="12" lg="8" offset-lg="2" md="10" offset-md="1" xl="6" offset-xl="3">
              <v-form ref="form">
                <v-text-field
                  class="mt-5 organization_name"
                  :label="$t('tenants.organization_name')"
                  :rules="[$rules.required]"
                  validate-on-blur
                  full-width
                  solo
                  background-color="#f4f4f4"
                  flat
                  type="text"
                  autocomplete="organization"
                  v-model="name"
                ></v-text-field>
                <v-text-field
                  class="mt-5 organization_street_address"
                  :label="$t('tenants.details.street_address')"
                  :rules="[$rules.required]"
                  validate-on-blur
                  full-width
                  solo
                  background-color="#f4f4f4"
                  flat
                  type="text"
                  autocomplete="name"
                  v-model="streetAddress"
                ></v-text-field>
                <v-text-field
                  class="mt-5 organization_zip_code"
                  :label="$t('tenants.details.zip_code')"
                  :rules="[$rules.required]"
                  validate-on-blur
                  full-width
                  solo
                  background-color="#f4f4f4"
                  flat
                  type="text"
                  autocomplete="zipCode"
                  v-model="zipCode"
                ></v-text-field>
                <v-text-field
                  class="mt-5 organization_city"
                  :label="$t('tenants.details.city')"
                  :rules="[$rules.required]"
                  validate-on-blur
                  full-width
                  solo
                  background-color="#f4f4f4"
                  flat
                  type="text"
                  autocomplete="city"
                  v-model="city"
                ></v-text-field>
                <CountrySelect v-model="country" :solo="true" :rules="[$rules.required]"></CountrySelect>
                <v-select :items="businesses" item-text="text" full-width
                          solo
                          background-color="#f4f4f4"
                          flat
                          item-value="id" :label="$t('tenants.details.business')"
                          v-model="business"></v-select>
                <v-select :items="headcounts" item-text="text"
                          solo
                          background-color="#f4f4f4"
                          flat
                          full-width item-value="id" :label="$t('tenants.details.headcount')"
                          v-model="headcount"></v-select>
                <v-select :items="turnovers" item-text="text"
                          solo
                          background-color="#f4f4f4"
                          flat
                          full-width item-value="id" :label="$t('tenants.details.turnover')"
                          v-model="turnover"></v-select>
              </v-form>
              <v-alert :value="showAlert" outlined class="mt-4" type="error">{{
                $t('validation.check_all_required_fields')}}
              </v-alert>
              <v-btn color="primary" :loading="saving"
                     @click="saveOrganization"
                     :fab="$vuetify.breakpoint.xsOnly"
                     :fixed="$vuetify.breakpoint.xsOnly"
                     :bottom="$vuetify.breakpoint.xsOnly"
                     :right="$vuetify.breakpoint.xsOnly">
                <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('tenants.new_tenant_save') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
  import params from "./../config/params";
  import CountrySelect from "../components/CountrySelect";
  import userApi from "../api/user";

  export default {
    name: "NoTenantsView",
    components: {
      CountrySelect,
    },
    data() {
      return {
        saving: false,
        subtleLoading: false,
        name: '',
        streetAddress: '',
        city: '',
        zipCode: '',
        country: null,
        business: null,
        headcount: null,
        turnover: null,
        showAlert: false,
        loading: false,
      }
    },
    methods: {
      async saveOrganization() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        this.saving = true;

        const data = {
          name: this.name,
          streetAddress: this.streetAddress,
          city: this.city,
          zipCode: this.zipCode,
          country: this.country,
          business: this.business,
          headcount: this.headcount,
          turnover: this.turnover,
        }

        try {
          const tenant = await userApi.createTenant(data);
          this.$router.replace({ name: 'tenant_settings', params: { tenantId: tenant.id } });
          window.location.reload();

        } catch (error) {
          this.$handleApiError(error);
        }

        this.saving = false;
      },
      logout() {
        // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
        this.$Amplify.Auth.signOut().then(() => {
          // this is client side only logout
          this.$clearUserInfo();
          this.$store.commit('setLoadingOverlay', true);
          this.$store.commit('setLoggedIn', false);
          this.$router.push({ name: 'login' });
        })
      },
    },
    computed: {
      businesses() {
        return params.businesses;
      },
      headcounts() {
        return params.headcount;
      },
      turnovers() {
        return params.turnover;
      },
    },
  }
</script>

<style scoped>

</style>
