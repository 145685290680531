import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.xsOnly ? '' : '600',"hide-overlay":_vm.$vuetify.breakpoint.xsOnly,"transition":_vm.$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('plan.copy_action')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("lnr lnr-cross")])],1)],1),_c(VCardText,[_c(VAlert,{staticClass:"mt-10",attrs:{"type":"info","value":true,"outlined":""}},[_vm._v(_vm._s(_vm.$t('plan.copy_action_info' )))]),_c(VSelect,{attrs:{"items":_vm.plans,"item-text":"name","label":_vm.$tc('plan.title', 1),"item-value":"id"},model:{value:(_vm.selectedPlanId),callback:function ($$v) {_vm.selectedPlanId=$$v},expression:"selectedPlanId"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c(VDivider):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"default","fab":_vm.$vuetify.breakpoint.xsOnly,"fixed":_vm.$vuetify.breakpoint.xsOnly,"bottom":_vm.$vuetify.breakpoint.xsOnly,"disabled":_vm.saving,"left":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.dismissDialog}},[(_vm.$vuetify.breakpoint.xsOnly)?_c(VIcon,[_vm._v("lnr lnr-cross")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))]):_vm._e()],1),_c(VBtn,{attrs:{"color":"primary","loading":_vm.saving,"fab":_vm.$vuetify.breakpoint.xsOnly,"fixed":_vm.$vuetify.breakpoint.xsOnly,"bottom":_vm.$vuetify.breakpoint.xsOnly,"right":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.copyToPlan}},[(_vm.$vuetify.breakpoint.xsOnly)?_c(VIcon,[_vm._v("check")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm.$t('copy')))]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }