<template>
  <div>
    <v-card color="grey lighten-4">

      <v-card-title>
        <div class="headline">{{ $tc('api_keys._', 2) }}</div>
        <v-spacer></v-spacer>
        <a class="body-1" href="/api-docs/index.html" target="_blank">{{ $t('api_keys.link_to_docs')}}</a>
      </v-card-title>
        <v-simple-table>
          <thead class="grey lighten-3">
          <tr>
            <th>
              {{ $t('api_keys.name') }}
            </th>
            <th>
              {{ $t('api_keys.public_key') }}
            </th>
            <th>
              {{ $t('api_keys.ips') }}
            </th>

            <th>
              {{ $t('api_keys.created_at') }}
            </th>
            <th>
              {{ $t('api_keys.expire_at') }}
            </th>
            <th>
              {{ $t('api_keys.last_used') }}
            </th>
            <th>
              {{ $t('api_keys.created_by') }}
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loadingApiKeys">
            <td colspan="100%" style="height: 4px; padding: 0;">
              <v-progress-linear indeterminate></v-progress-linear>
            </td>
          </tr>
          <tr v-if="apiKeys.length === 0 && !loadingApiKeys">
            <td colspan="100%">
              <span>{{ $t('api_keys.no_api_keys') }}</span>
            </td>
          </tr>
          <tr v-for="apiKey in apiKeys" :key="apiKey.publicKey">
            <td>{{ apiKey.name }}</td>
            <td>{{ apiKey.publicKey }}</td>
            <td>
              <template v-if="apiKey.ips">
                <v-chip v-for="(ip, i) in apiKeyIpsSplit(apiKey)" :key="i" class="mr-1" small>
                  {{ ip }}
                </v-chip>
              </template>
            </td>

            <td>{{ $formatDateTime(apiKey.createdAt) }}</td>
            <td :class="[isPast(apiKey.expireAt) ? 'error--text': '']">
              {{ $formatDateTime(apiKey.expireAt) }}
            </td>
            <td>{{ $formatDateTime(apiKey.lastUsed) }}</td>

            <td>{{ apiKey.createdBy }}</td>
            <td>
              <v-btn icon @click="openEditApiKeyDialog(apiKey)">
                <v-icon>mdi mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : ''">
        <v-btn
          color="primary"
          rounded
          text
          class="ma-1"
          @click="openNewAPIKeyDialog">{{ $t('api_keys.new_api_key') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly"
              :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
              :hide-overlay="$vuetify.breakpoint.xsOnly"
              :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
              :value="apiKeyDialog.show">
      <v-card :class="$vuetify.breakpoint.xsOnly && 'rounded-t-0'">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('api_keys.new_api_key') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="apiKeyDialog.show = false">
            <v-icon>lnr lnr-cross</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="apiKeyDialog.edit || !apiKeyDialog.apiKey.publicKey" class="pt-5" :style="{maxHeight: $vuetify.breakpoint.xsOnly && 'calc(100vh - 255px)'}">
          <v-form>
            <v-text-field v-model="apiKeyDialog.apiKey.name" :label="$t('api_keys.name')" :dense="$vuetify.breakpoint.xsOnly"/>

            <DatePicker
              v-model="apiKeyDialog.apiKey.expireAt"
              :dense="$vuetify.breakpoint.xsOnly"
              :input-styles="{ outlined: false, dense: false }"
              :label="$t('api_keys.expire_at')"
              :saving="apiKeyDialog.saving"
              clearable
              @input="handleApiKeyExpireAtChange"/>

            <v-textarea class="mt-5" rows="3" v-model="apiKeyDialog.apiKey.ips" :hint="$t('api_keys.ips_hint')" :label="$t('api_keys.ips')" persistent-hint/>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.name') }}
                  </td>
                  <td>{{ apiKeyDialog.apiKey.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.public_key') }}
                  </td>
                  <td>
                    <code ref="publicKey" class="mt-2 mb-2 d-block" style="overflow-wrap: anywhere; cursor: pointer;" @click="copyPublicKey">{{
                        apiKeyDialog.apiKey.publicKey
                      }}</code></td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('api_keys.private_key') }}</td>
                  <td>
                    <code ref="privateKey" class="mt-10 mb-2 d-block" style="overflow-wrap: anywhere; cursor: pointer;" @click="copyPrivateKey">{{
                        apiKeyDialog.apiKey.privateKey
                      }}</code>
                    <br/><small class="error--text mt-2 mb-2 d-block">{{
                      $t('api_keys.private_key_one_time_notice')
                    }}
                  </small>

                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.expire_at') }}
                  </td>
                  <td>{{ $formatDateTime(apiKeyDialog.apiKey.expireAt) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.last_used') }}
                  </td>
                  <td>{{ $formatDateTime(apiKeyDialog.apiKey.lastUsed) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.ips') }}
                  </td>
                  <td>
                    <template v-if="apiKeyDialog.apiKey.ips">
                      <v-chip v-for="(ip, i) in apiKeyIpsSplit(apiKeyDialog.apiKey)" :key="i" class="mr-1" small>
                        {{ ip }}
                      </v-chip>
                    </template>

                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.created_at') }}
                  </td>
                  <td>{{ $formatDateTime(apiKeyDialog.apiKey.createdAt) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('api_keys.created_by') }}
                  </td>
                  <td>{{ apiKeyDialog.apiKey.createdBy }}</td>
                </tr>

                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
          <v-spacer></v-spacer>
          <v-btn
            v-if="apiKeyDialog.apiKey.publicKey"
            :loading="apiKeyDialog.deleting"
            color="error"
            rounded
            :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
            :block="$vuetify.breakpoint.xsOnly"
            @click="deleteApiKey">{{ $t('api_keys.delete') }}
          </v-btn>
          <v-btn
            color="default"
            rounded
            :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
            :block="$vuetify.breakpoint.xsOnly"
            @click="closeApiKeyDialog">{{ $t('api_keys.close') }}
          </v-btn>
          <v-btn
            v-if="!apiKeyDialog.apiKey.publicKey"
            :loading="apiKeyDialog.saving"
            color="primary"
            rounded
            :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
            :block="$vuetify.breakpoint.xsOnly"
            @click="saveNewApiKey">{{ $t('api_keys.save_new') }}
          </v-btn>
          <v-btn
            v-if="apiKeyDialog.edit && apiKeyDialog.apiKey.publicKey"
            :loading="apiKeyDialog.saving"
            color="primary"
            rounded
            :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
            :block="$vuetify.breakpoint.xsOnly"
            @click="editApiKey">{{ $t('api_keys.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import apiKeyApi from "@/api/apikey";
import DatePicker from "@/components/DatePicker";

const apiKeyTemplate = {
  name: '',
  expireAt: dayjs().add(1, 'month').set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59).toISOString(),
  ips: '',
}
export default {
  name: "ApiKeys",
  components: {DatePicker},
  data() {
    return {
      loadingApiKeys: false,
      apiKeys: [],
      apiKeyDialog: {
        show: false,
        saving: false,
        deleting: false,
        apiKey: {...apiKeyTemplate},
      },
      now: dayjs(),
    };
  },
  methods: {
    isPast(expireAt) {
      return this.now.isBefore(expireAt)
    },
    openEditApiKeyDialog(apiKey) {
      this.apiKeyDialog = {...this.apiKeyDialog, show: true, edit: true, apiKey: {...apiKey}}
    },
    async copyPublicKey() {
      await this.$copyText(this.apiKeyDialog.apiKey.publicKey, this.$refs.publicKey);
      this.$showSuccessNotification(this.$t('api_keys.public_key') + ' ' + this.$t('api_keys.copied_to_clipboard'))
    },
    async copyPrivateKey() {
      await this.$copyText(this.apiKeyDialog.apiKey.privateKey, this.$refs.privateKey);
      this.$showSuccessNotification(this.$t('api_keys.private_key') + ' ' + this.$t('api_keys.copied_to_clipboard'))
    },
    apiKeyIpsSplit(apiKey) {
      if (!apiKey.ips) return [];
      return apiKey.ips.split('\n').map(ip => ip.trim());
    },
    openNewAPIKeyDialog() {
      this.apiKeyDialog = {saving: false, show: true, deleting: false, apiKey: {...apiKeyTemplate}}
    },
    closeApiKeyDialog() {
      this.apiKeyDialog = {saving: false, show: false, deleting: false, apiKey: {...apiKeyTemplate}}
    },
    async saveNewApiKey() {
      this.apiKeyDialog.saving = true;
      try {
        let apiKey = await apiKeyApi.createApiKey(this.apiKeyDialog.apiKey);
        this.apiKeyDialog.apiKey = apiKey;
        this.$showSuccessNotification(this.$t('api_keys.new_api_key_saved'))
      } catch (err) {
        this.$handleApiError(err, this.$t('api_keys.error_create'))
      }
      this.apiKeyDialog.saving = false;
      this.reloadApiKeys();
    },
    async deleteApiKey() {
      this.apiKeyDialog.deleting = true;
      try {
        await apiKeyApi.deleteApiKey(this.apiKeyDialog.apiKey.publicKey);
        this.apiKeyDialog.show = false;
        this.$showSuccessNotification(this.$t('api_keys.api_key_deleted'))
      } catch (err) {
        this.$handleApiError(err, this.$t('api_keys.error_delete'))
      }
      this.apiKeyDialog.deleting = false;
      this.reloadApiKeys();
    },
    async editApiKey() {
      this.apiKeyDialog.saving = true;
      try {
        let apiKey = await apiKeyApi.updateApiKey(this.apiKeyDialog.apiKey.publicKey, this.apiKeyDialog.apiKey);
        this.apiKeyDialog.apiKey = apiKey;
        this.apiKeyDialog.show = false;
        this.$showSuccessNotification(this.$t('api_keys.api_key_saved'))
      } catch (err) {
        this.$handleApiError(err, this.$t('api_keys.error_edit'))
      }
      this.apiKeyDialog.saving = false;
      this.reloadApiKeys()
    },
    async reloadApiKeys() {
      this.loadingApiKeys = true;
      this.apiKeys = await apiKeyApi.getApiKeys();
      this.loadingApiKeys = false;
    },
    handleApiKeyExpireAtChange(e) {
      this.apiKeyDialog.apiKey.expireAt = dayjs(e).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59).toISOString();
    },
  },
  mounted() {
    this.reloadApiKeys();
    this.now = dayjs();
  }
}
</script>

<style scoped>

</style>
