<template>
  <div>
    <!-- MD and Up -->
    <v-menu v-model="isActive" v-if="$vuetify.breakpoint.mdAndUp" bottom nudge-bottom="-14" max-height="400" :close-on-content-click="false" :close-on-click="!longDescriptionDialog" :max-width="$vuetify.breakpoint.mdAndUp ? maxWidth : ''">
      <template v-slot:activator="{ on }">
        <v-text-field :value="sdgDisplayText"
                      ref="sdgTextField"
                      :placeholder="$t('plan.select_sdg_indicator')"
                      v-on="on"
                      @focus="setMaxWidth"
                      readonly
                      :rules="[$rules.required]"
                      autocomplete="off"
                      solo-inverted
                      flat>
          <template v-slot:append>
            <v-icon :color="isActive ? 'primary' : ''" :class="isActive ? 'mdi-rotate-180' : ''">mdi mdi-menu-down</v-icon>
          </template>
        </v-text-field>
      </template>
      <SDGBusinessIndicatorList :is-active="isActive"
                                @close-without-changes="isActive = false"
                                @select-indicator="onSelect"
                                @show-long-description="showLongDescription"
                                :best-matches="bestMatches"
                                :all-indicators="allIndicators"
                                :selected-indicator="selectedIndicator" />
    </v-menu>

    <!-- SM and Down -->
    <v-dialog v-model="isActive"
              v-if="$vuetify.breakpoint.smAndDown"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
              :hide-overlay="$vuetify.breakpoint.xsOnly"
              :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
      <template v-slot:activator="{ on }">
        <v-text-field :value="sdgDisplayText"
                      :placeholder="$t('plan.select_sdg_indicator')"
                      v-on="on"
                      readonly
                      :rules="[$rules.required]"
                      autocomplete="off"
                      solo-inverted
                      flat>
          <template v-slot:append>
            <v-icon :color="isActive ? 'primary' : ''" :class="isActive ? 'mdi-rotate-180' : ''">mdi mdi-menu-down</v-icon>
          </template>
        </v-text-field>
      </template>
      <SDGBusinessIndicatorList :is-active="isActive"
                                @close-without-changes="isActive = false"
                                @select-indicator="onSelect"
                                @show-long-description="showLongDescription"
                                :best-matches="bestMatches"
                                :all-indicators="allIndicators"
                                :selected-indicator="selectedIndicator" />
    </v-dialog>

    <v-dialog v-model="longDescriptionDialog" max-width="600">
      <v-card v-if="shortDescription && longDescription">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ shortDescription }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="longDescriptionDialog = false">
            <v-icon>lnr lnr-cross</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <p>{{ longDescription }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import SDGBusinessIndicatorList from "./SDGBusinessIndicatorList";
  export default {
    name: "SDGBusinessIndicatorSelector",
    components: {SDGBusinessIndicatorList},
    props: {
      bestMatches: {
        type: Array,
        default: () => [],
      },
      allIndicators: {
        type: Array,
        default: () => [],
      },
      selectedIndicator: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        filter: '',
        isActive: false,
        maxWidth: '',
        longDescriptionDialog: false,
        longDescription: '',
        shortDescription: '',
      }
    },
    methods: {
      onSelect(indicatorCode) {
        this.$emit('select-indicator', indicatorCode);
        this.isActive = false;
      },
      setMaxWidth() {
        if (this.$refs.sdgTextField) {
          this.maxWidth = this.$refs.sdgTextField.$el.offsetWidth;
        }
      },
      showLongDescription(indicator) {
        this.longDescriptionDialog = true;
        this.longDescription = indicator.description;
        this.shortDescription = indicator.shortDescription;
      },
    },
    watch: {
      longDescriptionDialog(to) {
        if (!to) {
          this.longDescriptionDialog = '';
          this.shortDescription = '';
        }
      }
    },
    computed: {
      sdgDisplayText() {
        if (this.selectedIndicator && this.selectedIndicator.text) {
          return this.selectedIndicator.text;
        }
        return '';
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setMaxWidth);
    },
    mounted() {
      this.setMaxWidth();
      window.addEventListener('resize', this.setMaxWidth);
    }
  }
</script>

<style scoped>

</style>
