<template>
  <v-row>
    <v-col cols="12">
      <v-card color="#e7e7e7" flat tile width="100%">
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-left">
              <img :src="imageUrl" style="max-height: 100px; max-width: 90%;" />
            </v-col>
            <v-col cols="6" class="text-right">
              <img src="/img/SDG_M_icon.png" style="max-height: 150px; max-width: 90%;"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DashboardFooter",
  data() {
    return {
      logo: null,
    }
  },
  computed: {
    imageUrl() {
      return `${this.$baseUrl}/api/shared/image/${this.$tenant}/logo`
    },
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
