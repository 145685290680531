<template>
  <v-container :fill-height="loading" class="plan" :fluid="$vuetify.breakpoint.lgAndDown">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;">

        <v-col cols="12">
          <hb-heading v-model="plan.name" :editable="!$store.getters.tenantNotActive" @input="planNameChanged" />
        </v-col>

        <v-col cols="12" md="4">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-btn color="primary" block class="mb-2" :disabled="$store.getters.tenantNotActive || exportMode"
                       @click="addNewAction">
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t('plan.add_new_action') }}
                </v-btn>
                <template v-if="exportMode">
                  <v-btn color="primary" block class="mb-2"
                         :disabled="$store.getters.tenantNotActive || selectedActions.length === 0" :loading="exporting"
                         @click="exportSelected">
                    <v-icon left>lnr lnr-download</v-icon>
                    {{ $t('plan.export_actions') }}
                  </v-btn>
                  <v-btn color="primary" block class="mb-2" :disabled="$store.getters.tenantNotActive || exporting"
                         @click="cancelExport">
                    <v-icon left>lnr lnr-cross-circle</v-icon>
                    {{ $t('plan.cancel_export') }}
                  </v-btn>
                </template>
              </v-list-item-content>
            </v-list-item>
            <template v-if="$vuetify.breakpoint.smAndDown && currentAction && currentAction.id === null">
              <action :users="users" style="margin-top: 5px; margin-bottom: 25px;" :action="currentAction"
                      :indicators="indicators" @create-action="createAction" @update-action="updateAction"/>
            </template>
            <v-divider></v-divider>
            <v-list-item-group v-model="model" class="actions-list">
              <draggable v-model="plan.actions" handle=".handle"  @change="dragOver">
                <div v-for="action in plan.actions" :key="action.id">
                  <v-list-item :key="`${action.id}_item`" @click="toggleSelectedAction(action)" :id="`item_${action.id}`">
                    <v-list-item-avatar class="handle" v-if="$vuetify.breakpoint.mdAndUp && !exportMode">
                      <v-icon>
                        mdi mdi-reorder-horizontal
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-if="exportMode">
                      <v-checkbox class="mr-n2" @click.stop v-model="action.selectedForExport"></v-checkbox>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ action.description }}</v-list-item-title>
                      <v-list-item-subtitle><span class="primary--text">{{ action.indicatorCode }}</span> {{ action.indicatorDescription }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on }">
                          <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">more_vert</v-icon></v-btn>
                        </template>
                        <v-list class="pt-0 pb-0">
                          <v-list-item @click="copyAction(action)">
                            <v-list-item-icon><v-icon>lnr lnr-link</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('plan.copy_action') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="copyActionToTenant(action)" v-if="userHasMultipleTenants">
                            <v-list-item-icon><v-icon>lnr lnr-link</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('plan.copy_action_to_tenant') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="selectActionForExport(action)">
                            <v-list-item-icon><v-icon>lnr lnr-download</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('plan.select_action_for_export') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="deleteAction(action)">
                            <v-list-item-icon><v-icon>lnr lnr-trash</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('plan.delete_action') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="`${action.id}_divider`"></v-divider>
                  <template v-if="$vuetify.breakpoint.smAndDown && currentAction.id === action.id">
                    <action :users="users" :key="`${action.id}_details`" style="margin-top: 5px; margin-bottom: 25px;"
                            :action="currentAction" :indicators="indicators" @create-action="createAction"
                            @update-action="updateAction"/>
                  </template>
                </div>
              </draggable>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col cols="12" md="8" v-if="$vuetify.breakpoint.mdAndUp">
          <action ref="currentActionCard" v-if="currentAction" :users="users" :action="currentAction" :indicators="indicators" @create-action="createAction" @update-action="updateAction"/>
          <template v-else>
            <v-alert type="info" text prominent :value="!currentAction" class="mt-5">
              <v-row justify="space-between" align="center" class="pa-3">
              <template v-if="plan.actions.length > 0">
                <div class="py-2">{{ $t('plan.no_selected_action') }}</div>
                <v-btn right small color="info" outlined @click="addNewAction">{{ $t('plan.add_new_action') }}</v-btn>
              </template>
              <template v-else>
                <div class="py-2">{{ $t('plan.no_actions') }}</div>
                <v-btn right small color="info" outlined @click="addNewAction">{{ $t('plan.add_new_action') }}</v-btn>
              </template>
              </v-row>
            </v-alert>
          </template>
        </v-col>
      </v-row>
    </v-fade-transition>

    <ConfirmDialog ref="confirm" />
    <CopyActionToPlanDialog ref="copyActionDialog"></CopyActionToPlanDialog>
    <CopyActionToTenantDialog ref="copyActionToTenantDialog"></CopyActionToTenantDialog>

  </v-container>

</template>

<script>
  import Action from "../../components/Plan/Action.vue";
  import tenantApi from "../../api/tenant";
  import ConfirmDialog from "@/components/ConfirmDialog";
  import CopyActionToPlanDialog from "@/components/Plan/CopyActionToPlanDialog";
  import CopyActionToTenantDialog from "@/components/Plan/CopyActionToTenantDialog";
  import draggable from "vuedraggable";
  import dataExportApi from "@/api/dataExportApi";
  import downloadjs from "downloadjs";

  export default {
    name: 'PlanView',
    components: {
      draggable,
      CopyActionToTenantDialog,
      CopyActionToPlanDialog,
      ConfirmDialog,
      Action: Action,
    },
    props: {
      planId: {
        type: [Number, String],
      },
      actionId: {
        type: [Number, String, Boolean],
        default: false,
      },
    },
    data() {
      return {
        users: [],
        loading: false,
        subtleLoading: false,
        exporting: false,
        model: null,
        plan: {
          name: '',
          actions: []
        },
        indicators: [],
        exportMode: false,
        actionTemplate: {
          description: '',
          indicatorDescription: null,
          indicatorCode: null,
          indicatorIndex: null,
          impactId: null,
          impactText: '',
          esg: null,
          sdgIndex: null,
          currentValue: null,
          startValue: null,
          targetValue: null,
          startDate: null,
          endDate: null,
          unitId: null,
          source: '',
          dataPoints: [],
          id: null,
          planId: null,
          containsData: true,
          customImpacts: [],
        },
        currentAction: false,
      }
    },
    computed: {
      selectedActions() {
        return this.plan.actions.filter(a => a.selectedForExport);
      },
      userHasMultipleTenants() {
        return this.$userInfo.tenants.length > 1;
      },
    },
    methods: {
      async exportSelected() {
        this.exporting = true;

        try {
          const actionIds = this.plan.actions.filter(a => a.selectedForExport).map(a => a.id);
          const blob = await dataExportApi.exportActionData(actionIds);
          downloadjs(blob, 'sdg_monitor_actions.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        } catch (error) {
          this.$handleApiError(error);
        }
        this.exporting = false;
      },
      cancelExport() {
        this.exportMode = false;
        this.plan.actions.forEach(a => a.selectedForExport = false);
      },
      selectActionForExport(action) {
        this.exportMode = true;
        action.selectedForExport = true;
      },
      async dragOver() {
        this.subtleLoading = true;

        try {
          await tenantApi.reorderPlanActions(this.planId, this.plan.actions.map(a => a.id));
        } catch (error) {
          this.$handleApiError(error);
        }

        this.subtleLoading = false;
      },
      async copyActionToTenant(action) {
        this.$refs.copyActionToTenantDialog.open(action);
      },
      async copyAction(action) {
        this.$refs.copyActionDialog.open(action);
      },
      async deleteAction(action) {
        const confirm = await this.$refs.confirm.open(this.$t('plan.delete_action'), this.$t('plan.delete_action_confirm'),
        { color: 'error', agree: this.$t('delete'), cancel: this.$t('cancel')});
        if (!confirm) {
          return;
        }

        try {
          await tenantApi.deleteAction(action.id);
          if (this.currentAction.id === action.id) {
            this.currentAction = false;
          }
          await this.reloadPlan();
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async planNameChanged() {
        this.subtleLoading = true;
        try {
          await tenantApi.updatePlan(this.plan.id, { name: this.plan.name });
        } catch (error) {
          this.$handleApiError(error);
        }
        this.subtleLoading = false;
      },
      addNewAction() {
        this.model = null;
        this.currentAction = Object.assign({}, this.actionTemplate);
        this.currentAction.planId = this.plan.id;
      },
      toggleSelectedAction(action) {
        if (this.currentAction.id !== action.id) {
          this.currentAction = action;
        }
      },
      addNewValue() {
        this.actionTab = 1;
        this.currentAction.dataPoints.push(Object.assign({}, this.dataPointTemplate));
      },
      createAction(action) {
        const newAction = Object.assign({}, action);
        this.plan.actions.push(newAction);
        this.model = this.plan.actions.length - 1;
        this.currentAction = newAction;
        if (this.$vuetify.breakpoint.smAndDown) {
          setTimeout(() => {
            this.$vuetify.goTo(`#item_${this.currentAction.id}`);
          }, 100);
        }
      },
      updateAction(action) {
        const updatedAction = Object.assign({}, action);
        Object.assign(this.plan.actions[this.plan.actions.findIndex(a => a.id === action.id)], updatedAction);
        this.currentAction = updatedAction;
      },
      async reloadPlan() {
        this.loading = true;
        try {
          const plan = await tenantApi.getPlan(this.planId);
          plan.actions.forEach(a => a.selectedForExport = false);
          this.plan = plan;
        } catch (error) {
          this.$handleApiError(error);
        }
        this.loading = false;
      },
      async reloadUsers() {
        this.users = await tenantApi.loadTenantUsers();
      },
    },
    async mounted() {
      await this.reloadUsers();
      await this.reloadPlan();
      this.indicators = this.$store.state.systemData.indicators;

      if (this.actionId && this.plan.actions) {
        const actionIndex = this.plan.actions.findIndex(action => action.id === this.actionId);
        if (actionIndex > -1) {
          this.toggleSelectedAction(this.plan.actions[actionIndex]);
          this.$nextTick(() => {
            if (this.$refs.currentActionCard) {
              this.$refs.currentActionCard.setTab(1);
            }
          });
        }
      }
    }
  }
</script>

<style lang="scss">
  .action-tabs {
    .v-slide-group.v-item-group>.v-slide-group__next, .v-slide-group.v-item-group>.v-slide-group__prev {
      display: none !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .actions-list {
      max-height: calc(100vh - 360px);
      overflow: auto;
    }
  }

  @media only screen and (min-width: 1400px) {
    .actions-list {
      max-height: calc(100vh - 390px);
    }
  }

  @media only screen and (min-width: 1980px) {
    .actions-list {
      max-height: calc(100vh - 450px);
    }
  }
</style>
