import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-list"},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.subtleLoading),expression:"subtleLoading"}],staticStyle:{"position":"fixed","left":"0","right":"0","margin":"0","z-index":"99"},style:({top: _vm.$vuetify.breakpoint.smAndDown ? '54px' : '62px'}),attrs:{"color":"primary lighten-2","height":"2","indeterminate":true}}),_vm._l((_vm.users),function(user){return [_c('div',{key:user.id},[_c(VListItem,{staticStyle:{"background":"none"}},[_c(VListItemAvatar,{staticClass:"user-avatar",attrs:{"color":user.enabled ? _vm.$randomizeColorFromString(user.email ? user.email : user.subject, 75, 70) : 'grey lighten-2'}},[(_vm.type === 'user')?_c(VIcon,{staticStyle:{"margin-top":"8px","margin-left":"2px"},attrs:{"color":"white","size":"36"}},[_vm._v("lnr lnr-user")]):_vm._e()],1),_c(VListItemContent,[_c(VContainer,{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center","ma-0":""}},[_c(VCol,{class:!user.enabled ? 'grey--text text--lighten-1' : '',attrs:{"cols":"12","sm":"6","lg":"5","xl":"3"}},[_vm._v(" "+_vm._s(user.email ? user.email : user.subject)+" "),(user.firstName && user.lastName)?[_c('br'),_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))])]:_vm._e()],2),(_vm.$vuetify.breakpoint.smAndUp)?_c(VCol,{attrs:{"sm":"6","lg":"7","xl":"9"}}):_vm._e()],1)],1)],1),_c(VListItemAction,[_c(VMenu,{attrs:{"bottom":"","left":"","close-on-content-click":_vm.$vuetify.breakpoint.smAndUp},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"mt-0 mb-0",attrs:{"text":"","icon":"","color":"grey"}},[_c(VIcon,_vm._g({attrs:{"size":"28"}},on),[_vm._v("more_vert")])],1)]}}],null,true)},[_c(VList,{staticClass:"pt-0 pb-0",attrs:{"dense":""}},[(_vm.$vuetify.breakpoint.xsOnly)?[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('user_management.email'))+":")]),_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("lnr lnr-user")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(user.username))])],1)],1)]:_vm._e(),_c(VSubheader,[_vm._v(_vm._s(_vm.$t('user_management.manage_user'))+":")]),_c(VListItem,{on:{"click":function($event){return _vm.removeUserFromTenant(user)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("lnr lnr-circle-minus")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('tenants.delete_user')))])],1)],1)],2)],1)],1)],1),_c(VDivider)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }